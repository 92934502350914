import { Elements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { Button } from '.';
import JobStore from '../stores/JobStore';
import { ButtonFooter } from './_buttonFooter';
import { ToastsStore } from 'react-toasts';
import { loadStripe } from '@stripe/stripe-js';
import Config from '../config';

export const PaymentCTAComponent = ({ props }) => {
  const stripe = useStripe();

  const payNow = () => {
    let { job } = JobStore;
    if (props?.defaultPaymentMethodID === null) {
      ToastsStore.error('Please select a payment method');
      return;
    }

    JobStore.createIntent(job.id, props?.defaultPaymentMethodID).then(
      async (resp) => {
        if (resp) {
          const requiresAction = resp && resp?.status === 'requires_action';
          const paymentIntentId = resp?.id;
          const clientSecret = resp?.client_secret;

          if (!requiresAction) {
            ToastsStore.success('The payment was confirmed successfully!');
            setTimeout(() => {
              props?.props?.history?.push('/dashboard/jobs');
            }, 2300);
          }
          if (clientSecret && paymentIntentId && requiresAction) {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              clientSecret
            );
            const confirmCardStatus = paymentIntent?.status;

            if (confirmCardStatus === 'succeeded') {
              ToastsStore.success('The payment was confirmed successfully!');
              setTimeout(() => {
                props?.props?.history?.push('/dashboard/jobs');
              }, 2300);
            }
            if (error) {
              ToastsStore.error(error?.message);
            }
          }
        }
      }
    );
  };
  return (
    <ButtonFooter>
      <Button
        text='Checkout'
        customClass='button'
        style={{ width: '45%', float: 'right' }}
        onClick={payNow}
      />
      <Button
        text='Cancel'
        customClass='button btn-cancel'
        style={{ width: '30%', float: 'right' }}
        onClick={() => props?.history?.push('/dashboard/jobs')}
      />
    </ButtonFooter>
  );
};

const stripePromise = loadStripe(Config.stripeKey);

export const PaymentCTA = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentCTAComponent props={props} />
    </Elements>
  );
};
