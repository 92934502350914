import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, Button, Breadcrumbs, Input } from '../../../components';

import AuthStore from '../../../stores/AuthStore'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


@observer
class ChangeEmail extends Component {

    @observable active_sidebar_option = 'Settings'
    @observable sidebar_options = ['Settings']

    @observable email = ''
    @observable email_confirmation = ''

    @observable valid = false

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    changeEmail = async () => {
        try {
            this.loading = true
            AuthStore.changeEmail(this.email, this.email_confirmation).then(res => {
                if (res.data) {
                    this.loading = false
                    ToastsStore.success("Email Request Sent")
                } else {
                    this.loading = false
                    this.valid = res.message
                }
            })
        }
        catch (e) {
            this.loading = false
        }
    }


    render() {

        let { errors } = AuthStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/profile',
                title: 'Profile'
            },
            {
                link: '/dashboard/profile/email',
                title: 'Change Email'
            }
        ]
        return (

            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Change Email Address"
                    buttonText="Back"
                    buttonClass='button button-white'
                    buttonClick={() => {
                        this.props.history.goBack()
                    }}
                />

                <div className="page-content">

                    <ContentContainer>
                        <div className="main-content">
                            <h1>Change Email Address</h1>
                            <Input
                                label="Enter your new email address"
                                name="email"
                                type="email"
                                placeholder="Enter new email address"
                                keyvalue={'email'}
                                value={this.email || ''}
                                onChange={text => this.email = text.target.value}
                                errors={errors}
                            />

                            <Input
                                label="Confirm Your New Email Address"
                                name="email_confirmation"
                                type="email"
                                placeholder="Confirm Your New Email Address"
                                keyvalue={'email_confirmation'}
                                value={this.email_confirmation || ''}
                                onChange={text => this.email_confirmation = text.target.value}
                                errors={errors}
                            />


                            {this.valid &&
                                <div className="errorsContainer">
                                    <p className="error">{this.valid}</p>
                                </div>
                            }

                            <Button
                                text="Update Email"
                                customClass="button"
                                style={{ width: '45%', float: 'right' }}
                                onClick={() => this.changeEmail()}
                            />

                        </div>

                        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />

                    </ContentContainer>
                </div>

            </div >
        );
    }
}
export default ChangeEmail;
