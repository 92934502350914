import React from 'react';
import MobileExample from '../../assets/images/homepage/Phone.png';
import { Fade, Slide } from 'react-reveal';

const GetStarted = () => {
    return (

        <div className="section content getStarted">
            <Slide left delay={200} duration={1000}>
                <div className="getStartedLeft">
                    <h2>Get Started</h2>
                    <hr />
                    <p>Take the first step towards securing your next role with Pear Up. Download the app today to access local jobs and workers at the click of a button.</p>
                    <div className="btnContainer">
                        <a className="btn" href="/login">Sign In</a>
                        <a className="btn" href="mailto:info@pearupjobs.com">Contact Us</a>
                    </div>
                </div>
            </Slide>
            <Fade right delay={1200} duration={1000}>
                <div className="getStartedRight">
                    <img src={MobileExample} />
                </div>
            </Fade>
        </div>

    );
}

export default GetStarted;