import React, { Component } from 'react';

export class Button extends Component {

	render() {

		const { text, onClick, disabled, style = {}, customClass } = this.props

		return (
			<button className={customClass} style={style} onClick={onClick} disabled={disabled}>
				{text}
			</button>
		);

	}
}
