import React, { Component } from 'react';

import { ButtonFooter, Button } from './'

export class ConfirmationModal extends Component {

    render() {

        const { title, text, acceptText, acceptFunc, declineText, visible, onClose } = this.props

        return (


            <div className={visible ? 'custom-modal' : 'custom-modal-hide'}>
                <div className="modal-content" >

                    <div className="close-icon-cont" onClick={() => onClose()}>
                        <img src={require('../assets/images/icons/close-black.png')} alt="Close Icon" />
                    </div>

                    <div>
                        <div>
                            {title &&
                                <p style={{ fontSize: 22, textAlign: 'center', marginBottom: 12 }}>{title}</p>
                            }
                            {text &&
                                <p style={{ fontSize: 16, textAlign: 'center' }}>{text}</p>
                            }
                        </div>
                        <ButtonFooter style={{ borderTopWidth: 0, justifyContent: 'space-between', height: 70, alignItems: 'flex-start' }}>

                            <Button
                                text={declineText}
                                style={{ width: '48%', backgroundColor: 'white', height: 50, borderWidth: 2, borderColor: 'red', float: 'left' }}
                                textStyle={{ color: 'red', fontSize: 16 }}
                                onClick={() => onClose()}
                            />

                            <Button
                                text={acceptText}
                                style={{ width: '48%', backgroundColor: '#59AE46', height: 50, borderWidth: 2, borderColor: '#59AE46', color: 'white', float: 'right' }}
                                textStyle={{ fontSize: 15 }}
                                onClick={() => acceptFunc()}
                            />
                        </ButtonFooter>
                    </div>

                </div>
            </div>
        );
    }

};
