import React, { Fragment, Component } from 'react';

import '../../App.scss';

import { GiftedChat } from 'react-web-gifted-chat';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, Breadcrumbs } from '../../components';

import AuthStore from '../../stores/AuthStore'
import CompanyStore from '../../stores/CompanyStore'


@observer
class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            max_messages: 10,
            page: 1,
            user_id: null,
            isLoadingEarlier: false
        };
        this.checkNewMessages = this.checkNewMessages.bind(this);
    }


    @observable user = []

    @observable active_sidebar_option = 'Message'
    @observable sidebar_options = ['Message']


    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    componentDidMount() {
        CompanyStore.getThread(this.props.match.params.user_id, this.state.max_messages, this.state.page).then(res => {
            if (res.data) {
                if (res.data.messages) {
                    if (res.data.messages.length > 0) {
                        let messageCount = 0
                        let messageLength = res.data.messages.length
                        res.data.messages.map(message => {
                            let obj = {
                                id: message.id,
                                text: message.body,
                                createdAt: message.created_at,
                                user: {
                                    id: message.sender === 'company' ? 1 : 2,
                                    name: res.data.user.full_name,
                                },
                            }

                            this.user = res.data.user.full_name

                            this.setState(previousState => ({
                                messages: GiftedChat.append(previousState.messages, obj),
                            }))

                            messageCount++;

                            if (messageCount === messageLength) {
                                AuthStore.getStatus()
                            }
                        })
                    }
                }
            }

        })

        this.myInterval = setInterval(this.checkNewMessages.bind(this), 4000)
        // this.myInterval
    }

    loadEarlierMessages = () => {
        if (!this.state.isLoadingEarlier) {
            let oldPageNumber = this.state.page
            this.setState({ page: this.state.page + 1, isLoadingEarlier: true }, () => {
                CompanyStore.getThread(this.props.match.params.user_id, this.state.max_messages, this.state.page).then(res => {
                    if (res.data.messages.length > 0) {

                        let reversedMessages = res.data.messages.reverse()

                        let newMessagesNumber = reversedMessages.length
                        let newMessagesAdded = 0

                        reversedMessages.map(message => {
                            let obj = {
                                id: message.id,
                                text: message.body,
                                createdAt: message.created_at,
                                user: {
                                    id: message.sender === 'company' ? 1 : 2,
                                    name: res.data.user.full_name,
                                },
                            }

                            this.setState(previousState => ({
                                messages: GiftedChat.prepend(previousState.messages, obj),
                            }))

                            newMessagesAdded++

                            if (newMessagesNumber === newMessagesAdded) {
                                this.setState({ isLoadingEarlier: false })
                            }

                        })
                    }
                    else {
                        this.setState({ page: oldPageNumber, isLoadingEarlier: false })
                    }
                })
            })
        }
    }

    checkNewMessages = () => {
        if (this.state.messages.length > 0) {

            let lastItem = this.state.messages[0]
            let lastId = lastItem.id

            CompanyStore.getUnseen(this.props.match.params.user_id, lastId).then(res => {
                if (res.data) {
                    if (res.data.messages.length > 0) {
                        let messageCount = 0
                        let messageLength = res.data.messages.length
                        res.data.messages.map(message => {
                            let obj = {
                                id: message.id,
                                text: message.body,
                                createdAt: message.created_at,
                                user: {
                                    id: message.sender === 'company' ? 1 : 2,
                                    name: res.data.user.full_name,
                                },
                            }

                            this.setState(previousState => ({
                                messages: GiftedChat.append(previousState.messages, obj),
                            }))

                            messageCount++;

                            if (messageCount === messageLength) {
                                AuthStore.getStatus()
                            }

                        })
                    }
                }

            })
        }
    }

    onSend(messages = []) {

        CompanyStore.sendMessage(this.props.match.params.user_id, messages[0].text).then(res => {
            if (res.data) {
                if (res.data.response == "Your data has been successfully created.") {
                    if (this.state.messages.length === 0) {
                        let obj = {
                            id: res.data.message.id,
                            text: res.data.message.body,
                            createdAt: res.data.message.created_at,
                            user: {
                                id: res.data.message.sender === 'company' ? 1 : 2,
                                name: '',
                            },
                        }

                        this.setState(previousState => ({
                            messages: GiftedChat.append(previousState.messages, obj),
                        }))

                    } else {
                        this.checkNewMessages()
                    }
                }
            } else {
            }
        })

    }



    render() {

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/messages',
                title: 'Messages'
            }
        ]

        return (
            <Fragment>
                <div className="page jobs">
                    <Breadcrumbs
                        data={breadcrumbs}
                    />

                    <Header
                        title="Messages"
                        backButton={true}
                        onClick={() => {
                            this.props.history.goBack()
                        }}

                    />

                    <div className="page-content">
                        <Sidebar
                            options={this.sidebar_options}
                            currentOption={this.active_sidebar_option}
                            onPress={this.setActiveSideBarOption.bind(this)}
                            buttonClick={() => {
                                this.props.history.push('/dashboard/job/create')
                            }}
                        />

                        <ContentContainer>
                            {this.active_sidebar_option && this.active_sidebar_option == 'Message' &&
                                <div className="main-content">
                                    {this.user &&
                                        <h1>{this.user}</h1>

                                    }
                                    <div className="giftedChat">
                                        <GiftedChat
                                            className="giftedChat"
                                            messages={this.state.messages}
                                            onSend={messages => this.onSend(messages)}
                                            onLoadEarlier={this.loadEarlierMessages}
                                            isLoadingEarlier={this.state.isLoadingEarlier}
                                            loadEarlier
                                            user={{
                                                id: 1,
                                            }}
                                        />
                                    </div>

                                </div>
                            }
                        </ContentContainer>
                    </div>
                </div >
            </Fragment>
        );
    }
}
export default Message;
