import React, { Component } from 'react';

export class ProfileImage extends Component {

    render() {

        const { image, name, edit } = this.props

        return (
            <div className={edit === true ? 'profileBg profileBgEdit' : 'profileBg'}>
                <div className="profileTop"></div>
                {image &&
                    <div className="profileImage" style={{ backgroundImage: `url(${image})` }} ></div>
                }
                {!image &&
                    <div className="profileImage" style={{ backgroundImage: `url(${require("../assets/images/profile/noProfileImage.png")})` }} ></div>
                }

                {name &&
                    <p className="userName">{name}</p>
                }

                {edit === true &&
                    <div className="profileBgedit">
                        <img src={require('../assets/images/icons/camera.png')} alt="Profile for user"/>
                    </div>
                }
            </div>
        );

    }
}
