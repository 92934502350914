import React, { Component } from 'react';
import { Button } from '.';
export class SliderImage extends Component {

    render() {

        const { image } = this.props

        return (
            <div class="modal">
                <img src={image} alt="Slider Graphic" />
            </div>
        )
    }
}

