import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Input, ContentContainer, Pill, DataRow, TextArea, Button, ButtonFooter, Breadcrumbs } from '../../components';

import { CountryDropdown } from 'react-country-region-selector';

import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Jobs from './jobs';

@observer
class CreateJob extends Component {

    @observable job_contracts = [
        { id: 1, name: 'Full Time' },
        { id: 2, name: 'Part Time' },
        { id: 3, name: 'Flexi' },
        { id: 4, name: 'Temporary' },
        { id: 5, name: 'Contract' },
    ]

    @observable postTimeRange = []

    @observable showDurationScreen = false;

    @observable selectedDuration = 1;

    componentDidMount() {
        CompanyStore.getAllIndustries()
        JobStore.getPricing()
            .then(res => {
                this.updatePricing(res.data["4_weeks"], res.data["6_weeks"]);
            });
        if (CompanyStore.activeCompany.address == undefined) {
            this.props.history.push('/dashboard/jobs/')
        }
    }

    addIndustry = (industry) => {

        if (JobStore.job.industries.map(x => x.id).includes(industry.id)) {
            return JobStore.job.industries.splice(JobStore.job.industries.map(x => x.id).indexOf(industry.id), 1)
        }

        return JobStore.job.industries.push(industry)
    }

    updatePricing = (four_week, six_week) => {
        this.postTimeRange = [{
            'id': 1,
            'name': '4 weeks',
            'price': four_week,
            'duration': '4_weeks'
        },
        {
            'id': 2,
            'name': '6 weeks',
            'price': six_week,
            'duration': '6_weeks'
        }]
    }

    setActiveJobContract = (job_duration) => {
        JobStore.job.contract = job_duration.name
    }
    setActiveJobDuration = (data) => {
        JobStore.job.duration = data.duration
    }

    showDuration = () => {
        this.showDurationScreen = true
    }
    hideDuration = () => {
        this.showDurationScreen = false
    }

    submitJob = (job) => {
        JobStore.newJob(JobStore.job).then((res) => {
            if (res === 200) {
                this.props.history.push('/dashboard/job/confirm/' + JobStore.job.id)
            } else {
                this.showDurationScreen = false
                ToastsStore.error("Please ensure all fields have been completed.")
            }
        })
    }

    render() {

        let { errors, job } = JobStore
        let { activeCompany, allIndustries } = CompanyStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/jobs',
                title: 'Jobs'
            },
            {
                link: '/dashboard/job/create',
                title: 'Create a Job'
            }
        ]

        return (
            <div className="page jobs">

                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Jobs"
                    backButton={true}
                    onClick={() => {
                        this.props.history.push('/dashboard/jobs')
                    }}
                    buttonClick={() => {
                        this.props.history.push('/dashboard/job/create')
                    }}
                />
                <div className="page-content">

                    <ContentContainer>
                        {this.showDurationScreen != true &&
                            <div className="main-content">
                                <h1>Create a job</h1>

                                <Input
                                    label="Job Name"
                                    name="name"
                                    type="text"
                                    placeholder="Job Name"
                                    keyvalue={'name'}
                                    errors={errors}
                                    value={job.name || ''}
                                    onChange={text => job.name = text.target.value}
                                />

                                <Input
                                    label="Hourly Rate"
                                    name="rate"
                                    type="text"
                                    placeholder="Hourly Rate"
                                    keyvalue={'rate'}
                                    errors={errors}
                                    value={job.rate || ''}
                                    onChange={text => job.rate = text.target.value}
                                />
                                <Input
                                    label="Job Referral Reward"
                                    name="reward"
                                    type="text"
                                    placeholder="Referral Reward"
                                    keyvalue={'reward'}
                                    errors={errors}
                                    value={job.reward || ''}
                                    onChange={text => job.reward = text.target.value}
                                />

                                <div className="f-width pill-block">
                                    <div className="input-container">
                                        <label>Contract Type</label>
                                    </div>
                                    {this.job_contracts.map((l, index) => (
                                        <Pill
                                            skill={l}
                                            key={l.id}
                                            selected={job.contract === l.name}
                                            onClick={this.setActiveJobContract.bind(this)}
                                        />
                                    ))
                                    }
                                </div>
                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                    <div className="errorsContainer">
                                        {
                                            Object.keys(errors).map((key) => (
                                                errors[key].map((l, index) => (
                                                    key == 'contract' ? <p className="error" key={index}>{l}</p> : null
                                                ))
                                            ))
                                        }
                                    </div>
                                }




                                <div className="f-width pill-block">
                                    <div className="input-container">
                                        <label>Industries</label>
                                    </div>
                                    {
                                        allIndustries.map((l, index) => (
                                            <Pill
                                                skill={l}
                                                key={l.id}
                                                selected={job.industries.map(x => x.id).includes(l.id)}
                                                onClick={this.addIndustry.bind(this)}
                                            />
                                        ))
                                    }
                                </div>
                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                    <div className="errorsContainer">
                                        {
                                            Object.keys(errors).map((key) => (
                                                errors[key].map((l, index) => (
                                                    key == 'industries' ? <p className="error" key={index}>{l}</p> : null
                                                ))
                                            ))
                                        }
                                    </div>
                                }

                                <div className="f-width pill-block">
                                    <TextArea
                                        label="Job Description"
                                        name="description"
                                        errors={errors}
                                        keyvalue="description"
                                        value={job.description || ''}
                                        onChange={text => job.description = text.target.value}
                                    />
                                </div>


                                {job.address &&
                                    <div className="address_block">
                                        <div className="input-container">
                                            <label>Job Address</label>
                                        </div>
                                        <Input
                                            name="street_1"
                                            type="text"
                                            placeholder="Street Line 1"
                                            keyvalue={'street_1'}
                                            errors={errors}
                                            value={job.address.street_1 || ''}
                                            onChange={text => job.address.street_1 = text.target.value}
                                        />

                                        <Input
                                            name="street_2"
                                            type="text"
                                            placeholder="Street Line 2"
                                            keyvalue={'street_2'}
                                            errors={errors}
                                            value={job.address.street_2 || ''}
                                            onChange={text => job.address.street_2 = text.target.value}
                                        />

                                        <Input
                                            name="city"
                                            type="text"
                                            placeholder="Town/City"
                                            keyvalue={'city'}
                                            errors={errors}
                                            value={job.address.city || ''}
                                            onChange={text => job.address.city = text.target.value}
                                        />

                                        <Input
                                            name="zip"
                                            type="text"
                                            placeholder="Postcode"
                                            keyvalue={'zip'}
                                            errors={errors}
                                            value={job.address.zip || ''}
                                            onChange={text => job.address.zip = text.target.value}
                                        />

                                        <Input
                                            name="state"
                                            type="text"
                                            placeholder="County"
                                            keyvalue={'state'}
                                            errors={errors}
                                            value={job.address.state || ''}
                                            onChange={text => job.address.state = text.target.value}
                                        />

                                        <div>
                                            <div className="input-container">
                                                <CountryDropdown
                                                    value={job.address.country || ''}
                                                    whitelist={["GB", "IE"]}
                                                    onChange={(val) => job.address.country = val} />


                                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                                    <div className="errorsContainer country-error">
                                                        {
                                                            Object.keys(errors).map((key) => (
                                                                errors[key].map((l, index) => (
                                                                    key == 'country' ? <p className="error" key={index}>{l}</p> : null
                                                                ))
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        <div>

                                            {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                                <div className="errorsContainer">
                                                    {
                                                        Object.keys(errors).map((key) => (
                                                            errors[key].map((l, index) => (
                                                                key == 'address' ? <p className="error" key={index}>{l}</p> : null
                                                            ))
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>

                                    </div>
                                }


                                <ButtonFooter>
                                    <Button
                                        text="Save job details & proceed"
                                        customClass="button"
                                        style={{ width: '45%', float: 'right' }}
                                        onClick={() => this.showDuration()}
                                    />
                                    <Button
                                        text="Cancel"
                                        customClass="button btn-cancel"
                                        style={{ width: '30%', float: 'right' }}
                                        onClick={() => this.props.history.goBack()}
                                    />
                                </ButtonFooter>

                            </div>
                        }

                        {this.showDurationScreen === true &&
                            <div className="main-content f-width">
                                <p>{this.showDurationScreen}</p>
                                <h1>Job Details</h1>

                                <div className="f-width pill-block">
                                    <div className="input-container">
                                        <label>Job post length</label>
                                        <p>How long would you like to post this job for?</p>
                                    </div>

                                    {
                                        this.postTimeRange.map((l, index) => (
                                            <Pill
                                                skill={l}
                                                key={l.id}
                                                selected={job.duration === l.duration}
                                                onClick={this.setActiveJobDuration.bind(this)}
                                            />
                                        ))
                                    }
                                </div>
                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                    <div className="errorsContainer">
                                        {
                                            Object.keys(errors).map((key) => (
                                                errors[key].map((l, index) => (
                                                    key == 'duration' ? <p className="error" key={index}>{l}</p> : null
                                                ))
                                            ))
                                        }
                                    </div>
                                }

                                <div>



                                    {job.duration &&
                                        activeCompany.address &&
                                        activeCompany.address.country &&
                                        <DataRow
                                            title="Total Cost"
                                            value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}${job.duration === "4_weeks" ? JobStore.four_week_pricing : JobStore.six_week_pricing}`}
                                        />
                                    }

                                </div>


                                <ButtonFooter>
                                    <Button
                                        text="Save job details & proceed"
                                        customClass="button"
                                        style={{ width: '45%', float: 'right' }}
                                        onClick={() => this.submitJob(job)}
                                    />
                                    <Button
                                        text="Cancel"
                                        customClass="button btn-cancel"
                                        style={{ width: '30%', float: 'right' }}
                                        onClick={() => this.hideDuration()}
                                    />
                                </ButtonFooter>
                            </div>
                        }


                    </ContentContainer>
                    <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />

                </div>

            </div>
        );
    }
}
export default CreateJob;
