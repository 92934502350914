import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, Button, Breadcrumbs, Input } from '../../../components';

import AuthStore from '../../../stores/AuthStore'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


@observer
class ChangePassword extends Component {

    @observable active_sidebar_option = 'Settings'
    @observable sidebar_options = ['Settings']
    @observable password = ''
    @observable currentPassword = ''
    @observable passwordConfirmation = ''

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }


    changePassword = async () => {
        try {
            this.loading = true
            AuthStore.resetPassword(this.currentPassword, this.password, this.passwordConfirmation).then(res => {
                if (res.data) {
                    this.loading = false
                    ToastsStore.success("Password Successfully changed!")
                    setTimeout(
                        function () {
                            this.props.history.push('/dashboard/profile')
                        }
                            .bind(this),
                        1500
                    );

                } else {
                    this.loading = false
                }
            })
                .catch(err => {
                    this.loading = false
                    ToastsStore.success(err)
                })
        }
        catch (e) {
            this.loading = false
        }
    }


    render() {



        let { errors } = AuthStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/profile',
                title: 'Profile'
            },
            {
                link: '/dashboard/profile/password',
                title: 'Change Password'
            }
        ]
        return (

            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Change Password"
                    buttonText="Back"
                    buttonClass='button button-white'
                    buttonClick={() => {
                        this.props.history.goBack()
                    }}
                />

                <div className="page-content">


                    <ContentContainer>
                        <div className="main-content">
                            <h1>Change Password</h1>
                            <Input
                                label="Enter your password"
                                name="password"
                                type="password"
                                placeholder="Enter your password"
                                keyvalue={'password'}
                                value={this.currentPassword || ''}
                                onChange={text => this.currentPassword = text.target.value}
                                errors={errors}
                            />


                            <Input
                                label="Enter your new password"
                                name="password"
                                type="password"
                                placeholder="Enter your new password"
                                keyvalue={'password'}
                                value={this.password || ''}
                                onChange={text => this.password = text.target.value}
                                errors={errors}
                            />

                            <Input
                                label="Confirm your new password"
                                name="password"
                                type="password"
                                placeholder="Confirm your new password"
                                keyvalue={'city'}
                                value={this.passwordConfirmation || ''}
                                onChange={text => this.passwordConfirmation = text.target.value}
                                errors={errors}
                            />

                            {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                <div className="errorsContainer">
                                    {
                                        Object.keys(errors).map((key) => (
                                            errors[key].map((l, index) => (
                                                key == 'password' ? <p className="error" key={index}>{l}</p> : null
                                            ))
                                        ))
                                    }
                                </div>
                            }


                            <Button
                                text="Update Password"
                                customClass="button"
                                style={{ width: '45%', float: 'right' }}
                                onClick={() => this.changePassword()}
                            />

                        </div>

                        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />

                    </ContentContainer>
                </div>

            </div >
        );
    }
}
export default ChangePassword;
