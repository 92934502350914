import React, { Component } from "react";

import { ButtonFooter, Button } from "./";

export class OnboardingModal extends Component {
  render() {
    const {
      title,
      text,
      subHeading,
      buttonText,
      onPress,
      visible,
      onClose,
      image,
      style
    } = this.props;

    return (
      <div className={visible ? "custom-modal" : "custom-modal-hide"}>
        <div className="modal-content">
        <img style={style} src={image} />
          <div className="close-icon-cont" onClick={() => onClose()}>
            <img
              src={require("../assets/images/icons/close-black.png")}
              alt="Close Icon"
            />
          </div>
              {title && (
                <p
                  style={{
                    fontSize: 22,
                    textAlign: "center",
                    marginBottom: 12,
                    fontWeight: 500,
                  }}
                >
                  {title}
                </p>
              )}
              {subHeading && (
                <p style={{ fontSize: 16, textAlign: "center" }}>
                  {subHeading}
                </p>
              )}
              {text && (
                <p
                  style={{
                    fontSize: 16,
                    textAlign: "center",
                    color: "#59AE46",
                  }}
                >
                  {text}
                </p>
              )}
            <ButtonFooter
              style={{
                borderTopWidth: 0,
                justifyContent: "space-between",
                height: 70,
                alignItems: "flex-start",
              }}
            >
              <Button
                text={buttonText}
                style={{
                  width: "100%",
                  backgroundColor: "#59AE46",
                  height: 50,
                  borderWidth: 2,
                  borderColor: "#59AE46",
                  color: "white",
                  float: "right",
                }}
                textStyle={{ fontSize: 15 }}
                onClick={onPress}
              />
            </ButtonFooter>
          </div>
        </div>
    );
  }
}
