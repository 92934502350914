import React, { Component } from 'react';
import '../../App.scss';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, Breadcrumbs, TextArea, ImageSlider, ProfileImage, ButtonFooter, Button, Input } from '../../components';

import AuthStore from '../../stores/AuthStore'
import CompanyStore from '../../stores/CompanyStore'

import { cloneDeep } from 'lodash'

import FileBase64 from 'react-file-base64';
import { CountryDropdown } from 'react-country-region-selector';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


@observer
class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    @observable active_sidebar_option = 'My Profile'
    @observable sidebar_options = ['My Profile']


    @observable editedCompany = cloneDeep(CompanyStore.activeCompany)

    @observable imagesToBeDeleted = []
    @observable videos = CompanyStore.activeCompany.videos ? cloneDeep(CompanyStore.activeCompany.videos) : [{ name: "", link: "" }]
    @observable activeImage = null
    @observable modalVisible = false
    @observable editDisabled = false
    @observable selectedFile = null


    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }


    componentDidMount() {

        if (!this.editedCompany.id) {
            this.props.history.push('/dashboard/profile')
        }
        if (this.editedCompany.images) {
            this.editedCompany.images.unshift({
                "id": "placeholder",
                "image": "../../../../assets/images/placeholder.png",
            })
        }
        else if (!this.editedCompany.images) {
            this.editedCompany.images = []
            this.editedCompany.images.unshift({
                "id": "placeholder",
                "image": "../../../../assets/images/placeholder.png",
            })
        }
    }

    editImage = () => {
        let options = {
            maxWidth: 480,
            maxHeight: 480,
            quality: 0.9,
            customButtons: [],
        }

        if (this.editedCompany.logo) {
            options.customButtons.push({ name: 'delete', title: 'Remove Profile Picture' })
        }

    }

    fileUploadHandler = (e) => {
    }

    fileSelectedHandler = (data) => {
        if (data) {
            let size = parseInt(data[0].size.replace(/[^0-9\.]+/g, ""));
            if (data[0].type !== "image/jpeg") {
                ToastsStore.error("Please ensure your selected image is a jpeg format")
            } else if (size >= 3000) {
                ToastsStore.error("Please ensure your selected image is less than 6mb")
            } else {
                this.editedCompany.logo = data[0].base64
                this.editedCompany.base64 = data[0].base64
            }
        }
    }

    addImageClick = (data) => {
    }

    addimage = (data) => {
        if (data) {
            let size = parseInt(data[0].size.replace(/[^0-9\.]+/g, ""));
            if (data[0].type !== "image/jpeg") {
                ToastsStore.error("Please ensure your selected image is a jpeg format")
            } else if (size >= 3000) {
                ToastsStore.error("Please ensure your selected image is less than 6mb")
            } else {
                this.editedCompany.images.push({
                    filepath: data[0].base64,
                    base64: data[0].base64
                })
            }
        }

    }

    editCompany = company => {
        // this.editDisabled = true
        company.videos = this.videos

        CompanyStore.editCompany(company).then(res => {
            if (res == 200) {
                // this.editDisabled === false
                ToastsStore.success("Your profile has been updated")

                setTimeout(
                    function () {
                        this.props.history.push('/dashboard/profile')
                    }
                        .bind(this),
                    2000
                );

            }
        })
            .catch(error => {
            })
    }

    deleteImage = (id, count) => {

        CompanyStore.removeSlide(id)
            .then(res => {
                if (res == 200) {
                    let index = this.editedCompany.images.map(x => { return x.id }).indexOf(id)
                    this.editedCompany.images.splice(index, 1)
                    ToastsStore.success("Your image has been deleted")

                }
                else {
                    let index = this.editedCompany.images.map(x => { return x.id }).indexOf(id)
                    this.editedCompany.images.splice(index, 1)
                }
            })
            .catch(err => {
                ToastsStore.success("Sorry, could not delete your image at this time")
            })
    }

    deleteVideo = (index) => {
        this.videos.splice(index, 1)
    }

    render() {

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/profile',
                title: 'Profile'
            }
        ]

        let { errors } = CompanyStore

        return (

            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Edit Profile"
                    backButton={true}
                    onClick={() => {
                        this.props.history.push('/dashboard/profile')
                    }}
                />

                <div className="page-content">
                    <Sidebar
                        options={this.sidebar_options}
                        currentOption={this.active_sidebar_option}
                        onPress={this.setActiveSideBarOption.bind(this)}
                        buttonClick={() => {
                            this.props.history.push('/dashboard/profile')
                        }}

                    />

                    <ContentContainer>
                        {this.active_sidebar_option && this.active_sidebar_option == 'My Profile' &&
                            <div className="main-content">
                                <h1>{this.active_sidebar_option}</h1>

                                <div className="applicantProfile" onClick={this.fileUploadHandler}>
                                    <label>
                                        <ProfileImage
                                            image={this.editedCompany.logo}
                                            value={this.editedCompany.name}
                                            edit={true}

                                        />
                                        <div className="dn">
                                            <FileBase64
                                                ref={this.myRef}
                                                id="file-input"
                                                multiple={true}
                                                onDone={this.fileSelectedHandler.bind(this)} />
                                        </div>
                                    </label>

                                </div>

                                <Input
                                    label="Name"
                                    name="Name"
                                    type="text"
                                    placeholder="Enter your name"
                                    keyvalue={'name'}
                                    errors={errors}
                                    value={this.editedCompany.name || ''}
                                    onChange={text => this.editedCompany.name = text.target.value}
                                />


                                {this.editedCompany.address &&
                                    <div className="address_block">
                                        <div className="input-container">
                                            <label>Job Address</label>
                                        </div>
                                        <Input
                                            name="street_1"
                                            type="text"
                                            placeholder="Street Line 1"
                                            keyvalue={'street_1'}
                                            errors={errors}
                                            value={this.editedCompany.address.street_1 || ''}
                                            onChange={text => this.editedCompany.address.street_1 = text.target.value}
                                        />

                                        <Input
                                            name="street_2"
                                            type="text"
                                            placeholder="Street Line 2"
                                            keyvalue={'street_2'}
                                            errors={errors}
                                            value={this.editedCompany.address.street_2 || ''}
                                            onChange={text => this.editedCompany.address.street_2 = text.target.value}
                                        />

                                        <Input
                                            name="city"
                                            type="text"
                                            placeholder="Town/City"
                                            keyvalue={'city'}
                                            errors={errors}
                                            value={this.editedCompany.address.city || ''}
                                            onChange={text => this.editedCompany.address.city = text.target.value}
                                        />

                                        <Input
                                            name="zip"
                                            type="text"
                                            placeholder="Postcode"
                                            keyvalue={'zip'}
                                            errors={errors}
                                            value={this.editedCompany.address.zip || ''}
                                            onChange={text => this.editedCompany.address.zip = text.target.value}
                                        />

                                        <Input
                                            name="state"
                                            type="text"
                                            placeholder="County"
                                            keyvalue={'state'}
                                            errors={errors}
                                            value={this.editedCompany.address.state || ''}
                                            onChange={text => this.editedCompany.address.state = text.target.value}
                                        />

                                        <div>
                                            <div className="input-container">
                                                <CountryDropdown
                                                    value={this.editedCompany.address.country || ''}
                                                    whitelist={["GB", "IE"]}
                                                    onChange={(val) => this.editedCompany.address.country = val} />

                                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                                    <div className="errorsContainer country-error">
                                                        {
                                                            Object.keys(errors).map((key) => (
                                                                errors[key].map((l, index) => (
                                                                    key == 'country' ? <p className="error" key={index}>{l}</p> : null
                                                                ))
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                }

                                <div>
                                    <TextArea
                                        label="About the company"
                                        name="about"
                                        errors={errors}
                                        keyvalue="about"
                                        value={this.editedCompany.about || ''}
                                        onChange={text => this.editedCompany.about = text.target.value}
                                    />
                                </div>


                                <Input
                                    label="Website"
                                    name="Website"
                                    type="text"
                                    placeholder="Enter the company website"
                                    keyvalue={'website'}
                                    errors={errors}
                                    value={this.editedCompany.website || ''}
                                    onChange={text => this.editedCompany.website = text.target.value}
                                />



                                {this.editedCompany.images &&
                                    <div>
                                        <h4>Photos</h4>
                                        <label id="images">
                                            <ImageSlider
                                                data={toJS(this.editedCompany.images)}
                                                mode="edit"
                                                onDelete={this.deleteImage.bind(this)}
                                                onClick={this.addImageClick.bind(this)}
                                            />

                                            <div className="dn">
                                                <FileBase64
                                                    innerRef="images"
                                                    multiple={true}
                                                    onDone={this.addimage.bind(this)} />
                                            </div>

                                        </label>

                                    </div>
                                }




                                <div className="editVideos">
                                    <h4>Videos</h4>
                                    {
                                        this.videos && this.videos.length > 0 && this.videos.map((l, index) => (

                                            <div className="videoRows" key={index}>

                                                <div style={{ width: "50%" }}>
                                                    <Input
                                                        label={null}
                                                        placeholder="Video Title"
                                                        styles={{ flex: 1, marginRight: 3, marginBottom: 0 }}
                                                        value={this.videos[index].name || ''}
                                                        onChange={text => this.videos[index].name = text.target.value}
                                                    />
                                                </div>

                                                <div style={{ width: "50%" }}>
                                                    <Input
                                                        label={null}
                                                        placeholder="https://www.youtube.com/watch?v=lM02vNMRRB0"
                                                        styles={{ flex: 1, marginHorizontal: 3, marginBottom: 0 }}
                                                        value={this.videos[index].link || ''}
                                                        onChange={text => this.videos[index].link = text.target.value}
                                                    />
                                                </div>

                                                <div className="deleteVideoBlock" onClick={() => this.deleteVideo(index)}>
                                                    <img src={require('../../assets/images/deleteIcon.png')} alt="Delete Icon" />
                                                </div>

                                            </div>

                                        ))
                                    }
                                </div>

                                <div onClick={() => { this.videos.push({ name: "", link: "" }) }} className="newVideoBlock">
                                    <img src={require('../../assets/images/plus.png')} alt="Add New Video Icon" />
                                </div>


                                <div className="cb">
                                    <ButtonFooter>
                                        <Button
                                            text="Save Profile"
                                            customClass="button"
                                            style={{ width: '45%', float: 'right' }}
                                            onClick={() => this.editCompany(this.editedCompany)}
                                        />
                                        <Button
                                            text="Cancel"
                                            customClass="button btn-cancel"
                                            style={{ width: '30%', float: 'right' }}
                                            onClick={() => this.props.history.goBack()}
                                        />
                                    </ButtonFooter>
                                </div>

                            </div>
                        }
                        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
                    </ContentContainer>
                </div>



            </div >
        );
    }
}
export default EditProfile;
