import React, { Component } from "react";
import "../../App.scss";
import { observer } from "mobx-react";
import { Button } from "../../components";
import { Helmet } from "react-helmet";
import { SkipButton } from "../../components/_skipButton";
import { OnboardingFooter } from "../../components/_onboardingFooter";

@observer
class ScreenTwo extends Component {
  render() {
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Onboarding</title>
        </Helmet>

        <div className="main">
          <div className="onboardingNav">
            <div className="backButton">
              <a
                href="/onboarding/screenOne"
                style={{
                  textDecoration: "none",
                  margin: 0,
                  marginLeft: 9,
                  marginBottom: 20,
                }}
              >
                <img
                  src={require("../../assets/images/arrow-left.png")}
                  style={{ marginLeft: 40, marginRight: 10, marginTop: 5 }}
                />
                <span style={{ color: "black", paddingBottom: 30 }}>Back</span>
              </a>
            </div>
            <div className="onboardingNavSkip">
              <SkipButton />
            </div>
          </div>

          <div className="onboarding">
            <img src={require("../../assets/images/handshake.png")} />
            <div className="onboarding-container">
              <h1>Get started!</h1>
              <h3>STEP 1</h3>
              <h5>
                <br /> It’s quick and simple, just send them an invite link from
                the employee section in Pear Up. <br />
              </h5>
            </div>
            <img src={require("../../assets/images/progress2.png")} />
            <blockquote />
            <Button
              customClass="buttonNext"
              text="Next"
              onClick={() => this.props.history.push("/onboarding/screenThree")}
            ></Button>
          </div>
          <img
            src={require("../../assets/images/leaf-bg.svg")}
            className="leaf-bg"
            alt="Pear Up Logo"
          />
        </div>
        <OnboardingFooter />
      </div>
    );
  }
}
export default ScreenTwo;
