import React, { Component } from 'react';

export class DataRowVideo extends Component {

    render() {

        const { title, value, link } = this.props

        return (
            <div className="dataRow">
                <div className="dataRowTitle">
                    <p>{title}</p>
                </div>

                <div className="dataRowValue">
                    <a target="_blank" rel="noopener noreferrer" href={link}>{value}</a>
                </div>

            </div>
        );

    }
}
