import React, { Component } from 'react';

export class EditUserProfile extends Component {

    render() {

        const { active, onClick, disabled, style = {}, customClass } = this.props

        return (
            <div className="editUserProfile">
                <button className={customClass} style={style} onClick={onClick} disabled={disabled}>
                    <img src={require('../assets/images/icons/editUser.svg')} alt="share icon" />
                </button>
            </div>
        );

    }
}
