import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, Pill, ButtonFooter, Button, ConfirmationModal } from '../../components';

import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'

import moment from "moment";

@observer
class DurationSelectionJob extends Component {

    @observable postTimeRange = []

    componentDidMount() {
        if (JobStore.job.id) { }
        else {
            JobStore.getJob(this.props.match.params.id)
        }
    }

    updatePricing = (four_week, six_week) => {
        this.postTimeRange = [{
            'id': 1,
            'name': '4 weeks',
            'price': four_week,
            'duration': '4_weeks'
        },
        {
            'id': 2,
            'name': '6 weeks',
            'price': six_week,
            'duration': '6_weeks'
        }]
    }

    setActiveJobDuration = (duration) => {
        JobStore.job.duration = duration.value
    }

    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }

    filterJobs = (jobs) => {
        if (this.active_sidebar_option == 'Closed') {
            return jobs.filter(el => el.active == 0 && el.paid != null)
        }
        else if (this.active_sidebar_option == 'Pending') {
            return jobs.filter(el => el.active == 0 && el.paid == null)
        }
        else if (this.active_sidebar_option === 'Applicants') {

        }
        else {
            return jobs.filter(el => el.active == 1)
        }
    }

    goToProfile = (job_id, user_id) => {

    }

    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    render() {

        let { state, errors, job } = JobStore
        let { activeCompany, allIndustries } = CompanyStore

        return (
            <div className="page jobs">
                <Header
                    title="Jobs"
                    backButton={true}
                    onClick={() => {
                        this.props.history.push('/dashboard/jobs')
                    }}

                />
                <div className="page-content">
                    {activeCompany.id &&
                        <ContentContainer>
                            <div className="main-content">
                                <h1>Job Details</h1>

                                <div className="f-width pill-block">
                                    <div className="input-container">
                                        <label>Duration</label>
                                    </div>
                                    {
                                        this.postTimeRange.map((l, index) => (
                                            <Pill
                                                skill={l}
                                                key={l.id}
                                                selected={job.duration === l.duration}
                                                onClick={this.setActiveJobDuration.bind(this)}
                                            />
                                        ))
                                    }
                                </div>
                                {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                    <div className="errorsContainer">
                                        {
                                            Object.keys(errors).map((key) => (
                                                errors[key].map((l, index) => (
                                                    key == 'duration' ? <p className="error" key={index}>{l}</p> : null
                                                ))
                                            ))
                                        }
                                    </div>
                                }


                                <ButtonFooter>
                                    <Button
                                        text="Confirm & checkout"
                                        customClass="button"
                                        style={{ width: '45%', float: 'right' }}
                                        onClick={() =>
                                            this.props.history.push('/dashboard/job/confirm/' + JobStore.job.id)
                                        }
                                    />
                                </ButtonFooter>

                            </div>

                        </ContentContainer>
                    }

                </div>


                <ConfirmationModal
                    visible={this.confirmationModal}
                    title={`Are you sure you wish to close this job?`}
                    text="This action cannot be undon...e"
                    declineText="No, cancel"
                    onClose={() => { this.confirmationModal = false }}
                    acceptText="Yes, close"
                    acceptFunc={() => this.closeJob(job.id)}
                />

            </div>
        );
    }
}
export default DurationSelectionJob;
