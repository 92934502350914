import React, { Component, Fragment } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, ProfileImage, DataRow, DataRowInfo, Button, ButtonFooter, ConfirmationModal, Breadcrumbs, MessageUserButton, EditUserProfile, TableRowList, WorkHistoryRow } from '../../components';

import AuthStore from '../../stores/AuthStore'
import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'

import moment from "moment";
import { isEmpty } from 'lodash'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

@observer
class ApplicantProfile extends Component {

    @observable active_sidebar_option = 'Description'

    @observable sidebar_options = ['Description']

    @observable requests = []

    @observable confirmationModal = false
    @observable status = "decline"
    @observable buttonsDisabled = false

    @observable breadcrumbs = []
    @observable friendStatusDropdown = false



    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    remove = () => {
        this.friendStatusDropdown = false
    }

    applicantStatus = () => {
        let obj = {
            status: this.status == "decline" ? "declined" : "accepted"
        }

        this.confirmationModal = false

        JobStore.setStatus(JobStore.job.id, JobStore.applicant.id, obj).then(response => {
            if (response === 200) {
                JobStore.getApplicants(JobStore.job.id).then(res => {
                    if (res) {
                        JobStore.setApplicant(JobStore.applicant.id).then(applicant => {
                            if (applicant) {
                                this.buttonsDisabled = true
                                ToastsStore.success('This applicant has been ' + obj.status)
                                setTimeout(
                                    function () {
                                        this.props.history.goBack()
                                    }.bind(this),
                                    1500
                                );
                            }
                        })
                    }
                })
            }
        })

    }

    processReferrals = (referrals) => {
        const referrals_length = referrals.length;
        let text = '';

        referrals.map((referee, index) => {
            if (referrals_length === index + 1) {
                text += referee.first_name + ' ' + referee.last_name
            }
            else {
                text += referee.first_name + ' ' + referee.last_name + ', '
            }
        })


        return text
    }

    componentDidMount() {
        if (this.props.match.params) {
            if (this.props.match.params.jobId) {
                JobStore.getJob(this.props.match.params.jobId)
            }
        }

        if (!JobStore.applicant.id) {
            JobStore.getApplicants(parseInt(this.props.match.params.jobId)).then(res => {
                if (res === 200) {
                    JobStore.getJob(this.props.match.params.jobId)
                    JobStore.setApplicant(parseInt(this.props.match.params.id))
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/employees',
                            title: 'Employees'
                        },
                        {
                            link: this.props.location.pathname,
                            title: JobStore.applicant.full_name
                        }
                    ]
                }
            })
        }

        if (this.props.location.state) {
            if (this.props.location.state.fromScreen) {
                if (this.props.location.state.fromScreen === "jobs") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/jobs',
                            title: 'Jobs'
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id,
                            title: JobStore.job.name
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id + '/applicant/' + JobStore.applicant.id,
                            title: JobStore.applicant.full_name
                        }
                    ]
                } else if (this.props.location.state.fromScreen === "job") {
                    JobStore.getApplicants(parseInt(this.props.match.params.jobId)).then(res => {
                        if (res) {
                            JobStore.getJob(this.props.match.params.jobId)
                            JobStore.setApplicant(parseInt(this.props.match.params.id))

                            this.breadcrumbs = [
                                {
                                    link: '/dashboard',
                                    title: 'Dashboard'
                                },
                                {
                                    link: '/dashboard/jobs',
                                    title: 'Jobs'
                                },
                                {
                                    link: '/dashboard/jobs/job/' + JobStore.job.id,
                                    title: JobStore.job.name
                                },
                                {
                                    link: '/dashboard/jobs/job/' + this.props.match.params.jobId + '/applicant/' + JobStore.applicant.id,
                                    title: JobStore.applicant.full_name
                                }
                            ]
                        }
                    });

                } else if (this.props.location.state.fromScreen === "employees") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/employees',
                            title: 'Employees'
                        },
                        {
                            link: '/dashboard/employees/applicant/' + JobStore.applicant.id,
                            title: JobStore.applicant.full_name
                        }
                    ]
                }
            }
        }
    }

    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }


    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    render() {

        let { job, applicant } = JobStore
        let { activeCompany } = CompanyStore
        return (
            <div className="page jobs">
                {!isEmpty(applicant) &&
                    <Fragment>

                        <Breadcrumbs
                            data={this.breadcrumbs}
                        />

                        <Header
                            title="Jobs"
                            buttonText="Create a job"
                            backButton={true}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                            buttonClick={() => {
                                JobStore.resetJob()
                                this.props.history.push('/dashboard/job/create')
                            }}
                        />
                        <div className="page-content">
                            <Sidebar
                                options={this.sidebar_options}
                                currentOption={this.active_sidebar_option}
                                onPress={this.setActiveSideBarOption.bind(this)}
                            />

                            {this.active_sidebar_option && this.active_sidebar_option === 'Description' &&
                                <ContentContainer>

                                    {/* {JSON.stringify(JobStore.job.name)} */}

                                    <div className="main-content">
                                        <div className="applicantProfile">
                                            <ProfileImage
                                                image={applicant.logo}
                                                value={applicant.full_name}
                                            />

                                            {applicant.application_status != 'pending' &&
                                                <EditUserProfile
                                                    onClick={() => {
                                                        this.friendStatusDropdown = true
                                                    }}
                                                />
                                            }
                                        </div>

                                        <DataRow
                                            title="Name"
                                            value={applicant.full_name}
                                        />

                                        <DataRow
                                            title="Date of Birth"
                                            value={moment(applicant.date_of_birth).calendar()}
                                        />

                                        <DataRow
                                            title="Address"
                                            value={applicant.address}
                                        />


                                        <DataRowInfo
                                            title="Description"
                                            value={applicant.description}
                                        />

                                        {applicant.work_history && applicant.work_history.length > 0 &&
                                            <WorkHistoryRow
                                                title="Work History"
                                                value={applicant.work_history}
                                            />
                                        }


                                        {applicant.skills && applicant.skills.length > 0 &&
                                            <TableRowList
                                                title="Skills"
                                                value={applicant.skills}
                                            />
                                        }

                                        {applicant.educations && applicant.educations.length > 0 &&
                                            <TableRowList
                                                title="Education"
                                                value={applicant.educations}
                                            />
                                        }

                                        {applicant.certificates && applicant.certificates.length > 0 &&
                                            <TableRowList
                                                title="Certificates"
                                                value={applicant.certificates}
                                            />
                                        }

                                        {applicant.referrals &&
                                            applicant.referrals.length > 0 &&
                                            <DataRowInfo
                                                title="Referred By"
                                                value={this.processReferrals(applicant.referrals)}
                                            />
                                        }

                                        {activeCompany && applicant.application_status != 'pending' &&
                                            <ButtonFooter>
                                                <MessageUserButton
                                                    customClass="button btn-black-message"
                                                    onClick={() => {
                                                        window.location.href = '/dashboard/messages/' + applicant.id + '/' + activeCompany.id
                                                    }}
                                                />
                                            </ButtonFooter>
                                        }

                                        {applicant.application_status === 'pending' &&
                                            <ButtonFooter>
                                                {activeCompany &&
                                                    <MessageUserButton
                                                        customClass="button btn-black-message"
                                                        onClick={() => {
                                                            window.location.href = '/dashboard/messages/' + applicant.id + '/' + activeCompany.id
                                                        }}
                                                    />
                                                }

                                                <Button
                                                    text="Accept"
                                                    customClass="button"
                                                    style={{ width: '45%', float: 'right' }}
                                                    onClick={() => {
                                                        this.status = "accept"
                                                        this.confirmationModal = true
                                                    }}
                                                />
                                                <Button
                                                    text="Decline"
                                                    customClass="button btn-cancel"
                                                    style={{ width: '30%', float: 'right' }}
                                                    onClick={() => {
                                                        this.status = "decline"
                                                        this.confirmationModal = true
                                                    }}
                                                />
                                            </ButtonFooter>
                                        }

                                    </div>



                                </ContentContainer>

                            }
                        </div>

                        <ConfirmationModal
                            visible={this.confirmationModal}
                            title={`Are you sure you wish to ${this.status} ${applicant.full_name} for the job?`}
                            text="The applicant will receive a notification about your decision..."
                            declineText="No, cancel"
                            onClose={() => { this.confirmationModal = false }}
                            acceptText={`Yes, ${this.status}`}
                            acceptFunc={this.applicantStatus.bind(this)}
                        />

                        <ConfirmationModal
                            visible={this.friendStatusDropdown}
                            title={`Are you sure you wish to remove ${applicant.full_name} as an employee?`}
                            text="The user will receive a notification about your decision..."
                            declineText="No, cancel"
                            onClose={() => { this.friendStatusDropdown = false }}
                            acceptText="Yes, remove"
                            acceptFunc={this.remove.bind(this)}
                        />
                    </Fragment>
                }

                <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
            </div>
        );
    }
}
export default ApplicantProfile;
