import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';

export class InputSearch extends Component {

	render() {

		return (
			<div className="search-container">
				<SearchIcon className="searchIcon" />
				<input
					{...this.props}
					className="inputSearch"
				/>
			</div>
		);

	}
}
