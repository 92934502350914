import React, { Component } from 'react';

export class ShareJobButton extends Component {

	render() {

		const { text, onClick, disabled, style = {}, customClass } = this.props

		return (
			<button className={customClass} style={style} onClick={onClick} disabled={disabled}>
                <img src={require('../assets/images/icons/Share.svg')} alt="share icon" /> {text}
			</button>
		);

	}
}
