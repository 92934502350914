import React from 'react';
import PearUpLg from '../../assets/images/icons/icon-lg.png';


import sampleVidMP4 from '../../assets/videos/pearup-video.MP4'
import sampleVidWebm from '../../assets/videos/pearup-video.webm'

const Landing = () => {
    return (
        <div className="landing content">
            <div className="landingLeft">
                <img src={PearUpLg} />

                <h1>PEAR UP</h1>
                <p>Find your next job or employees straight from your phone…</p>
                <div className="btnContainer">
                    <a href="mailto:info@pearupjobs.com">Contact Us</a>
                    <a href="\login">Sign In</a>
                </div>
            </div>
            <div className="landingRight">
                <div className="marvel-device iphone-x">
                    <div className="inner"></div>
                    <div className="overflow">
                        <div className="shadow"></div>
                    </div>
                    <div className="speaker"></div>
                    <div className="sensors"></div>
                    <div className="more-sensors"></div>
                    <div className="sleep"></div>
                    <div className="volume"></div>
                    <div className="camera"></div>
                    <div className="screen">
                        <video autoPlay muted loop>
                            <source src={sampleVidMP4} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;