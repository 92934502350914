import React, { Component } from 'react';
import '../../App.scss';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';
import { ProfileImageSimple, DashboardPageNav, Button } from '../../components';

import CompanyStore from '../../stores/CompanyStore'
import { Helmet } from "react-helmet";

@observer
class Dashboard extends Component {

    render() {
        let { activeCompany } = CompanyStore

        return (
            <Container fixed>
                <Helmet>
                    <title>Pear Up | Dashboard</title>
                    <meta name="description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="keywords" content="Recruitment, Recruitment app, Part-time job, Job search, Jobs, Jobs near me, Temporary work, Local jobs, Local work, Construction jobs, Retail jobs, Delivery driver jobs, Hourly work, Jobs Northern Ireland" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Dashboard" />
                    <meta property="og:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta property="og:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Dashboard" />
                    <meta name="twitter:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="twitter:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <div className="dashboard-error">
                    <h2>404</h2>
                    <h3>Whoops, something went wrong</h3>
                    <p>To find what you're looking for, please use the main menu</p>
                    <Button
                        text='Go Back'
                        customClass="button"
                        textStyle={{ fontSize: 12 }}
                        onClick={() => {
                            history.back()
                        }}
                    />
                </div>
            </Container>
        );
    }
}
export default Dashboard;
