import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { PanelNav } from '../components';

export class DashboardPageNav extends Component {

    render() {
        return (
            <div className="panelNavCont">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={6}
                >
                    <Grid item xs={12} md={6} lg={4}>
                        <PanelNav
                            image={require('../assets/images/icons/jobs.png')}
                            title="Jobs"
                            description="Manage and create jobs advertised for your company…"
                            link="/dashboard/jobs"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <PanelNav
                            image={require('../assets/images/icons/employees.png')}
                            title="Employees"
                            description="Manage and invite employees to your company…"
                            link="/dashboard/employees"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <PanelNav
                            image={require('../assets/images/icons/messages.png')}
                            title="Messages"
                            description="Message employees and keep up to date with jobs…"
                            link="/dashboard/messages"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <PanelNav
                            image={require('../assets/images/icons/notifications.png')}
                            title="Notifications"
                            description="View all notifications that you want  to be made aware of"
                            link="/dashboard/notifications"
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <PanelNav
                            image={require('../assets/images/icons/profile.png')}
                            title="Profile"
                            description="Manage your account information."
                            link="/dashboard/profile"
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

