import React, { Component, useEffect } from 'react';
import { Fade, Zoom, Slide } from 'react-reveal';

import '../../App.scss';
import Header from '../../components/homepage/Header';
import Footer from '../../components/homepage/Footer';
import Landing from '../../components/homepage/Landing';
import IOS from '../../components/homepage/IOS';
import Android from '../../components/homepage/Android';
import WhatIsIt from '../../components/homepage/WhatIsIt';
import AboutUs from '../../components/homepage/AboutUs';
import GetStarted from '../../components/homepage/GetStarted';
import CookieBox from '../../components/homepage/CookieBox';

import Leaf from '../../assets/images/homepage/Leaf.png';
import Doves from '../../assets/images/homepage/Doves.png';
import Cross from '../../assets/images/homepage/landingCross.png';
import Dots from '../../assets/images/homepage/Dots.png';

import "animate.css/animate.min.css";
import { Helmet } from "react-helmet";


class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cookieBoxShow: null,
            menuShow: false
        }

        this.hideCookieBox = this.hideCookieBox.bind(this);
    }


    componentDidMount() {
        if (localStorage.getItem('cookies') > 0) {
            this.hideCookieBox();
        } else {
            this.setState({
                cookieBoxShow: true
            });
        }
    }

    hideCookieBox = () => {
        this.setState({
            cookieBoxShow: false
        });
        localStorage.setItem('cookies', 1);
    }

    toggleMenu = () => {
        this.setState({
            menuShow: !this.state.menuShow
        })
    }

    closeMenu = () => {
        this.setState({
            menuShow: false
        })
    }

    render() {

        return (


            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <Helmet>
                    <title>Pear Up | Find your next job or employees straight from your phone</title>
                    <meta name="description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="keywords" content="Recruitment, Recruitment app, Part-time job, Job search, Jobs, Jobs near me, Temporary work, Local jobs, Local work, Construction jobs, Retail jobs, Delivery driver jobs, Hourly work, Jobs Northern Ireland" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Dashboard" />
                    <meta property="og:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta property="og:image" content="../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Dashboard" />
                    <meta name="twitter:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="twitter:image" content="../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <Header show={this.state.menuShow} toggle={this.toggleMenu} close={this.closeMenu} />
                <div className="page-cont">
                    <img src={Doves} className="doves" />
                    <img src={Cross} className="cross" />
                    <div className="bg-shape-1"></div>
                    <Fade top delay={500} duration={1000}>
                        <Landing />
                    </Fade>
                    <WhatIsIt />
                    <Fade delay={200} duration={1500} left>
                        <IOS />
                    </Fade>
                    <Fade delay={500} duration={1500} right>
                        <Android />
                    </Fade>
                    <AboutUs />
                    <div className="dot-cont">
                        <img src={Dots} className="dots" />
                    </div>
                    <GetStarted />
                    <CookieBox display={this.state.cookieBoxShow} hide={this.hideCookieBox} />
                    <img src={Leaf} className="leaf-bg" />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Home;





