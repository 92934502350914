import React, { Component} from 'react';

export class SkipButton extends Component {

	render() {

		return (
			<a href="/dashboard/employees"  style={{ textDecoration: 'none', fontWeight: 700, marginTop: 10 }}>
                <span style={{ color: "#59AE46", marginRight: 50, marginTop: 50 }}>Skip</span>
              </a>
		);
	}
}
