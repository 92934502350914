import React, { Component } from 'react';

export class DataRow extends Component {

	parseObj = object => {
		return {
			street_1: object.street_1,
			street_2: object.street_2 ? object.street_2 : null,
			city: object.city,
			state: object.state,
			zip: object.zip,
			country: object.country
		}
	}

	render() {

		const { title, value } = this.props

		return (
			<div className="dataRow">
				<div className="dataRowTitle">
					<p>{title}</p>
				</div>

				<div className="dataRowValue">
					{
						typeof value != "object" &&
						<p>
							{value}
						</p>
					}
					{
						typeof value == "object" && Object.keys(this.parseObj(value)).map(key => (
							value[key] ? <p key={key} >{value[key]}</p> : null
						))
					}
				</div>

			</div>
		);

	}
}
