import React, { Component } from 'react';
import { Button } from './'

export class Header extends Component {

  render() {
    const { title, backButton, buttonText, buttonClass, onClick, buttonClick } = this.props
    return (
      <div className="header">
        <div>
          <h1>{title}</h1>
          {backButton &&
            <button className="btn-back" onClick={onClick}>Back</button>
          }
        </div>
        <div>
          {buttonText &&
            <Button customClass={!buttonClass ? 'button' : buttonClass} text={buttonText} onClick={buttonClick} />
          }
        </div>
      </div>
    );
  }
}
