import React from 'react';
import DashboardExample from '../../assets/images/homepage/Dashboard.png';
import LoginExample from '../../assets/images/homepage/LoginExample.png';
import MobileExample from '../../assets/images/homepage/MobileExample.png';
import Dots from '../../assets/images/homepage/Dots.png';
import { Slide, Fade } from 'react-reveal';

const WhatIsIt = () => {

    return (
        <div id="WhatIsIt" className="section whatIsIt">
            <Slide top>
            <div className="sectionTitle">
                <h2>What Is It?</h2>
                <hr />
            </div>

        <div className="container">

        <div className="intro">
            <p><b>
            Are you an hourly worker, struggling to find a job that works for you? Is finding new opportunities based on word of mouth or luck alone? Do you struggle to know where to look?
            </b></p>
            <p><b>Or are you a business owner desperate to recruit a solid workforce, the beating heart of your company, but you’re chasing your tail trying to find the right people with the right skills in the right location?
            </b>
            </p>
            <p>Pear-Up is the clear-cut solution everyday industries have been looking for. A straight-talking, cutting-edge mobile app with a straightforward mission: to solve the issues of hourly recruitment in a simple functioning platform.</p>
            <p>It’s no secret that it’s the people that make everyday industries. So helping them find their next role is what we’re passionate about. We’re set on giving the backbone of our workforce a voice in online recruitment.</p>
            <p>And because these workers are a company’s greatest resource, our mission is to simplify finding them. Pear Up gives businesses the tools they need to keep their wheels turning; to find the right people to get the job done.</p>
        </div>    

        <div className="features">
        
            <h3>In App Features...</h3>
            <hr/>

            <ul>
                <li>Pear Up’s next generation features takes the platform from a standard job board to an innovative, peer-to-peer social media platform. Using a blend of social media, geo-location and direct messaging, Pear Up revolutionises recruitment in everyday industries.</li>
                <li>Word-of-mouth has long been the foundation of jobsearches in these industries, and there’s no denying that it’s worked well in the past. So we’re bringing word-of-mouth online; integrating social media and referral rewards to incentivise employees, taking traditional recruitment to the next level.</li>
                <li>One of the greatest challenges in a job search is finding the right role that is local to you. Our geo-location feature will drop users into a map, notifying them of nearby openings.</li>
                <li>Our direct messaging feature removes the middle-man from job searches and speeds up the hiring process. Companies can contact applicants directly through Pear Up. </li>
                <li>Our ‘No CV’ policy removes the smoke screen from a typical job search and allows companies to review applicants on a more personal level. Job seekers’ profile gives a short rundown of their skillsand experience, while Company Profiles gives applicants insights into the culture and core values they hold.  </li>
            </ul>

            </div>
        </div>
            </Slide>

            <Fade duration={1500} left>
            <div className="imgContTop">
                <img src={Dots} />
                <img src={LoginExample} />
            </div>
            </Fade>

            <Fade duration={1000} right>
            <div className="imgContBottom">
                <img src={MobileExample} />
                <img src={DashboardExample} />
            </div>
            </Fade>
        </div>
    );
}


export default WhatIsIt;