import React, { Fragment, Component } from 'react';

import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, EmptyState, Breadcrumbs, MessageRow } from '../../components';


import AuthStore from '../../stores/AuthStore'
import CompanyStore from '../../stores/CompanyStore'

@observer
class MessageHome extends Component {

    @observable active_sidebar_option = 'All'
    @observable sidebar_options = ['All', 'Read', 'Unread']
    @observable messages = []
    @observable read = 0
    @observable unread = 0


    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    componentDidMount() {
        CompanyStore.getMessages()
            .then(res => {
                if (res.data) {
                    if (res.data.length > 0) {
                        this.messages = res.data

                        for (var i = 0; i < this.messages.length; i++) {
                            var msg = this.messages[i];
                            if (msg.company_seen) {
                                this.read = this.read + 1
                            } else {
                                this.unread = this.unread + 1
                            }
                        }
                    }
                }
            })
            .catch(err => {
                this.messages = [];
            })
    }


    truncate = (text) => {
        if (text.length > 30)
            return text.substring(0, 30) + '...';
        else
            return text;
    }
    goToConversation = (user_id, company_id, name) => {
        this.props.history.push('/dashboard/messages/' + user_id + '/' + company_id)
    }



    render() {
        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/messages',
                title: 'Messages'
            }
        ]

        return (
            <Fragment>
                <div className="page jobs">
                    <Breadcrumbs
                        data={breadcrumbs}
                    />

                    <Header
                        title="Messages"
                        buttonText="New Message"
                        buttonClick={() => {
                            this.props.history.push('/dashboard/messages/new')
                        }}
                    />

                    <div className="page-content">
                        <Sidebar
                            options={this.sidebar_options}
                            currentOption={this.active_sidebar_option}
                            onPress={this.setActiveSideBarOption.bind(this)}
                            buttonClick={() => {
                                this.props.history.push('/dashboard/job/create')
                            }}

                        />

                        <ContentContainer>
                            {this.active_sidebar_option && this.active_sidebar_option == 'All' &&
                                <div className="main-content">
                                    <h1>All Messages</h1>
                                    <p>You have <span className="message-length"> ({this.messages.length})</span> messages</p>

                                    {
                                        this.messages.map(thread => (
                                            thread.user &&
                                            <MessageRow
                                                key={thread.id}
                                                name={thread.user.first_name + ' ' + thread.user.last_name}
                                                message={this.truncate(thread.latest_message.body)}
                                                image={thread.user.logo}
                                                seen={thread.company_seen}
                                                created={thread.latest_message.created_at}
                                                onClick={() => this.goToConversation(thread.user_id, thread.company_id, thread.user.first_name + ' ' + thread.user.last_name)}
                                            />
                                        ))
                                    }

                                    {this.messages < 1 &&
                                        <EmptyState
                                            image={require('../../assets/images/icons/noData.png')}
                                            title="No Messages"
                                        />
                                    }

                                </div>
                            }

                            {this.active_sidebar_option && this.active_sidebar_option == 'Read' &&
                                <div className="main-content">
                                    <h1>All Read Messages</h1>

                                    {
                                        this.messages.map(thread => (
                                            thread.user &&
                                            thread.company_seen === 1 &&
                                            <MessageRow
                                                key={thread.id}
                                                name={thread.user.first_name + ' ' + thread.user.last_name}
                                                message={this.truncate(thread.latest_message.body)}
                                                image={thread.user.logo}
                                                seen={thread.company_seen}
                                                created={thread.latest_message.created_at}
                                                onClick={() => this.goToConversation(thread.user_id, thread.company_id, thread.user.first_name + ' ' + thread.user.last_name)}
                                            />
                                        ))
                                    }

                                    {this.read === 0 &&
                                        <EmptyState
                                            image={require('../../assets/images/icons/noData.png')}
                                            title="No Read Messages"
                                        />
                                    }

                                </div>
                            }

                            {this.active_sidebar_option && this.active_sidebar_option == 'Unread' &&
                                <div className="main-content">
                                    <h1>All Unread Messages</h1>

                                    {this.messages.map(thread => (
                                        thread.user &&
                                        !thread.company_seen &&
                                        <MessageRow
                                            key={thread.id}
                                            name={thread.user.first_name + ' ' + thread.user.last_name}
                                            message={this.truncate(thread.latest_message.body)}
                                            image={thread.user.logo}
                                            seen={thread.company_seen}
                                            created={thread.latest_message.created_at}
                                            onClick={() => this.goToConversation(thread.user_id, thread.company_id, thread.user.first_name + ' ' + thread.user.last_name)}
                                        />
                                    ))
                                    }
                                
                                    {this.unread === 0 &&
                                        <EmptyState
                                            image={require('../../assets/images/icons/noData.png')}
                                            title="No Unread Messages"
                                        />
                                    }

                                </div>
                            }

                        </ContentContainer>
                    </div>
                </div >
            </Fragment>
        );
    }
}
export default MessageHome;
