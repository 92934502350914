import Config from '../config'
import { observable, action, toJS } from 'mobx'
import { cloneDeep } from 'lodash'

class Skill {
  @observable id
  @observable name
  @observable status
  @observable created_at
  @observable updated_at

  constructor(value) {
    this.id = value.id
    this.name = value.name
    this.status = value.status
    this.created_at = value.created_at
    this.updated_at = value.updated_at
  }
}

class Image {
  @observable id
  @observable filepath
  @observable created_at
  @observable updated_at

  constructor(value) {
    this.id = value.id
    this.filepath = value.filepath
    this.created_at = value.created_at
    this.updated_at = value.updated_at
  }
}

class Video {
  @observable id
  @observable link
  @observable name
  @observable created_at
  @observable updated_at

  constructor(value) {
    this.id = value.id
    this.link = value.link
    this.name = value.name
    this.created_at = value.created_at
    this.updated_at = value.updated_at
  }
}

class Address {
  @observable id
  @observable city
  @observable country
  @observable created_at
  @observable updated_at
  @observable state
  @observable street_1
  @observable street_2
  @observable zip
  @observable longitude
  @observable latitude

  constructor(value) {
    this.id = value.id
    this.city = value.city
    this.country = value.country
    this.created_at = value.created_at
    this.updated_at = value.updated_at
    this.state = value.state
    this.street_1 = value.street_1
    this.street_2 = value.street_2
    this.zip = value.zip
    this.longitude = value.longitude
    this.latitude = value.latitude
  }
}

class Company {
  @observable id
  @observable email
  @observable name
  @observable status
  @observable email_verified_at
  @observable logo
  @observable about
  @observable website
  @observable created_at
  @observable updated_at
  @observable stripe_id
  @observable card_brand
  @observable card_last_four
  @observable trial_ends_at
  @observable images
  @observable address
  @observable videos
  @observable about

  constructor(value) {
    this.id = value.id
    this.email = value.email
    this.name = value.name
    this.about = value.about
    this.status = value.status
    this.email_verified_at = value.email_verified_at
    this.logo = value.logo
    this.about = value.about
    this.website = value.website
    this.created_at = value.created_at
    this.updated_at = value.updated_at
    this.stripe_id = value.stripe_id
    this.card_brand = value.card_brand
    this.card_last_four = value.card_last_four
    this.trial_ends_at = value.trial_ends_at
    this.images = this.processImages(value.imageable)
    this.address = new Address(value.address)
    this.videos = this.processVideos(value.videos)
  }

  processSkills = (skills) => {
    let arr = []
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill))
      })
    }
    return arr
  }

  processImages = (images) => {
    let arr = []
    if (images) {
      images.forEach((image, index) => {
        arr.push(new Image(image))
      })
      return arr
    }
  }

  processVideos = (videos) => {
    if (videos) {
      let arr = []
      videos.forEach((video, index) => {
        arr.push(new Video(video))
      })
      return arr
    }
  }
}


class CompanyStore {

  // state = pending || complete || error
  @observable state = ""
  @observable errors = {}
  @observable allSkills = []
  @observable allIndustries = []
  @observable activeCompany = {}
  @observable authed = {}
  @observable onboarded = false

  @action.bound
  setOnboarding = (data) => {
    if (data == 1) {
      this.onboarded = data
    }
    else {
      this.onboarded = 0
    }
  }

  checkAuth = async () => {
    //NO AWAIT!
    let user_key = localStorage.getItem('user_key')
    if (user_key) {
      Config.userKey = user_key
      return fetch(Config.api + '/auth/check-auth', {
        method: 'GET',
        headers: Config.defaultHeaders
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.data) {
            this.setOnboarding(responseJson.data.onboarding_complete)
            this.activeCompany = new Company(responseJson.data)
            return this.activeCompany
          } else {
            localStorage.removeItem('user_key')
            return responseJson
          }


          // return this.getStatus().then((res) => {

          //   return responseJson
          // })
          // .catch(() => {
          //   return responseJson
          // })
        })
    }
  }

  @action.bound
  getStatus = async () => {
    let url = '/notifications/status'
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders,
      })
      let responseJson = await repsonse.json()
      if (responseJson.data) {
        this.unreadNotifications = responseJson.data.notifications
        this.unreadMessages = responseJson.data.messages
      }
      return 200
    } catch (error) {
      return error
    }
  }

  setUserKey = async key => {
    localStorage.setItem('user_key', key);
    Config.userKey = key
  }

  setActiveCompany = company => {
    this.activeCompany = new Company(company)
  }

  @action
  resetErrors = () => {
    this.errors = {}
  }

  @action
  login = async company => {
    this.state = "pending"
    this.errors = {}
    return fetch(Config.api + '/auth/login', {
      method: 'POST',
      headers: Config.defaultHeaders,
      body: JSON.stringify(company)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.data) {
          if (responseJson.data.user_type) {
            if (responseJson.data.user_type == 'company') {
              return this.setUserKey(responseJson.data.user_key).then(() => {
                this.activeCompany = new Company(responseJson.data.company)
                this.state = "complete"
                return responseJson;
              })
            }
            return responseJson;
          }
        }
        else {
          this.state = "error"
          this.errors = responseJson.errors
          if (responseJson.message.message) {
            if (responseJson.message.message == 'Please verify your email before you use Pear Up') {
              return responseJson
            }
          } else {
            return responseJson
          }
          return responseJson
        }
      }, error => {
        this.state = "error"
        this.errors = error
        return Promise.reject(error)
      })
  }

  @action.bound
  forgotPassword = async email => {
    let url = "/auth/forgot-password"
    this.state = "pending"
    this.errors = {}

    try {
      let response = await fetch(Config.api + url, {
        method: 'POST',
        headers: Config.defaultHeaders,
        body: JSON.stringify({ email: email })
      })
      let responseJson = await response.json()
      if (responseJson.data) {
        this.state = "complete"
        return 200
      }
      else {
        this.state = "error"
        this.errors = responseJson.errors
        return responseJson.message
      }

    } catch (error) {
      this.state = "error"
      return error
    }
  }


  @action
  register = company => {
    this.state = "pending"
    this.errors = {}
    return fetch(Config.api + '/auth/company/register', {
      method: 'POST',
      headers: Config.defaultHeaders,
      body: JSON.stringify(company)
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.data) {
          this.state = "complete"
          return Promise.resolve(200)
        }
        else {
          this.state = "error"
          this.errors = responseJson.errors
          return toJS(this.errors)
        }
      }, error => {
        this.state = "error"
        // this.errors = responseJson.errors
        return toJS(this.errors)
      })
      .catch(error => {
        this.state = "error"
        this.errors = error
        return toJS(this.errors)
      })
  }

  @action
  resetPassword = async (password, password_confirmation, token) => {

    let url = "/auth/forgot-password"
    this.state = "pending"
    this.errors = {}
    console.log(password)
    console.log(password_confirmation)
    console.log(token)
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'PATCH',
        headers: Config.defaultHeaders,
        body: JSON.stringify({
          password: password,
          password_confirmation: password_confirmation,
          token: token
        })
      })
      let responseJson = await repsonse.json()
      if (responseJson) {
        console.log(responseJson)
        this.state = "complete"
        return responseJson
      }
      else {
        this.state = "error"
        this.errors = responseJson.errors
        return responseJson.message
      }
    } catch (error) {
      this.state = "error"
      return error
    }
  }

  @action.bound
  deleteAccount = (password) => {
    return fetch(Config.api + '/company/profile', {
      method: 'DELETE',
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        password: password,
      })
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          localStorage.removeItem('user_key')
          Config.userKey = ''
          return Promise.resolve(200)
        }
      }, error => {
        return error
      })
  }

  @action.bound
  deactivateAccount = () => {
    return fetch(Config.api + '/company/profile/deactivate', {
      method: 'PATCH',
      headers: Config.defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          localStorage.removeItem('user_key')
          Config.userKey = ''
          return Promise.resolve(200)
        }
      }, error => {
        return error
      })
  }

  @action
  getAllIndustries = () => {
    this.state = "pending"
    return fetch(Config.api + '/industries', {
      method: 'GET',
      headers: Config.defaultHeaders,
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          this.state = "complete"
          this.allIndustries = responseJson.data
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  @action
  editCompany = async (company) => {

    this.errors = {}

    let clone = cloneDeep(company)

    let parsedObj = {
      name: clone.name,
      street_1: clone.address.street_1,
      street_2: clone.address.street_2,
      city: clone.address.city,
      state: clone.address.state,
      country: clone.address.country,
      zip: clone.address.zip,
      about: clone.about,
      website: clone.website,
      logo: clone.base64 ? clone.base64 : false,
      videos: clone.videos,
      images: clone.images.filter(x => x.base64).map(l => l.base64)
    }

    // clone.street_2 ? parsedObj.street_2 = clone.street_2 : null

    return fetch(Config.api + '/company/profile', {
      method: 'PATCH',
      headers: Config.defaultHeaders,
      body: JSON.stringify(parsedObj)
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          this.setActiveCompany(responseJson.data)
          return Promise.resolve(200)
        }
        else {
          this.errors = responseJson.errors
          return Promise.reject(this.errors)
        }
      }, error => {
        return Promise.reject(error)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  @action.bound
  removeProfilePicture = () => {
    return fetch(Config.api + '/company/profile/logo', {
      method: 'DELETE',
      headers: Config.defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          this.activeCompany.logo = null
          return 200
        }
      }, error => {
        return error
      })
  }

  @action.bound
  removeSlide = id => {
    return fetch(Config.api + '/image/' + id, {
      method: 'DELETE',
      headers: Config.defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {

        if (responseJson.data) {
          // this.setActiveCompany(responseJson.data)
          return 200
        }
      }, error => {
        return error
      })
  }

  @action.bound
  getMessages = async () => {
    let url = '/company/messages';
    try {
      let response = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await response.json();
      return responseJson
    } catch (error) {
      return error
    }
  }

  @action.bound
  getThread = async (id, limit, page) => {
    let url = '/company/messages/' + id + '?limit=' + limit + '&page=' + page
    try {
      let response = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await response.json();

      return responseJson
    } catch (error) {
      return error
    }
  }

  @action.bound
  getUnseen = async (id, last_id) => {
    let url = '/company/messages/' + id + '?last_id=' + last_id
    try {
      let response = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await response.json();
      return responseJson
    } catch (error) {
      return error
    }
  }


  @action.bound
  getContacts = async id => {
    let url = '/company/messages/contacts';
    try {
      let response = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await response.json();
      return responseJson
    } catch (error) {
      return error
    }
  }

  @action.bound
  sendMessage = async (id, message) => {
    let url = '/company/messages/' + id;
    try {
      let response = await fetch(Config.api + url, {
        method: 'POST',
        headers: Config.defaultHeaders,
        body: JSON.stringify({ body: message })
      })
      let responseJson = await response.json()
      return responseJson
    } catch (error) {
      return error
    }
  }

}

const companyStore = new CompanyStore()
export default companyStore
