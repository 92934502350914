import React, { Component } from 'react';

export class TextArea extends Component {

  render() {

    const { label, errors, keyvalue } = this.props
    
    return (
      <div>
        <div className="input-container">
          <label>{label}</label>
          <textarea
            {...this.props}
            className="input"
          />
        </div>

        {
          errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
          <div className="errorsContainer">
            {
              Object.keys(errors).map((key) => (
                errors[key].map((l, index) => (
                  key == keyvalue ? <p className="error" key={index}>{l}</p> : null
                ))
              ))
            }
          </div>
        }
      </div>
    );

  }
}
