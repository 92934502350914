import React, { Component } from 'react';
export class PanelNav extends Component {

    render() {

        const { image, title, description, link } = this.props

        return (
            <a className="navPanel" href={link}>
                <div>
                    <img className="navImage" src={image} />
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </a>
        )
    }
}

