import { observable, action } from 'mobx'
import Config from '../config'

class Notification {
  @observable id
  @observable url
  @observable read
  @observable title
  @observable body
  @observable created_at

  constructor(value) {
    this.id = value.id
    this.url = value.page_url
    this.read = value.read
    this.title = value.title
    this.body = value.body
    this.created_at = value.created_at
  }
}

class NotificationStore {

  @observable notifications = []

  @action.bound
  getNotifications = async (limit, page) => {
    this.notifications = []
    let url = '/notifications?limit=' + limit + '&page=' + page;
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders,
      })
      let responseJson = await repsonse.json()
      responseJson.data.data.forEach((notification, index, array) => {
        this.notifications.push(new Notification(notification))
      })
      return this.notifications
    } catch (error) {
      return error
    }
  }

  @action.bound
  markAsRead = async id => {
    let url = '/notifications/' + id;
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'PATCH',
        headers: Config.defaultHeaders,
      })
      let responseJson = await repsonse.json()
      return responseJson
    } catch (error) {
      return error
    }
  }
}

const notificationStore = new NotificationStore()
export default notificationStore
