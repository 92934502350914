import React, { Component } from 'react';
import PlayStoreBtn from '../../assets/images/store/GooglePlayBtn.png';
import AndroidExample from '../../assets/images/homepage/AndroidExample.png';
import Dots from '../../assets/images/homepage/Dots.png';


const Android = () => {
    return (
        <div id="Android" className="section android" >
            <div className="android-left">
                <img src={Dots} />
            </div>
            <div className="android-middle">
                <img src={AndroidExample} />
            </div>
            <div className="android-right">
                <h2>Android</h2>
                <hr />
                <p>...Or if you’re an Android user, the Google Play Store is where you’ll find us</p>
                <a href="https://play.google.com/store/apps/details?id=com.pearupjobs" target="_blanks">
                    <img src={PlayStoreBtn} />
                </a>
            </div>
        </div>
    );
}

export default Android;