import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, EmptyState, Notification, Breadcrumbs } from '../../components';

import NotificationStore from '../../stores/NotificationStore'
import AuthStore from '../../stores/AuthStore'
import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'

import moment from "moment";


@observer
class Notifications extends Component {

    @observable active_sidebar_option = 'All'
    @observable sidebar_options = ['All', 'Unopened']

    @observable notifications = []
    @observable loaded = false
    @observable max_notifications = 20
    @observable page = 1
    @observable status = []

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    componentDidMount() {
        this.notifications = []
        AuthStore.getStatus()
        CompanyStore.checkAuth()

        const { unreadNotifications } = AuthStore

        if (unreadNotifications) {
            this.notifications = []
            this.status = unreadNotifications
        }

        this.loaded = false
        NotificationStore.getNotifications(this.max_notifications, this.page)
            .then(res => {
                if (res.length > 0) {
                    let notification_ids = this.notifications.map(x => x.id)
                    res.forEach(notification => {
                        if (!notification_ids.includes(notification.id)) {
                            this.notifications.push(notification)
                        }
                    })
                }
                this.loaded = true
            })
            .catch(err => {
                this.notifications = []
                this.loaded = true
            })

    }



    formatDuration = (date) => {
        let date_1 = date
        let date_2 = moment().format("YYYY-MM-DD H:mm:ss")
        return this.capitalise(moment(date_1).from(moment(date_2), true))
    }

    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ') + ' Ago';
    }

    truncate = (text) => {
        return text;
    }

    pressNotification = (id, url) => {
        NotificationStore.markAsRead(id)
            .then(res => {
                AuthStore.getStatus().then(res => {
                    if (url.includes('company')) {
                        if (url.includes('job')) {
                            var str = url.split("/");
                            this.props.history.push('/dashboard/jobs/job/' + str[4], {
                                id: str[4]
                            })
                        }
                        else if (url.includes('employee-requests')) {
                            var str = url.split("/");
                            JobStore.findPendingEmployeeById(str[4]).then((res) => {
                                if (res.message) {
                                    if (res.message === 'No results found.') {
                                        this.props.history.push('/dashboard/employees')
                                    }
                                } else {
                                    this.props.history.push('/dashboard/employees/employee/new/' + str[4], {
                                        fromScreen: "employees",
                                        showFooter: true,
                                        addFriend: false,
                                        user_id: str[4]
                                    })
                                }
                            })
                        }

                    }
                    if (url.includes('employee')) {
                        if (url.includes('jobs')) {
                            var str = url.split("/");
                            this.props.history.push('/dashboard/jobs/job/' + str[4], {
                                id: str[4]
                            })
                        }
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }



    render() {
        let { unreadNotifications } = AuthStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/notifications',
                title: 'Notifications'
            }
        ]
        return (

            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Notifications"
                />

                <div className="page-content">
                    <Sidebar
                        options={this.sidebar_options}
                        currentOption={this.active_sidebar_option}
                        onPress={this.setActiveSideBarOption.bind(this)}
                        buttonClick={() => {
                            this.props.history.push('/dashboard/job/create')
                        }}

                    />

                    <ContentContainer>
                        {this.active_sidebar_option && this.active_sidebar_option == 'All' &&
                            <div className="main-content">
                                <h1>Notifications</h1>
                                {
                                    this.notifications.map((item, index) => (
                                        <Notification
                                            key={item.id}
                                            message={this.truncate(item.body)}
                                            page_url={item.url}
                                            id={item.id}
                                            timestamp={this.formatDuration(item.created_at)}
                                            status={item.read === 0 ? 'unopened' : 'opened'}
                                            onClick={this.pressNotification.bind(this)}
                                        />
                                    ))
                                }

                                {this.notifications < 1 &&
                                    <EmptyState
                                        image={require('../../assets/images/icons/noData.png')}
                                        title="No Notifications"
                                    />
                                }

                            </div>
                        }

                        {this.active_sidebar_option && this.active_sidebar_option == 'Unopened' &&
                            <div className="main-content">
                                {/* <h1>{this.active_sidebar_option}</h1> */}
                                {
                                    this.notifications.map((item, index) => (
                                        item.read === 0 &&
                                        <Notification
                                            key={item.id}
                                            message={this.truncate(item.body)}
                                            page_url={item.url}
                                            id={item.id}
                                            timestamp={this.formatDuration(item.created_at)}
                                            status={item.read === 0 ? 'unopened' : 'opened'}
                                            onClick={this.pressNotification.bind(this)}
                                        />
                                    ))
                                }

                                {!unreadNotifications &&
                                    <EmptyState
                                        image={require('../../assets/images/icons/noData.png')}
                                        title="No Unopened Notifications"
                                    />
                                }


                            </div>
                        }

                    </ContentContainer>
                </div>

            </div >
        );
    }
}
export default Notifications;
