import React, { useState } from 'react';
import '../../../App.scss';
import JobStore from '../../../stores/JobStore'
import Config from '../../../config';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { Input } from '../../../components';
import { CountryDropdown } from 'react-country-region-selector';

const CheckoutForm = () => {

    const [name, setName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressCountry, setAddressCountry] = useState('');
    const [addressZip, setAddressZip] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        let billingAddress = {
            'name': name,
            'address_line1': addressLine1,
            'address_line2': addressLine2,
            'address_city': addressCity,
            'address_state': addressState,
            'address_country': addressCountry,
            'address_zip': addressZip,
        }
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const cardData = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (cardData) {
            if (cardData.paymentMethod) {
                JobStore.createCard({ token: cardData.paymentMethod.id }).then((res) => {
                    let link = window.location.href
                    link = link.replace('card', '')
                    window.location.href = link
                })
            }
        }
    };

    const handleBack = () => {
        let link = window.location.href
        link = link.replace('card', '')
        window.location.href = link
    }

    return (

        <form className="create-method-form">
            <CardElement
                options={
                    {
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }
                }
            />


            <br></br>
            <Input
                label="Name on Card"
                name="name"
                type="text"
                placeholder="Enter the name on your card"
                keyvalue={'name'}
                value={name || ''}
                onChange={text => setName(text.target.value)}
            />


            <div className="address_block">
                <div className="input-container">
                    <label>Billing Address</label>
                </div>
                <Input
                    name="street_1"
                    type="text"
                    placeholder="Street Line 1"
                    keyvalue={'street_1'}
                    value={addressLine1 || ''}
                    onChange={text => setAddressLine1(text.target.value)}
                />

                <Input
                    name="street_2"
                    type="text"
                    placeholder="Street Line 2"
                    keyvalue={'street_2'}
                    value={addressLine2 || ''}
                    onChange={text => setAddressLine2(text.target.value)}
                />

                <Input
                    name="city"
                    type="text"
                    placeholder="Town/City"
                    keyvalue={'city'}
                    value={addressCity || ''}
                    onChange={text => setAddressCity(text.target.value)}
                />

                <Input
                    name="zip"
                    type="text"
                    placeholder="Postcode"
                    keyvalue={'zip'}
                    value={addressZip || ''}
                    onChange={text => setAddressZip(text.target.value)}
                />

                <Input
                    name="state"
                    type="text"
                    placeholder="County"
                    keyvalue={'state'}
                    value={addressState || ''}
                    onChange={text => setAddressState(text.target.value)}
                />

                <div>
                    <div className="input-container">
                        <CountryDropdown
                            value={addressCountry || ''}
                            onChange={val => setAddressCountry(val)} />
                    </div>
                </div>


            </div>


            <div className="proceedForm">
                <a className="button btn-cancel" onClick={handleBack}>
                    <span>Cancel</span>
                </a>

                <button className="button btn-next btn-green btn-save" onClick={handleSubmit} disabled={!stripe} >
                    <span>Save</span>
                </button>

            </div>

        </form>
    );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.stripeKey);

const Method = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default Method;