import React from 'react'

export default class Slide extends React.Component {

  constructor(props) {
    super(props);
    this.state = { dimensions: {} };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  onImgLoad({ target: img }) {
    this.setState({
      dimensions: {
        height: img.offsetHeight,
        width: img.offsetWidth
      }
    });
  }


  render() {

    const { height } = this.state.dimensions;

    return (
      <div style={{ marginRight: 10 }}>
        <img onLoad={this.onImgLoad} style={{ maxHeight: 125, height: height, width: Math.round(125 * (125 / 125)) }} src={this.props.filepath} alt={this.props.filepath} />
      </div>
    )
  }
}

