import React, { Component } from "react";
import "../../App.scss";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import {
  Header,
  Sidebar,
  ContentContainer,
  EmptyState,
  ApplicantPanel,
  Breadcrumbs,
  Input,
  InputRemovable,
  Button,
  ButtonFooter,
} from "../../components";

import AuthStore from "../../stores/AuthStore";
import JobStore from "../../stores/JobStore";
import companyStore from "../../stores/CompanyStore";
import { OnboardingModal } from "../../components/_onboardingModal";

import moment from "moment";
import { isEmpty } from "lodash";

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import jobStore from "../../stores/JobStore";

@observer
class Employees extends Component {
  @observable active_sidebar_option = "Employees";
  @observable sidebar_options = ["Employees", "Employee Requests", "Invites"];

  @observable searchQuery = "";
  @observable text = "";
  @observable requests = [];
  @observable employeeRequests = [];
  @observable employees = [];
  @observable newEmployees = [];
  @observable emailAddresses = [];
  @observable validEmail = true;
  @observable copyClicked = false;
  @observable sharableUrl = "https://pearup.com";
  @observable showModalEmployees = false;
  @observable modalClosed = false;

  constructor(props) {
    super(props);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);

    this.clear = this.clear.bind(this);
  }

  setActiveSideBarOption = (option) => {
    AuthStore.setActiveSideBarOption(option);
    this.active_sidebar_option = option;
  };

  componentDidMount() {
    //Put these in order, woud like more time to make this better.
    //I'll leave a note here so I can improve it I get more time

    JobStore.getAllEmployees().then((res) => {
      this.employees = [];
      this.employees = res;

      this.loaded = true;

      console.log("Employees no. = " + this.employees.length);
      if (this.employees.length == 0) {
        this.showModalEmployees = true;
      }

      JobStore.getEmployeeRequests().then((res) => {
        this.employeeRequests = toJS(res);
        this.loaded = true;

        JobStore.getAllApplicants().then((res) => {
          if (res.data) {
            this.requests = res.data;
          } else {
            this.requests = [];
            this.showModalEmployees = true;
          }
          this.loaded = true;
        });
      });
    });

    JobStore.getSharableUrl().then((res) => {
      if (res) {
        if (res.data) {
          if (res.data.link) {
            this.sharableUrl = res.data.link;
          }
        }
      }
    });
  }

  handleChange = (term) => {
    clearTimeout(this.timeout);
    this.searchQuery = term.target.value;
    this.timeout = setTimeout(() => {
      if (this.searchEmployees(this.employees)) {
        JobStore.findEmployees(this.searchQuery).then((res) => {
          this.newEmployees = toJS(res);
        });
      }
    }, 1000);
  };

  searchEmployees = (employees) => {
    return employees.filter((el) =>
      `${el.full_name}`.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  };

  searchNewEmployees = (newEmployees) => {
    return newEmployees.filter((el) =>
      `${el.full_name}`.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  };

  processRequests = (data) => {
    let arr = [];
    data.forEach((item) => {
      let obj = {};
      obj.title = item.name;
      obj.job_id = item.id;
      obj.data = item.applicants ? toJS(item.applicants) : [];
      arr.push(obj);
    });
    return arr;
  };

  formatDuration = (start, end) => {
    let startDate = start.split("-").map((l) => {
      return parseInt(l);
    });
    let endDate = end.split("-").map((l) => {
      return parseInt(l);
    });
    return this.capitalise(moment(endDate).from(moment(startDate), true));
  };

  filterJobs = (jobs) => {
    if (this.active_sidebar_option == "Closed") {
      return jobs.filter((el) => el.active == 0 && el.paid != null);
    } else if (this.active_sidebar_option == "Pending") {
      return jobs.filter((el) => el.active == 0 && el.paid == null);
    } else if (this.active_sidebar_option === "Applicants") {
    } else {
      return jobs.filter((el) => el.active == 1);
    }
  };

  goToEmProfile = (job_id, user_id) => {
    if (job_id == "null") {
      JobStore.setCurrentEmployee(user_id).then((res) => {
        if (!isEmpty(res)) {
          this.props.history.push("/dashboard/employees/employee/" + user_id, {
            fromScreen: "employees",
            hideFooter: true,
            addFriend: false,
            removeFriendOption: true,
            user_id: user_id,
          });
        }
      });
    }
  };

  acceptApplicant = (job_id, id) => {
    // JobStore.acceptRequest(id).then(res => {
    //   ToastsStore.success("Applicant has been accepted")
    // })

    let obj = {
      status: "accepted",
    };

    JobStore.setStatus(job_id, id, obj).then((res) => {
      ToastsStore.success("Applicant has been accepted");
      JobStore.getAllApplicants().then((res) => {
        if (res.data) {
          this.requests = res.data;
          JobStore.getAllEmployees().then((res) => {
            this.employees = toJS(res);
            this.loaded = true;
          });
        } else {
          this.requests = [];
        }
        this.loaded = true;
      });
    });
  };

  goToProfile = (job_id, user_id) => {
    if (job_id == "null") {
      JobStore.setApplicant(user_id).then((res) => {
        if (!isEmpty(res)) {
          this.props.history.push("/dashboard/employees/employee/" + user_id, {
            fromScreen: "employees",
            hideFooter: true,
            removeFriendOption: true,
            addFriend: true,
            user_id: user_id,
          });
        }
      });
    } else {
      JobStore.getJob(job_id).then((res) => {
        if (res == 200) {
          JobStore.setApplicant(user_id).then((res) => {
            if (!isEmpty(res)) {
              this.props.history.push(
                "/dashboard/employees/applicant/" + user_id + "/job/" + job_id,
                {
                  fromScreen: "employees",
                  hideFooter: true,
                  addFriend: true,
                  removeFriendOption: true,
                  user_id: user_id,
                }
              );
            }
          });
        }
      });
    }
  };

  goToEmployeeProfile = (job_id, user_id) => {
    if (job_id == "null") {
      JobStore.setEmployee(user_id).then((res) => {
        if (!isEmpty(res)) {
          //this just needs new page for getting setEmployee Response

          this.props.history.push(
            "/dashboard/employees/employee/new/" + user_id,
            {
              fromScreen: "employees",
              hideFooter: true,
              addFriend: true,
              removeFriendOption: true,
              user_id: user_id,
            }
          );
        }
      });
    }
  };

  acceptEmployeeRequest = (user_id) => {
    JobStore.acceptRequest(user_id).then((res) => {
      JobStore.getEmployeeRequests().then((res) => {
        this.employeeRequests = toJS(res);
        JobStore.getAllEmployees().then((res) => {
          this.employees = toJS(res);
          ToastsStore.success("Applicant has been accepted");
        });
      });
    });
  };

  goToEmployeeRequest = (job_id, user_id) => {
    JobStore.setViewProfile(user_id).then((res) => {
      if (!isEmpty(res)) {
        this.props.history.push(
          "/dashboard/employees/employee/new/" + user_id,
          {
            fromScreen: "employees",
            showFooter: true,
            addFriend: false,
            removeFriendOption: false,
            user_id: user_id,
          }
        );
      }
    });
  };

  capitalise = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  handleEmailChange(event) {
    this.text = event.target.value;
  }
  handleEmailUpdate(event) {
    this.emailAddresses[event.target.id - 1]["name"] = event.target.value;
  }

  clear = () => {
    this.emailAddresses = [];
  };

  isValidEmailAddress = (address) => {
    return !!address.match(/.+@.+/);
  };

  render() {
    let breadcrumbs = [
      {
        link: "/dashboard",
        title: "Dashboard",
      },
      {
        link: "/dashboard/employees",
        title: "Employees",
      },
    ];

    return (
      <div className="page jobs">
        <Breadcrumbs data={breadcrumbs} />

        <Header title="Employees" />

        <div className="page-content">
          <Sidebar
            options={this.sidebar_options}
            currentOption={this.active_sidebar_option}
            onPress={this.setActiveSideBarOption.bind(this)}
            buttonClick={() => {
              this.props.history.push("/dashboard/job/create");
            }}
          />

          <ContentContainer>
            {this.active_sidebar_option &&
              this.active_sidebar_option === "Employees" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option}</h1>

                  <Input
                    name="searchQuery"
                    type="text"
                    placeholder="Search for an employee"
                    value={this.searchQuery}
                    onChange={this.handleChange.bind(this)}
                  />

                  {this.searchEmployees(this.employees).length > 0 &&
                    this.searchEmployees(this.employees).map(
                      (subItem, subindex) => (
                        <ApplicantPanel
                          key={subItem.id}
                          id={subItem.id}
                          image={subItem.logo}
                          name={subItem.full_name}
                          job_id={"null"}
                          onPress={this.goToEmProfile.bind(this)}
                        />
                      )
                    )}

                  {this.searchNewEmployees(this.newEmployees).length > 0 &&
                    this.searchQuery.length > 3 &&
                    this.searchNewEmployees(this.newEmployees).map(
                      (subItem, subindex) => (
                        <ApplicantPanel
                          key={subItem.id}
                          id={subItem.id}
                          image={subItem.logo}
                          name={subItem.full_name}
                          job_id={"null"}
                          onPress={this.goToEmployeeProfile.bind(this)}
                        />
                      )
                    )}

                  {this.searchEmployees(this.employees).length < 1 &&
                    this.searchNewEmployees(this.newEmployees).length < 1 && (
                      <EmptyState
                        image={require("../../assets/images/icons/noData.png")}
                        title="No Employees Found"
                      />
                    )}
                </div>
              )}

            {this.active_sidebar_option &&
              this.active_sidebar_option === "Application Requests" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option}</h1>

                  {this.requests.length > 0 &&
                    this.requests.map((item, index) => (
                      <div key={item.id}>
                        <h3>
                          {item.name}{" "}
                          <span className="count">
                            ({item.applicants.length})
                          </span>
                        </h3>

                        {item.applicants.map((subItem, subindex) => (
                          <ApplicantPanel
                            key={subItem.id}
                            id={subItem.id}
                            image={subItem.logo}
                            name={subItem.full_name}
                            job_id={item.id}
                            accept={() =>
                              this.acceptApplicant(item.id, subItem.id)
                            }
                            onPress={this.goToProfile.bind(this)}
                          />
                        ))}
                      </div>
                    ))}

                  {this.requests.length < 1 && (
                    <EmptyState
                      image={require("../../assets/images/icons/noData.png")}
                      title="You currently have no requests on Pear Up"
                    />
                  )}
                </div>
              )}

            {this.showModalEmployees && !this.modalClosed ? (
              <OnboardingModal
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 405,
                }}
                visible={this.showModalEmployees}
                image={require("../../assets/images/contact.png")}
                title="Connect your employees"
                subHeading={
                  "Connect your employee through an 'Invite' link, enabling them to share your job on social media and earn rewards."
                }
                text="Connectivity That Drives Results"
                onClose={() => {
                  this.modalClosed = true;
                  this.showModalEmployees = false;
                }}
                buttonText="Invite employee"
                onPress={() => {
                  this.showModalEmployees = false;
                  this.modalClosed = true;
                  this.setActiveSideBarOption("Invites");
                }}
              />
            ) : null}

            {this.active_sidebar_option &&
              this.active_sidebar_option === "Employee Requests" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option}</h1>

                  {this.employeeRequests.length > 0 &&
                    this.employeeRequests.map((subItem, subindex) => (
                      <ApplicantPanel
                        key={subItem.id}
                        id={subItem.id}
                        image={subItem.logo}
                        name={subItem.full_name}
                        job_id={"null"}
                        accept={() => this.acceptEmployeeRequest(subItem.id)}
                        onPress={this.goToEmployeeRequest.bind(this)}
                      />
                    ))}

                  {this.employeeRequests.length < 1 && (
                    <EmptyState
                      image={require("../../assets/images/icons/noData.png")}
                      title="You currently have no requests on Pear Up"
                    />
                  )}
                </div>
              )}

            {this.active_sidebar_option &&
              this.active_sidebar_option === "Invites" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option}</h1>

                  <div className="shareableLink">
                    <h4>Your Invite Link</h4>
                    <p className="linkIntro">
                      Share this with users to link them to your profile
                    </p>
                    <p>
                      {this.sharableUrl}
                      <span
                        className={
                          this.copyClicked
                            ? "copyToClipboardActive"
                            : "copyToClipboard"
                        }
                        onClick={() => {
                          this.copyClicked = true;
                          var URLInput = document.createElement("input"),
                            text = this.sharableUrl;
                          document.body.appendChild(URLInput);
                          URLInput.value = text;
                          URLInput.select();
                          document.execCommand("copy");
                          document.body.removeChild(URLInput);
                          setTimeout(
                            function () {
                              this.copyClicked = false;
                            }.bind(this),
                            3400
                          );
                        }}
                      >
                        {!this.copyClicked && "Copy Link"}
                        {this.copyClicked && "Link Copied"}
                      </span>
                    </p>
                  </div>

                  <div className="emailInviteCont">
                    <h4>Invite users via email</h4>
                    <p>
                      Enter the users email address, then click the "+" button.
                    </p>

                    <InputRemovable
                      name="new_class"
                      type="email"
                      placeholder="Enter Email Address"
                      value={this.text}
                      onChange={this.handleEmailChange}
                      add={(n) => {
                        if (this.isValidEmailAddress(this.text)) {
                          this.emailAddresses.push({
                            id: this.emailAddresses.length + 1,
                            name: n,
                          });
                          this.text = "";
                          this.validEmail = true;
                        } else {
                          this.validEmail = false;
                        }
                      }}
                      delete={(n) => {
                        var index = this.emailAddresses
                          .map(function (item) {
                            return item.id;
                          })
                          .indexOf(n);
                        this.emailAddresses.splice(index, 1);
                      }}
                    />

                    {this.emailAddresses.map((l, index) => (
                      <InputRemovable
                        key={index}
                        name="class"
                        type="text"
                        placeholder="Enter New Class"
                        value={l.name}
                        id={l.id}
                        onChange={this.handleEmailUpdate}
                        add={(n) => {
                          this.emailAddresses.push({
                            id: this.emailAddresses.length + 1,
                            name: n,
                          });
                          this.text = "";
                        }}
                        delete={(n) => {
                          var index = this.emailAddresses
                            .map(function (item) {
                              return item.id;
                            })
                            .indexOf(n);
                          this.emailAddresses.splice(index, 1);
                        }}
                      />
                    ))}

                    {!this.validEmail && (
                      <div className="errorsContainer emailerror">
                        <p className="error">
                          Please enter a valid email address
                        </p>
                      </div>
                    )}

                    <ButtonFooter>
                      <Button
                        text="Send Invites"
                        customClass="button"
                        style={{ width: "200px", float: "right" }}
                        onClick={() => {
                          if (this.emailAddresses.length > 0) {
                            JobStore.inviteByEmailAddress(
                              this.emailAddresses
                            ).then((res) => {
                              if (res) {
                                setTimeout(
                                  function () {
                                    this.emailAddresses = [];
                                  }.bind(this),
                                  1500
                                );
                                ToastsStore.success("Invites have been sent");
                              }
                            });
                          } else {
                            ToastsStore.error(
                              "please press the + icon or check your email address"
                            );
                          }
                        }}
                      />

                      <Button
                        text="Clear"
                        customClass="button btn-black"
                        style={{
                          width: "200px",
                          marginRight: "20px",
                          float: "right",
                        }}
                        onClick={() => {
                          this.emailAddresses = [];
                        }}
                      />
                    </ButtonFooter>
                  </div>
                </div>
              )}
          </ContentContainer>
        </div>

        <ToastsContainer
          className="toast"
          position={ToastsContainerPosition.BOTTOM_CENTER}
          store={ToastsStore}
        />
      </div>
    );
  }
}
export default Employees;
