import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

export class StepperNav extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(currentStep) {
    this.props.onUpdate(currentStep)
  }

  render() {
    const { currentStep } = this.props
    const steps = [{
      key: 1,
    },
    {
      key: 2,
    },
    ]

    return (
      <div className="circleCont">
        {steps.map((label, index) => (
          <div key={index}>
            <Button className={index === currentStep ? 'dot-active ' : ''} onClick={() => this.props.onUpdate(index)}> </Button>
          </div>
        ))}
      </div>
    );
  }
}