import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import CompanyStore from '../../stores/CompanyStore'
import { Input, Button, StepperNav } from '../../components';
import { Helmet } from "react-helmet";
import { CountryDropdown } from 'react-country-region-selector';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
@observer

class Register extends Component {


  @observable email = '';
  @observable password = '';
  @observable loading = false;

  @observable currentStep = 0;
  @observable totalSteps = 3;

  @observable searchQuery = ''
  @observable disabled = false

  @observable company = {
    email: '',
    password: '',
    password_confirmation: '',
    name: '',
    street_1: '',
    street_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    address: '',
    about: '',
  }

  constructor(props) {
    super(props);

    this.updateProperty = this.updateProperty.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  updateProperty(key, value) {
    this.company[key] = value
  }

  handleChange(event) {
    this.updateProperty(event.target.name, event.target.value)
  }


  submitForm = () => {
    this.disabled = true

    CompanyStore.register(this.company).then(res => {
      if (res == 200) {
        ToastsStore.success('Please verify your email before you use Pear Up')
        setTimeout(
          function () {
            this.props.history.push('/login')
          }.bind(this),
          2500
        );

      } else {
        if (res.email) {
          this.currentStep = 0
        }
        else if (res.password) {
          this.currentStep = 0
        }
        else if (res.name) {
          this.currentStep = 1
        }
        else if (res.street_1) {
          this.currentStep = 1
        }
        else if (res.city) {
          this.currentStep = 1
        }
        else if (res.state) {
          this.currentStep = 1
        }
        else if (res.zip) {
          this.currentStep = 1
        }
        else if (res.country) {
          this.currentStep = 1
        } else if (res.address) {
          this.currentStep = 1
        }

        setTimeout(
          function () {
            this.disabled = false
          }
            .bind(this),
          2500
        );


      }
    });

  }

  nextStep = () => {
    if (this.currentStep < 2) {
      this.currentStep++;
    }
  }

  render() {
    let { errors } = CompanyStore
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Pear Up | Register</title>
          <meta name="description" content="Find your next job or employees straight from your phone…" />
          <meta name="keywords" content="Find your next job or employees straight from your phone" />
          <meta name="author" content="Pear Up" />

          <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta property="og:description" content="Find your next job or employees straight from your phone…" />
          <meta property="og:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
          <meta property="og:url" content="https://pearup-jobs.com/" />

          <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
          <meta name="twitter:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
          <meta name="twitter:card" content="Summary" />

        </Helmet>

        <div className="main">
          <div className="content">
            <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
            <div className="login">
              <a href="/login" className="link register-link">Or <span style={{ color: '#59AE46' }}>Login</span></a>
              <a href="/">
                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
              </a>
              <form className="form login-form">

                {this.currentStep === 0 &&
                  <div>
                    <h1>Register with Pear Up</h1>
                    <p>Fill out your company details to register with Pear Up…</p>
                    <Input
                      label="Email address"
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      value={this.company.email}
                      onChange={this.handleChange}
                      keyvalue={'email'}
                      errors={errors}
                    />
                    <Input
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={this.company.password}
                      onChange={this.handleChange}
                      keyvalue={'password'}
                      errors={errors}
                    />
                    <Input
                      label="Confirm Password"
                      name="password_confirmation"
                      type="password"
                      placeholder="Confirm your password"
                      value={this.company.password_confirmation}
                      onChange={this.handleChange}
                      keyvalue={'password'}
                      errors={errors}
                    />
                  </div>
                }


                {this.currentStep === 1 &&
                  <div>
                    <h1>About the company</h1>
                    <p>Fill out your company details to register with Pear Up…</p>
                    <Input
                      label="Company Name*"
                      name="name"
                      type="text"
                      placeholder="Enter your company name"
                      value={this.company.name}
                      onChange={this.handleChange}
                      keyvalue={'name'}
                      errors={errors}
                    />


                    <div className="group">
                      <h3>Company Address</h3>
                      <Input
                        name="street_1"
                        type="street_1"
                        placeholder="Line 1*"
                        value={this.company.street_1}
                        onChange={this.handleChange}
                        keyvalue={'street_1'}
                        errors={errors}
                      />

                      <Input
                        name="street_2"
                        type="street_2"
                        placeholder="Line 2"
                        value={this.company.street_2}
                        onChange={this.handleChange}
                        keyvalue={'street_2'}
                        errors={errors}
                      />

                      <Input
                        name="city"
                        type="text"
                        placeholder="Town / City*"
                        value={this.company.city}
                        onChange={this.handleChange}
                        keyvalue={'city'}
                        errors={errors}
                      />

                      <Input
                        name="state"
                        type="text"
                        placeholder="State / County*"
                        value={this.company.state}
                        onChange={this.handleChange}
                        keyvalue={'state'}
                        errors={errors}
                      />


                      <div>
                        <div className="input-container">
                          <CountryDropdown
                            value={this.company.country || ''}
                            whitelist={["GB", "IE"]}
                            onChange={(val) => this.company.country = val} />

                          {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                            <div className="errorsContainer country-error">
                              {
                                Object.keys(errors).map((key) => (
                                  errors[key].map((l, index) => (
                                    key == 'country' ? <p className="error" key={index}>{l}</p> : null
                                  ))
                                ))
                              }
                            </div>
                          }
                        </div>


                      </div>


                      <Input
                        name="zip"
                        type="text"
                        placeholder="Postcode / Zip Code*"
                        value={this.company.zip}
                        onChange={this.handleChange}
                        keyvalue={'zip'}
                        errors={errors}
                      />
                    </div>

                    <div className="group">
                      <h3>About</h3>
                      <Input
                        label="About the Company"
                        name="about"
                        type="text"
                        placeholder="Tell us about your company"
                        value={this.company.about}
                        onChange={this.handleChange}
                        errors={errors}
                      />
                    </div>
                  </div>

                }


              </form>

              {this.currentStep < 1 &&
                <Button customClass="button"
                  text="Proceed" onClick={this.nextStep}>
                </Button>
              }

              {this.currentStep === 1 &&
                <Button customClass="button"
                  text="Complete" disabled={this.disabled} onClick={this.submitForm}>
                </Button>
              }

              <div className="stepperNav">
                <StepperNav
                  currentStep={this.currentStep}
                  onUpdate={(n) => { this.currentStep = n }}
                />
              </div>


              <a href="/forgot-password" className="link">Forgot your password?</a>
            </div>
            <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
          </div>
        </div>
        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
      </div>
    );

  }

}
export default Register;
