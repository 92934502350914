import Config from '../config'
import { observable, action, toJS } from 'mobx'

class Skill {
  @observable id
  @observable name
  @observable status

  constructor(value) {
    this.id = value.id
    this.name = value.name
    this.status = value.status
  }
}

class Industry {
  @observable id
  @observable name
  @observable status

  constructor(value) {
    this.id = value.id
    this.name = value.name
    this.status = value.status
  }
}

class Company {
  @observable id
  @observable name
  @observable about
  @observable email
  @observable logo
  @observable website
  @observable status
  @observable address
  @observable jobCount

  constructor(value) {
    this.id = value.id
    this.name = value.name
    this.about = value.about
    this.email = value.email
    this.logo = value.logo ? value.logo : null
    this.website = value.website
    this.status = value.status
    this.address = value.address ? new CompanyAddress(value.address) : null
    this.jobCount = value.jobCount ? value.jobCount : null
  }
}

class Address {
  @observable id
  @observable city
  @observable country
  @observable created_at
  @observable updated_at
  @observable state
  @observable longitude
  @observable latitude
  @observable latitude

  constructor(value) {
    this.id = value.id
    this.city = value.city
    this.country = value.country
    this.created_at = value.created_at
    this.updated_at = value.updated_at
    this.state = value.state
    this.longitude = value.longitude
    this.latitude = value.latitude
  }
}

class CompanyAddress {
  @observable id
  @observable street_1
  @observable street_2
  @observable city
  @observable country
  @observable zip
  @observable created_at
  @observable updated_at
  @observable state
  @observable longitude
  @observable latitude

  constructor(value) {
    this.id = value.id
    this.city = value.street_1
    this.country = value.street_2
    this.city = value.city
    this.country = value.country
    this.zip = value.zip
    this.created_at = value.created_at
    this.updated_at = value.updated_at
    this.state = value.state
    this.longitude = value.longitude
    this.latitude = value.latitude
  }
}

class User {
  @observable id
  @observable first_name
  @observable last_name
  @observable full_name
  @observable email
  @observable date_of_birth
  @observable radius
  @observable radius_measurement
  @observable registration_method
  @observable facebook_id
  @observable status
  @observable updated_at
  @observable created_at
  @observable skills
  @observable address
  @observable description
  @observable work_experience
  @observable logo

  constructor(value) {
    this.id = value.id
    this.first_name = value.first_name
    this.last_name = value.last_name
    this.full_name = value.full_name
    this.email = value.email
    this.date_of_birth = value.date_of_birth
    this.radius = value.radius
    this.radius_measurement = value.radius_measurement
    this.registration_method = value.registration_method
    this.facebook_id = value.facebook_id
    this.status = value.status
    this.updated_at = value.updated_at
    this.created_at = value.created_at
    this.skills = this.processSkills(value.skills)
    this.address = new Address(value.address)
    this.description = value.description
    this.work_experience = value.work_experience
    this.logo = value.logo
  }

  processSkills = (skills) => {
    let arr = []
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill))
      })
    }
    return arr
  }
}

class Job {
  @observable id
  @observable address
  @observable company
  @observable company_id
  @observable description
  @observable distance
  @observable duration
  @observable contract
  @observable skills
  @observable name
  @observable active
  @observable reward
  @observable rate
  @observable paid
  @observable payment_id
  @observable applied
  @observable count
  @observable industries

  constructor(value) {
    this.id = value.id
    this.address = value.address ? new Address(value.address) : null
    this.company = this.processCompany(value.company)
    this.company_id = value.company_id
    this.description = value.description
    this.distance = value.distance
    this.contract = value.contract
    this.duration = value.duration
    this.skills = this.processSkills(value.skills)
    this.name = value.name
    this.active = value.active
    this.reward = value.reward
    this.rate = value.rate
    this.paid = value.paid
    this.payment_id = value.payment_id
    this.applied = value.applied
    this.count = value.count ? value.count : null
    this.industries = this.processIndustries(value.industries)
  }

  processSkills = (skills) => {
    let arr = []
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill))
      })
    }
    return arr
  }

  processCompany = (company) => {
    if (company) {
      return new Company(company)
    }
    return {}
  }

  processIndustries = (industries) => {
    let arr = []
    if (industries) {
      industries.forEach((industry, index) => {
        arr.push(new Industry(industry))
      })
    }
    return arr
  }
}

class GuestStore {

  @observable state = ""
  @observable errors = {}
  @observable allSkills = []
  @observable allIndustries = []
  @observable activeUser = {}
  @observable jobs = []
  @observable job = {}
  @observable companies = []
  @observable company = {}

  @action.bound
  getEmployeeJobsForMaps = async data => {
    this.jobs = []
    let items = 0
    let url = '/jobs'
    this.state = "pending"
    if (data) {
      url = url + '?radius=' + data.radius

      data.industries.map(x => {
        url = url + '&industries[]=' + x
      })
    }
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await repsonse.json()
      responseJson.data.jobs.forEach((groupedJobs, index, array) => {
        groupedJobs[0].count = groupedJobs.length
        this.jobs.push(new Job(groupedJobs[0]))
      })
      this.state = "complete"
      return 200
    } catch (error) {
      this.state = "complete"
      return error
    }
  }

  @action.bound
  getEmployeeJobsByLngLatForMaps = async data => {
    this.jobs = []
    let items = 0

    let url = '/jobs?latitude=' + data.lat + '&longitude=' + data.lng

    if (data.radius) {
      url = url + '&radius=' + data.radius
    }

    if (data.industries) {
      data.industries.map(x => {
        url = url + '&industries[]=' + x.id
      })
    }

    this.state = "pending"

    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await repsonse.json()
      responseJson.data.jobs.forEach((groupedJobs, index, array) => {
        groupedJobs[0].count = groupedJobs.length
        this.jobs.push(new Job(groupedJobs[0]))
      })
      this.state = "complete"
      return 200
    } catch (error) {
      this.state = "complete"
      return error
    }
  }

  @action.bound
  getEmployeeAllJobsByLngLat = async data => {
    let url = '/jobs?latitude=' + data.lat + '&longitude=' + data.lng
    if (data.radius) {
      url = url + '&radius=' + data.radius
    }
    else {
      url = url + '&radius=0'
    }
    let allJobs = []
    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await repsonse.json()
      responseJson.data.jobs.forEach((groupedJobs, index, array) => {
        groupedJobs.forEach((job, index, array) => {
          allJobs.push(new Job(job))
        })
      })
      return allJobs
    } catch (error) {
      return error
    }
  }

  @action.bound
  getJob = async id => {
    this.state = 'pending'
    return fetch(Config.api + '/jobs/' + id, {
      method: 'GET',
      headers: Config.defaultHeaders
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        if (responseJson.data) {
          this.job = new Job(responseJson.data)
          this.state = 'complete'
          return responseJson.data
        }
      }, error => {
        this.state = 'complete'
        return Promise.resolve(error)
      })
  }




  @action.bound
  getCompaniesByLngLatForMaps = async data => {

    this.companies = []

    let items = 0
    let url = '/companies?latitude=' + data.lat + '&longitude=' + data.lng

    this.state = "pending"

    if (data.radius) {
      url = url + '&radius=' + data.radius
    }

    if (data.industries) {
      data.industries.map(x => {
        url = url + '&industries[]=' + x.id
      })
    }

    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await repsonse.json()
      responseJson.data.companies.forEach((groupedCompanies, index, array) => {
        groupedCompanies.forEach((company, index, array) => {
          this.companies.push(new Company(company))
        })
      })

      this.state = "complete"
      return 200
    } catch (error) {
      this.state = "complete"
      return error
    }
  }

  @action.bound
  getJobsByCompany = async id => {
    this.jobs = []
    this.company = {}
    let items = 0
    let url = '/companies/' + id
    this.state = "pending"

    try {
      let repsonse = await fetch(Config.api + url, {
        method: 'GET',
        headers: Config.defaultHeaders
      })
      let responseJson = await repsonse.json()
      if (responseJson.data) {
        responseJson.data.jobCount = responseJson.data.job_posts.length
        this.company = new Company(responseJson.data)
        if (responseJson.data.job_posts) {
          responseJson.data.job_posts.forEach((job, index, array) => {
            this.jobs.push(new Job(job))
          })
        }
      }
      this.state = "complete"
      return 200
    } catch (error) {
      this.state = "complete"
      return error
    }
  }

}

const guestStore = new GuestStore()
export default guestStore
