import React, { Component } from 'react';

export class ProfileImageSimple extends Component {

    render() {

        const { image } = this.props

        return (
            <div className="profileBgSimple">
                {image &&
                    <div className="profileImage" style={{ backgroundImage: `url(${image})` }} ></div>
                }
                {!image &&
                    <div className="profileImage" style={{ backgroundImage: `url(${require("../assets/images/profile/noProfileImage.png")})` }} ></div>
                }
            </div>
        );

    }
}
