import React, { Component } from "react";
import "../../App.scss";
import { observer } from "mobx-react";
import { Button } from "../../components";
import { Helmet } from "react-helmet";
import authStore from "../../stores/AuthStore";
import { OnboardingFooter } from "../../components/_onboardingFooter";

@observer
class ScreenFive extends Component {
  render() {
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Onboarding</title>
        </Helmet>

        <div className="main">
          <div className="onboardingNav">
            <div className="backButton">
              <a
                href="/onboarding/screenFour"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={require("../../assets/images/arrow-left.png")}
                  style={{ marginLeft: 40, marginRight: 10, marginTop: 5 }}
                />
                <span style={{ color: "black" }}>Back</span>
              </a>
            </div>
          </div>
          <div className="onboarding">
            <img src={require("../../assets/images/chat.png")} />
            <div className="onboarding-container">
              <h1>It's Secure</h1>
              <h5>
                All Payments are strictly between employer and employee. Your
                employee list is private and not visible to any third parties.
              </h5>
            </div>
            <blockquote />
            <blockquote />
            <blockquote />
            <img src={require("../../assets/images/progress5.png")} />
            <blockquote />
            <Button
              customClass="button"
              text="Let's get started!"
              onClick={() => {
                authStore.completeOnboarding();
                this.props.history.push("/dashboard/employees");
              }}
            ></Button>
          </div>
          <img
            src={require("../../assets/images/leaf-bg.svg")}
            className="leaf-bg"
            alt="Pear Up Logo"
          />
        </div>
        <OnboardingFooter />
      </div>
    );
  }
}
export default ScreenFive;
