import React, { Fragment, Component } from 'react';

import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, EmptyState, Input, Breadcrumbs, MessageRow } from '../../components';

import AuthStore from '../../stores/AuthStore'
import CompanyStore from '../../stores/CompanyStore'



@observer
class NewMessage extends Component {

    @observable active_sidebar_option = 'Contacts'
    @observable sidebar_options = ['Contacts']

    @observable contacts = []
    @observable searchQuery = ''


    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    componentDidMount() {
        CompanyStore.getContacts().then(res => {
            if (res.data) {
                if (res.data.length > 0) {
                    this.contacts = res.data
                }
            }
        }).catch(err => {
            this.contacts = []
        })
    }

    searchContacts = (contacts) => {
        return contacts.filter(el => `${el.first_name + ' ' + el.last_name}`.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }


    truncate = (text) => {
        if (text.length > 30)
            return text.substring(0, 30) + '...';
        else
            return text;
    }

    goToConversation = (user_id, company_id, name) => {
        this.props.history.push('/dashboard/messages/' + user_id + '/' + company_id)
    }

    handleChange = (term) => {
        this.searchQuery = term.target.value
    }


    render() {

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/messages',
                title: 'Messages'
            }
        ]

        let { activeCompany } = CompanyStore

        return (
            <Fragment>
                <div className="page jobs">
                    <Breadcrumbs
                        data={breadcrumbs}
                    />

                    <Header
                        title="Messages"
                        backButton={true}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    />

                    <div className="page-content">
                        <Sidebar
                            options={this.sidebar_options}
                            currentOption={this.active_sidebar_option}
                            onPress={this.setActiveSideBarOption.bind(this)}
                            buttonClick={() => {
                                this.props.history.push('/dashboard/job/create')
                            }}

                        />

                        <ContentContainer>
                            {this.active_sidebar_option && this.active_sidebar_option == 'Contacts' &&
                                <div className="main-content">
                                    <h1>New Message</h1>

                                    <Input
                                        name="searchQuery"
                                        type="text"
                                        placeholder="Search for a contact"
                                        value={this.searchQuery}
                                        onChange={this.handleChange.bind(this)}
                                    />

                                    {this.searchContacts(this.contacts).map(contact => (
                                        <MessageRow
                                            key={contact.id}
                                            name={contact.first_name + ' ' + contact.last_name}
                                            image={contact.logo}
                                            newContact={true}
                                            onClick={() => this.goToConversation(contact.id, activeCompany.id, contact.first_name + ' ' + contact.last_name)}
                                        />
                                    ))
                                    }


                                    {this.searchContacts(this.contacts).length < 1 &&
                                        <EmptyState
                                            image={require('../../assets/images/icons/noData.png')}
                                            title="No Contacts Found"
                                        />
                                    }

                                </div>
                            }


                        </ContentContainer>
                    </div>
                </div >
            </Fragment>
        );
    }
}
export default NewMessage;
