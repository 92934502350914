import React, { Component } from 'react';

export class ModalImage extends Component {

    render() {

        const { selectedImage, modalVisible, onClick } = this.props

        return (
            <div className={modalVisible === true ? 'modal modal-active' : 'modal'}>
                <div class="modal-img-cont">
                    <div class="image-container">
                        <div class="modal-close" onClick={() => onClick(false)}>
                            <img src={require('../assets/images/icons/close.svg')} alt="Close Modal" />
                        </div>
                        <img src={selectedImage} alt="Modal content" />
                    </div>
                </div>
            </div>
        );

    }
}
