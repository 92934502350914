import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, Breadcrumbs } from '../../../components';

import JobStore from '../../../stores/JobStore'

import Method from '../../../screens/jobs/payment/method'

@observer
class PaymentMethods extends Component {
    @observable confirmationModal = false;
    @observable activeCardId = null

    componentDidMount() {
        JobStore.getCards();

        if (JobStore.job.id) {

        } else {
            JobStore.getJob(this.props.match.params.id).then((res) => {

            })
        }
    }

    deleteCard = id => {
        JobStore.deleteCard({ card_id: id })
            .then(res => {
                if (res == 200) {
                    JobStore.getCards();
                    this.activeCardId = null
                }
            })
            .catch(error => {
                this.activeCardId = null
            })
    }

    setDefault = id => {
        if (id != JobStore.defaultPaymentMethod) {
            JobStore.setDefaultCard(id).then(res => {
            })
        }
    }

    cancelDelete = () => {
        this.confirmationModal = false
        this.activeCardId = null
    }

    toBeDeleted = id => {
        this.confirmationModal = true
        this.activeCardId = id
    }

    closeModal = () => {
        this.showModal = false
    }

    payNow = (duration) => {
        if (!duration) return;
        let cost = duration === "4_weeks" ? JobStore.four_week_pricing : JobStore.six_week_pricing
        JobStore.payNow(JobStore.job.id, cost)
            .then(res => {
                if (res == 200) {
                    this.props.navigation.navigate('ModalScreen', {
                        title: "Payment Accepted",
                        message: "Your job has been posted!",
                        headerColor: "green",
                        buttonText: "See your jobs",
                        buttonLink: "Jobs",
                        imageType: "accepted",
                    })
                }
            })
            .catch(error => {
                // Toast.show(error.message) 
            })
    }

    render() {

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/jobs',
                title: 'Jobs'
            },
            {
                link: '/dashboard/job/pay/' + this.props.match.params.id,
                title: 'Payment Methods'
            },
            {
                link: this.props.match.url,
                title: 'Add Payment Method'
            }
        ]

        return (
            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Jobs"
                />

                <div className="page-content">
                    <ContentContainer>
                        <div>
                            <h2>Add Payment Method</h2>
                            <p>Please enter your card details exactly as they are printed…</p>

                            <Method />
                        </div>

                    </ContentContainer>

                </div>



            </div >

        );
    }
}
export default PaymentMethods;
