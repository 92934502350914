import React, { Component } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import { Helmet } from "react-helmet";

import timezone from 'moment-timezone';

import Config from './config'
import HomeScreen from './'

import CompanyStore from './stores/CompanyStore'
import AuthStore from './stores/AuthStore'

import Home from './screens/home/Home.js';
import MobileJob from './screens/home/mobileJob.js';

import Refer from './screens/referal/index.js';
import Login from './screens/authentication/login.js'
import Register from './screens/authentication/register.js'
import ForgotPassword from './screens/authentication/forgotPassword.js'
import ResetPassword from './screens/authentication/resetPassword.js'
import Verify from './screens/email/verify.js'
import VerifySuccess from './screens/email/verifySuccess.js'

import EmployeeInvite from './screens/referal/employeeInvite.js'


import LoginVerify from './screens/authentication/verify.js'

import Dashboard from './screens/dashboard/home.js'

import Jobs from './screens/jobs/jobs.js'
import ViewJob from './screens/jobs/viewJob.js'
import CreateJob from './screens/jobs/create.js'
import ConfirmJob from './screens/jobs/confirmJob.js'
import PaymentMethods from './screens/jobs/payment/paymentMethods.js'
import PaymentProcessing from './screens/jobs/payment/paymentProcessing.js'
import AddMethod from './screens/jobs/payment/addMethod.js'
import DurationJob from './screens/jobs/duration.js'
import ApplicantProfile from './screens/user/applicantProfile.js'


import Employees from './screens/employees/employees.js'
import EmployeeProfile from './screens/employees/employeeProfile.js'
import NewEmployeeProfile from './screens/employees/newEmployeeProfile.js'
import FoF from './screens/dashboard/fof.js';

import Privacy from './screens/home/privacy.js'
import Terms from './screens/home/terms-and-conditions.js'
import CookiePolicy from './screens/home/cookie-policy.js'

import Profile from './screens/profile/profile.js'
import EditProfile from './screens/profile/editProfile.js'

import Notifications from './screens/notifications/notifications.js'


import DeleteAccount from './screens/profile/settings/deleteAccount.js'
import ChangeEmail from './screens/profile/settings/changeEmail.js'
import ChangePassword from './screens/profile/settings/changePassword'

import MessageHome from './screens/messages/messageHome.js'
import Message from './screens/messages/message.js'
import NewMessage from './screens/messages/newMessage.js'
import ScreenOne from './screens/onboarding/screenOne';
import ScreenTwo from './screens/onboarding/screenTwo';
import ScreenThree from './screens/onboarding/screenThree';
import ScreenFour from './screens/onboarding/screenFour';
import ScreenFive from './screens/onboarding/screenFive';

const history = createBrowserHistory();

class App extends Component {


  UNSAFE_componentWillMount() {
    CompanyStore.checkAuth().then(res => {
      if (res === "User Api Key is invalid") {
        localStorage.clear();
        window.location.href = '/login';
      }
    })

    var tz = timezone.tz.guess();
    Config.tz = tz;
    console.log("tz " + tz);
  }


  logout() {
    AuthStore.logout().then((res) => {
      if (res === 200) {
        localStorage.clear();
        window.location.href = '/login';
      }
    })

  }

  render = () => {


    return (
      <Router history={history}>
        <Switch>
          <Route path="/dashboard(.*)" render={(() =>
            <nav className="nav">
              <ul>
                <li>
                  <Link to="/dashboard">
                    <img src={require('./assets/logo/pear-up-brand@3x.png')} className="brand" alt="Pear Up logo" />
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/jobs"> <img src={require('./assets/images/icons/jobs.png')} alt="Jobs Icon" />Jobs</Link>
                </li>
                <li>
                  <Link to="/dashboard/employees"><img src={require('./assets/images/icons/employees.png')} alt="Employees Icon" />Employees</Link>
                </li>
                <li>
                  <Link to="/dashboard/messages"><img src={require('./assets/images/icons/messages.png')} alt="Messages Icon" />Messages</Link>
                </li>
                <li>
                  <Link to="/dashboard/notifications"><img src={require('./assets/images/icons/notifications.png')} alt="Notifications Icon" />Notifications</Link>
                </li>
                <li>
                  <Link to="/dashboard/profile"><img src={require('./assets/images/icons/profile.png')} alt="Profile Icon" />Profile</Link>
                </li>
                <li>
                  <Link to="/login" onClick={this.logout}>Sign Out</Link>
                </li>
              </ul>
            </nav>
          )} />
          {/* <Route path="*">
            <div className="wrapper">
              <div className="main">
                <FoF />
              </div>
            </div>
          </Route> */}
        </Switch>


        <Switch>
          <Route path="/dashboard(.*)" >
            <div className="wrapper">
              <div className="main">
                <div className="content">
                  <PrivateRoute path={'/dashboard'} exact component={Dashboard} />

                  <PrivateRoute path={'/dashboard/jobs'} exact component={Jobs} />


                  <PrivateRoute path={'/dashboard/jobs/job/:id'} exact component={ViewJob} />
                  <PrivateRoute path={'/dashboard/jobs/job/:jobId/applicant/:id'} exact component={ApplicantProfile} />
                  <PrivateRoute path={'/dashboard/job/create'} exact component={CreateJob} />
                  <PrivateRoute path={'/dashboard/job/confirm/:id'} exact component={ConfirmJob} />
                  <PrivateRoute path={'/dashboard/job/pay/:id'} exact component={PaymentMethods} />
                  <PrivateRoute path={'/dashboard/job/pay/:id/processing'} exact component={PaymentProcessing} />
                  <PrivateRoute path={'/dashboard/job/pay/:id/card'} exact stripe={Config.stripeKey} component={AddMethod} />
                  <PrivateRoute path={'/dashboard/job/duration'} exact component={DurationJob} />

                  <PrivateRoute path={'/dashboard/employees'} exact component={Employees} />

                  <PrivateRoute path={'/dashboard/employees/employee/:id'} exact component={EmployeeProfile} />
                  <PrivateRoute path={'/dashboard/employees/applicant/:id/job/:jobId'} exact component={ApplicantProfile} />
                  <PrivateRoute path={'/dashboard/employees/employee/new/:id'} exact component={EmployeeProfile} />


                  <PrivateRoute path={'/dashboard/profile'} exact component={Profile} />
                  <PrivateRoute path={'/dashboard/profile/edit'} exact component={EditProfile} />
                  <PrivateRoute path={'/dashboard/profile/delete'} exact component={DeleteAccount} />
                  <PrivateRoute path={'/dashboard/profile/email'} exact component={ChangeEmail} />
                  <PrivateRoute path={'/dashboard/profile/password'} exact component={ChangePassword} />

                  <PrivateRoute path={'/dashboard/notifications'} exact component={Notifications} />

                  <PrivateRoute path={'/dashboard/messages'} exact component={MessageHome}  />
                  <PrivateRoute path={'/dashboard/messages/:user_id/:company_id'} exact component={Message} />
                  <PrivateRoute path={'/dashboard/messages/new'} exact component={NewMessage} />
                </div>
              </div>
            </div>

            <div className="dashboard-footer">
              <p>© 2023, Pear Up</p>
            </div>

          </Route>
        </Switch>


        <Switch>
          <RedirectRouteLogin path={'/'} exact component={Home} />



          <RedirectRouteLogin path={'/employee-invite'} exact component={EmployeeInvite} />
          <RedirectRouteLogin path={'/job/:id'} exact component={MobileJob} />
          <RedirectRouteLogin path={'/refer'} exact component={Refer} />
          <RedirectRouteLogin path={'/login'} exact component={Login} />
          <RedirectRouteLogin path={'/login/verify'} exact component={LoginVerify} />
          <RedirectRouteLogin path={'/register'} exact component={Register} />
          <RedirectRouteLogin path={'/forgot-password'} exact component={ForgotPassword} />
          <RedirectRouteLogin path={'/reset-password/reset'} exact component={ResetPassword} />
          <RedirectRouteLogin path={'/email/reset'} exact component={Verify} />
          <RedirectRouteLogin path={'/email/verified'} exact component={VerifySuccess} />

          <RedirectRouteLogin path={'/privacy'} exact component={Privacy} />
          <RedirectRouteLogin path={'/terms-and-conditions'} exact component={Terms} />
          <RedirectRouteLogin path={'/cookie-policy'} exact component={CookiePolicy} />

        </Switch>

        <Switch>
          <OnboardingRoute path={'/onboarding'} exact component={Home} />

          <OnboardingRoute path={'/onboarding/screenOne'} exact component={ScreenOne} />
          <OnboardingRoute path={'/onboarding/screenTwo'} exact component={ScreenTwo} />
          <OnboardingRoute path={'/onboarding/screenThree'} exact component={ScreenThree} />
          <OnboardingRoute path={'/onboarding/screenFour'} exact component={ScreenFour} />
          <OnboardingRoute path={'/onboarding/screenFive'} exact component={ScreenFive} />
        </Switch>
      </Router>
    )
  }
}


const RedirectRouteLogin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('user_key') ? (

        <Redirect
          to={{
            pathname: "/dashboard"
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('user_key') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      )
    }
  />
);

const OnboardingRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('user_key') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      )
    }
  />
);



export default App;
