import React, { Component } from 'react'

export class Notification extends Component {

    render() {
        const { message, id, timestamp, onClick, page_url, status } = this.props
        return (
            <div className={status == 'unopened' ? 'notification notifUnopened' : 'notification notif'} onClick={() => { onClick(id, page_url) }}>

                {status == 'unopened' &&
                    <img src={require('../assets/images/chevron-right.png')} alt="UnOpened Notification" />
                }
                {status == 'opened' &&
                    <img src={require('../assets/images/chevron-right.png')} alt="Opened Notification" />
                }

                <div className="notiRow">
                    <div className="notiRowDetails">
                        <div className="notiRow">
                            <div className="notiTitle">
                                <h4>{message}</h4>
                            </div>
                        </div>

                        <div className="notiRow">
                            {status == 'unopened' &&
                                <div className="notiTimeBlock">
                                    <div className="notiGreenBlock notiTime">
                                        <p>{timestamp}</p>
                                    </div>
                                </div>
                            }
                            {status == 'opened' &&
                                <div className="notiTimeBlock">
                                    <div className="notiGreyBlock notiTime">
                                        <p>{timestamp}</p>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}