import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import AuthStore from '../../stores/AuthStore'
import { Input, Button } from '../../components';
import { Helmet } from "react-helmet";

@observer
class Referal extends Component {

  @observable email = '';
  @observable token = '';
  @observable referName = '';
  @observable companyName = '';
  @observable complete = false

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  updateProperty(value) {
    this.email = value
  }

  handleChange(event) {
    this.updateProperty(event.target.value)
  }


  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    if (params.get('token')) {
      this.token = params.get('token')
      AuthStore.getReferalData(this.token).then((res) => {
        let data = res.data
        this.referName = data.referee.first_name + ' ' + data.referee.last_name
        this.companyName = data.job.company.name
      })
    } else {
      console.log("No Token PROVIDED")
    }
  }

  submitForm = () => {
    AuthStore.referalDashboardSignup(this.token.replace('?token=', ''), this.email).then((res) => {
      if (res.data) {
        this.complete = true
      }
    })
  }

  render() {

    let { errors } = AuthStore

    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Pear Up - Referred to {this.companyName} by {this.referName}</title>
          <meta name="description" content="Find your next job or employees straight from your phone…" />
          <meta name="keywords" content="Find your next job or employees straight from your phone" />
          <meta name="author" content="Pear Up" />

          <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta property="og:description" content="Find your next job or employees straight from your phone…" />
          <meta property="og:image" content="../assets/images/icons/icon-lg.png" />
          <meta property="og:url" content="https://pearup-jobs.com/" />

          <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
          <meta name="twitter:image" content="../assets/images/icons/icon-lg.png" />
          <meta name="twitter:card" content="Summary" />

        </Helmet>

        <div className="main">
          <div className="content">
            <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
            <div className="login">
              <a href="/">
                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
              </a>
              <div className="form login-form">
                {!this.complete &&
                  <div>
                    <h1>You've been referred to a job for {this.companyName} by {this.referName} on <span style={{ color: '#59AE46' }}>Pear Up</span></h1>
                    <p>Enter your email address and register to the Pear Up app using this same email address…</p>
                    <Input
                      label="Email address"
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      value={this.email}
                      keyvalue={'email'}
                      onChange={this.handleChange}
                      errors={errors}
                    />

                    <Button text="Submit" customClass="button" onClick={this.submitForm} />
                  </div>
                }

                {this.complete &&
                  <div>
                    <h1>You’ve been referred to a job on <span style={{ color: '#59AE46' }}>Pear Up</span></h1>
                    <p>Download the <b>Pear Up</b> app and register using this same email address you have already entered…</p>

                    <div className="appStoreIcons">
                      <div className="apple">
                        <a href="https://apps.apple.com/gb/app/pear-up/id6450773650" target="_blank">
                          <img src={require('../../assets/images/store/apple.png')} alt="Pear Up Logo" />
                        </a>
                      </div>
                      <div className="google">
                        <a href="https://play.google.com/store/apps/details?id=com.pearupjobs" target="_blank">
                          <img src={require('../../assets/images/store/google.png')} alt="Pear Up Logo" />
                        </a>
                      </div>
                    </div>

                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default Referal;
