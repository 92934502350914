import React, { Component } from 'react';

export class SidebarNavigate extends Component {

	render() {
    const { options, currentOption, onPress } = this.props
		return (
			<div className="sidebar">
				{
					options.map((option, index) => (
						<button
							key={option}
							className={option === currentOption ? "option active" : "option"}
							style={{
								"borderTopLeftRadius": index === 0 ? "5px" : 0,
								"borderBottomLeftRadius": index === options.length-1 ? "5px" : 0,
							}}
							onClick={() => onPress(option)}>
							<span>{ option }</span>
						</button>
					))
				}
      </div>
		);
	}
}
