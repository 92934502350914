import { observable, action, toJS } from "mobx";
import Config from "../config";

class Address {
  @observable id;
  @observable city;
  @observable country;
  @observable created_at;
  @observable updated_at;
  @observable state;
  @observable street_1;
  @observable street_2;
  @observable zip;

  constructor(value) {
    this.id = value.id;
    this.city = value.city;
    this.country = value.country;
    this.created_at = value.created_at;
    this.updated_at = value.updated_at;
    this.state = value.state;
    this.street_1 = value.street_1;
    this.street_2 = value.street_2;
    this.zip = value.zip;
  }
}

class Friend {
  @observable id;
  @observable full_name;
  @observable date_of_birth;
  @observable logo;
  @observable application_status;
  @observable address;
  @observable description;
  @observable referral_status;
  @observable work_experience;

  constructor(value) {
    this.id = value.id;
    this.full_name = value.full_name;
    this.date_of_birth = value.date_of_birth;
    this.logo = value.logo;
    this.application_status = value.application_status;
    this.address = value.address ? new Address(value.address) : null;
    this.description = value.description;
    this.referral_status = value.referral_status
      ? value.referral_status
      : false;
    this.work_experience = value.work_experience;
  }
}

class Skill {
  @observable id;
  @observable name;
  @observable status;
  @observable created_at;
  @observable updated_at;

  constructor(value) {
    this.id = value.id;
    this.name = value.name;
    this.status = value.status;
    this.created_at = value.created_at;
    this.updated_at = value.updated_at;
  }
}

class Industry {
  @observable id;
  @observable name;
  @observable status;

  constructor(value) {
    this.id = value.id;
    this.name = value.name;
    this.status = value.status;
  }
}

class Job {
  @observable id;
  @observable address;
  @observable company_id;
  @observable created_at;
  @observable description;
  @observable contract;
  @observable skills;
  @observable industries;
  @observable name;
  @observable updated_at;
  @observable active;
  @observable reward;
  @observable duration;
  @observable rate;
  @observable paid;

  constructor(value) {
    this.id = value.id;
    this.address = new Address(value.address);
    this.company_id = value.company_id;
    this.created_at = value.created_at;
    this.description = value.description;
    this.contract = value.contract;
    this.skills = this.processSkills(value.skills);
    this.industries = this.processIndustries(value.industries);
    this.name = value.name;
    this.updated_at = value.updated_at;
    this.active = value.active;
    this.reward = value.reward;
    this.duration = value.duration;
    this.rate = value.rate;
    this.paid = value.paid;
  }

  processSkills = (skills) => {
    let arr = [];
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill));
      });
    }
    return arr;
  };

  processIndustries = (industries) => {
    let arr = [];
    if (industries) {
      industries.forEach((industry, index) => {
        arr.push(new Industry(industry));
      });
    }
    return arr;
  };
}

class Applicant {
  @observable id;
  @observable full_name;
  @observable date_of_birth;
  @observable logo;
  @observable application_status;
  @observable address;
  @observable description;
  @observable work_experience;

  @observable work_history;
  @observable educations;
  @observable certificates;
  @observable skills;

  @observable referrals;

  @observable applicant_type;

  constructor(value) {
    this.id = value.id;
    this.full_name = value.full_name;
    this.date_of_birth = value.date_of_birth;
    this.logo = value.logo;
    this.application_status = value.application_status
      ? value.application_status
      : null;
    this.address = value.address ? new Address(value.address) : null;
    this.description = value.description;

    this.work_experience = value.work_experience;
    this.work_history = value.work_history;
    this.skills = value.skills;
    this.educations = value.educations;
    this.certificates = value.certificates;

    this.referrals = value.referrals ? value.referrals : [];
    this.applicant_type = "General";
  }

  processSkills = (skills) => {
    let arr = [];
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill));
      });
    }
    return arr;
  };
}

class Employee {
  @observable id;
  @observable full_name;
  @observable date_of_birth;
  @observable logo;
  @observable application_status;
  @observable address;
  @observable description;

  @observable work_experience;
  @observable work_history;
  @observable educations;
  @observable certificates;
  @observable skills;

  @observable referrals;

  constructor(value) {
    this.id = value.id;
    this.full_name = value.full_name;
    this.date_of_birth = value.date_of_birth;
    this.logo = value.logo;
    this.application_status = value.application_status
      ? value.application_status
      : null;
    this.address = value.address ? new Address(value.address) : null;
    this.description = value.description;

    this.work_experience = value.work_experience;
    this.work_history = value.work_history;
    this.skills = value.skills;
    this.educations = value.educations;
    this.certificates = value.certificates;

    this.referrals = value.referrals ? value.referrals : [];
  }

  processSkills = (skills) => {
    let arr = [];
    if (skills) {
      skills.forEach((skill, index) => {
        arr.push(new Skill(skill));
      });
    }
    return arr;
  };
}

class JobStore {
  @observable count = 0;
  @observable selectedIndex = 0;
  @observable jobs = [];
  @observable paymentMethods = [];
  @observable defaultPaymentMethod = null;
  @observable state = "";
  @observable errors = {};
  @observable activeJobPrice = null;
  @observable activeJobPostDuration = null;
  @observable paymentDisabled = false;
  @observable job = {
    industries: [],
  };
  @observable applicants = [];
  @observable applicant = {};

  @observable employees = [];
  @observable employee = {};
  @observable employeeRequests = {};
  @observable newEmployees = {};
  @observable paymentIntent = "";

  @observable four_week_pricing = null;
  @observable six_week_pricing = null;

  @action.bound
  addJob = (job) => {
    this.jobs.push(job);
  };

  @action.bound
  setActiveJobPrice = (price, duration) => {
    if (price && duration) {
      this.activeJobPrice = price;
      this.activeJobPostDuration = duration;
    } else {
      this.activeJobPrice = null;
      this.activeJobPostDuration = null;
    }
  };

  @action.bound
  getJobs = () => {
    this.state = "pending";
    this.jobs = [];
    let items = 0;
    return fetch(Config.api + "/company/job-posts", {
      method: "GET",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.message == "No results found.") {
            this.state = "complete";
          } else if (
            responseJson.message ==
            "Please verify your email before you use Pear Up"
          ) {
            this.state = "complete";
          } else if (responseJson.data) {
            let jobs = responseJson.data;
            //broken here
            responseJson.data.forEach((job, index, array) => {
              items++;
              this.jobs.push(new Job(job));
              if (items === array.length) {
                this.state = "complete";
              }
            });
          }
        },
        (error) => {}
      );
  };

  @action.bound
  getJob = async (id) => {
    this.state = "pending";
    let items = 0;
    return fetch(Config.api + "/company/job-posts/" + id, {
      method: "GET",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.job = new Job(responseJson.data);
            this.state = "complete";
            return 200;
          }
        },
        (error) => {
          return error;
        }
      );
  };

  @action.bound
  resetJob = () => {
    this.job = {
      industries: [],
      address: {
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
    };
  };

  @action.bound
  newJob = (jobInformation) => {
    this.state = "pending";
    this.errors = {};

    let parsedJob = {
      name: jobInformation.name,
      industries: toJS(jobInformation.industries).map((x) => x.id),
      contract: jobInformation.contract,
      street_1: jobInformation.address.street_1,
      street_2: jobInformation.address.street_2,
      city: jobInformation.address.city,
      state: jobInformation.address.state,
      zip: jobInformation.address.zip,
      country: jobInformation.address.country,
      rate: jobInformation.rate ? jobInformation.rate : null,
      reward: jobInformation.reward ? jobInformation.reward : null,
      description: jobInformation.description,
      duration: jobInformation.duration,
    };

    return fetch(Config.api + "/company/job-posts", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify(parsedJob),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.state = "complete";
            this.job = new Job(responseJson.data);
            return 200;
          } else if (responseJson.errors) {
            this.state = "error";
            this.errors = responseJson.errors;
            return this.errors;
          }
        },
        (error) => {
          this.state = "error";
          this.errors = error;
          return this.errors;
        }
      )
      .catch((error) => {
        this.state = "error";
        this.errors = error;
        return this.errors;
      });
  };

  @action.bound
  createIntent = (jobId, paymentMethodID) => {
    this.state = "pending";
    this.errors = {};

    return fetch(Config.api + "/company/intents", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        job_id: jobId,
        payment_method_id: paymentMethodID,
      }),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.state = "complete";
            this.paymentIntent = responseJson.data.id;
            return responseJson.data;
          } else if (responseJson.message) {
            this.state = "error";
            this.errors = responseJson.message;
            return this.errors;
          }
        },
        (error) => {
          this.state = "error";
          this.errors = error;
          return this.errors;
        }
      )
      .catch((error) => {
        this.state = "error";
        this.errors = error;
        return this.errors;
      });
  };

  @action.bound
  editJob = (id, jobInformation) => {
    this.state = "pending";
    this.errors = {};

    let parsedJob = {
      name: jobInformation.name,
      industries: toJS(jobInformation.industries).map((x) => x.id),
      contract: jobInformation.contract,
      street_1: jobInformation.address.street_1,
      street_2: jobInformation.address.street_2,
      city: jobInformation.address.city,
      state: jobInformation.address.state,
      zip: jobInformation.address.zip,
      country: jobInformation.address.country,
      rate: jobInformation.rate,
      reward: jobInformation.reward,
      duration: jobInformation.duration,
      description: jobInformation.description,
    };

    return fetch(Config.api + "/company/job-posts/" + id, {
      method: "PATCH",
      headers: Config.defaultHeaders,
      body: JSON.stringify(parsedJob),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.state = "complete";
            this.job = new Job(responseJson.data);
            return 200;
          } else if (responseJson.errors) {
            this.state = "error";
            this.errors = responseJson.errors;
            return this.errors;
          }
        },
        (error) => {
          this.state = "error";
          this.errors = error;
          return this.errors;
        }
      )
      .catch((error) => {
        this.state = "error";
        this.errors = error;
        return this.errors;
      });
  };

  @action.bound
  deleteJob = (id) => {
    return fetch(Config.api + "/company/job-posts/" + id, {
      method: "DELETE",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            return 200;
          } else if (responseJson.errors) {
            this.state = "error";
            this.errors = responseJson.errors;
            return this.errors;
          }
        },
        (error) => {
          this.state = "error";
          this.errors = error;
          return this.errors;
        }
      )
      .catch((error) => {
        this.state = "error";
        this.errors = error;
        return this.errors;
      });
  };

  @action.bound
  getCards = () => {
    this.state = "pending";
    this.paymentDisabled = true;
    return fetch(Config.api + "/company/cards", {
      method: "GET",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.data) {
          this.paymentMethods = responseJson.data.cards;
          this.defaultPaymentMethod = responseJson.data.customer.default_source;
          this.state = "complete";
          this.paymentDisabled = false;
        } else if (responseJson.message == "No results found") {
          this.state = "complete";
          this.paymentDisabled = false;
        }
      });
  };

  @action.bound
  createCard = async (token) => {
    this.state = "pending";
    this.paymentDisabled = true;
    return fetch(Config.api + "/company/cards", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify(token),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.paymentMethods.push(responseJson.data.card);
            if (this.paymentMethods.length == 1) {
              this.defaultPaymentMethod = this.paymentMethods[0].id;
            }
            this.state = "complete";
            this.paymentDisabled = false;
            return Promise.resolve(responseJson);
          } else {
            this.state = "complete";
            this.paymentDisabled = false;
            return Promise.reject(responseJson);
          }
        },
        (error) => {
          this.state = "complete";
          this.paymentDisabled = false;
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.state = "complete";
        this.paymentDisabled = false;
        return Promise.reject(error);
      });
  };

  @action.bound
  deleteCard = (card_id) => {
    this.state = "pending";
    this.paymentDisabled = true;
    return fetch(Config.api + "/company/cards", {
      method: "DELETE",
      headers: Config.defaultHeaders,
      body: JSON.stringify(card_id),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data == "Your data has been successfully deleted.") {
            this.state = "complete";
            this.paymentDisabled = false;
            return Promise.resolve(200);
          }
        },
        (error) => {
          this.state = "complete";
          this.paymentDisabled = false;
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.state = "complete";
        this.paymentDisabled = false;
        return Promise.reject(error);
      });
  };

  @action
  setDefaultCard = async (card_id) => {
    this.paymentDisabled = true;
    return fetch(Config.api + "/company/cards", {
      method: "PATCH",
      headers: Config.defaultHeaders,
      body: JSON.stringify(card_id),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.data == "Your data has been successfully updated.") {
          this.paymentDisabled = false;
          this.defaultPaymentMethod = card_id.card_id;

          return Promise.resolve(200);
        }
      });
  };

  @action.bound
  payNow = async (job_id, cost) => {
    this.paymentDisabled = true;
    let postDuration =
      cost == 20 ? this.four_week_pricing : this.six_week_pricing;
    return fetch(Config.api + "/company/job-posts/" + job_id + "/pay", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify({ duration: postDuration }),
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            this.paymentDisabled = false;
            return Promise.resolve(200);
          } else {
            this.paymentDisabled = false;
            return Promise.reject(responseJson);
          }
        },
        (error) => {
          this.paymentDisabled = false;
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.paymentDisabled = false;
        return Promise.reject(error);
      });
  };

  @action.bound
  getApplicants = (id) => {
    this.applicants = [];
    this.state = "pending";
    return fetch(Config.api + "/company/job-posts/" + id + "/applications", {
      method: "GET",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson) {
            if (responseJson.data) {
              responseJson.data.forEach((applicant, index, array) => {
                this.applicants.push(new Applicant(applicant));
              });
              this.state = "complete";
              return responseJson.data;
            }
          }
        },
        (error) => {
          this.state = "complete";
          return error;
        }
      );
  };

  @action.bound
  getGeneralApplicants = (id) => {
    this.applicants = [];
    this.state = "pending";
    return fetch(
      Config.api +
        "/company/job-posts/" +
        id +
        "/applications?referral_filter=general",
      {
        method: "GET",
        headers: Config.defaultHeaders,
      }
    )
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson) {
            if (responseJson.data) {
              responseJson.data.forEach((applicant, index, array) => {
                this.applicants.push(new Applicant(applicant));
              });
              this.state = "complete";
              return responseJson.data;
            }
          }
        },
        (error) => {
          this.state = "complete";
          return error;
        }
      );
  };

  @action.bound
  getReferredApplicants = (id) => {
    this.applicants = [];
    this.state = "pending";
    return fetch(
      Config.api +
        "/company/job-posts/" +
        id +
        "/applications?referral_filter=referred",
      {
        method: "GET",
        headers: Config.defaultHeaders,
      }
    )
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson) {
            if (responseJson.data) {
              responseJson.data.forEach((applicant, index, array) => {
                this.applicants.push(new Applicant(applicant));
              });
              this.state = "complete";
              return responseJson.data;
            }
          }
        },
        (error) => {
          this.state = "complete";
          return error;
        }
      );
  };

  @action.bound
  setApplicant = async (id) => {
    this.applicant = {};
    this.applicant = this.applicants.find((x) => x.id === id);
    return this.applicant;
  };

  @action.bound
  setCurrentEmployee = async (id) => {
    this.employee = {};
    this.employee = this.employees.find((x) => x.id === id);
    return this.employee;
  };

  @action.bound
  setEmployee = async (id) => {
    this.employee = {};
    this.employee = this.newEmployees.find((x) => x.id === id);
    return this.employee;
  };

  @action.bound
  setViewProfile = async (id) => {
    this.employee = {};
    this.employee = this.employeeRequests.find((x) => x.id === id);
    return this.employee;
  };

  @action.bound
  setStatus = async (job_id, applicant_id, data) => {
    this.state = "pending";
    return fetch(
      Config.api +
        "/company/job-posts/" +
        job_id +
        "/applications/" +
        applicant_id,
      {
        method: "PATCH",
        headers: Config.defaultHeaders,
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            return Promise.resolve(200);
          } else {
            this.state = "complete";
            return Promise.reject(responseJson);
          }
        },
        (error) => {
          this.state = "complete";
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.state = "complete";
        return Promise.reject(error);
      });
  };

  @action.bound
  getStatus = async (job_id) => {
    this.state = "pending";
    return fetch(
      Config.api +
        "/company/job-posts/" +
        job_id +
        "/status?payment_intent_id=" +
        this.paymentIntent,
      {
        method: "GET",
        headers: Config.defaultHeaders,
      }
    )
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            return Promise.resolve(responseJson.data);
          } else {
            this.state = "complete";
            return Promise.reject(responseJson);
          }
        },
        (error) => {
          this.state = "complete";
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.state = "complete";
        return Promise.reject(error);
      });
  };

  @action.bound
  closeJob = async (job_id) => {
    this.state = "pending";
    return fetch(Config.api + "/company/job-posts/" + job_id + "/close", {
      method: "PATCH",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then(
        (responseJson) => {
          if (responseJson.data) {
            return Promise.resolve(200);
          } else {
            this.state = "complete";
            return Promise.reject(responseJson);
          }
        },
        (error) => {
          this.state = "complete";
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        this.state = "complete";
        return Promise.reject(error);
      });
  };

  @action.bound
  getAllApplicants = async () => {
    this.applicants = [];
    let url = "/company/applications";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();
      let arr = [];
      if (responseJson.data) {
        if (responseJson.data.length > 0) {
          responseJson.data.forEach((item) => {
            if (item.applicants) {
              if (item.applicants.length > 0) {
                item.applicants.forEach((applicant) => {
                  let ids = arr.map((x) => x.id);
                  if (!ids.includes(applicant.id)) {
                    arr.push(new Applicant(applicant));
                  }
                });
              }
            }
          });
          this.applicants = arr;
        }
      }
      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getAllReferredApplicants = async () => {
    this.applicants = [];
    let url = "/company/applications?referral_filter=referred";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();
      let arr = [];
      if (responseJson.data) {
        if (responseJson.data.length > 0) {
          responseJson.data.forEach((item) => {
            if (item.applicants) {
              if (item.applicants.length > 0) {
                item.applicants.forEach((applicant) => {
                  let ids = arr.map((x) => x.id);
                  if (!ids.includes(applicant.id)) {
                    arr.push(new Applicant(applicant));
                  }
                });
              }
            }
          });
          this.applicants = arr;
        }
      }
      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getAllGeneralApplicants = async () => {
    this.applicants = [];
    let url = "/company/applications?referral_filter=general";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();
      let arr = [];
      if (responseJson.data) {
        if (responseJson.data.length > 0) {
          responseJson.data.forEach((item) => {
            if (item.applicants) {
              if (item.applicants.length > 0) {
                item.applicants.forEach((applicant) => {
                  let ids = arr.map((x) => x.id);
                  if (!ids.includes(applicant.id)) {
                    arr.push(new Applicant(applicant));
                  }
                });
              }
            }
          });
          this.applicants = arr;
        }
      }
      return responseJson;
    } catch (error) {
      return error;
    }
  };
  @action.bound
  getAllEmployees = async () => {
    let arr = [];
    this.applicants = [];
    this.employees = [];
    let url = "/company/employees";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();

      responseJson.data.map((employee) => {
        this.applicants.push(new Applicant(employee));
      });
      responseJson.data.map((employee) => {
        this.employees.push(new Employee(employee));
      });

      return this.applicants;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getEmployeeById = async (id) => {
    let arr = [];
    this.applicants = [];
    this.employees = [];
    let url = "/company/employees/" + id;
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();

      this.employee = responseJson.data;

      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  findPendingEmployeeById = async (id) => {
    let url = "/company/pending-employees/" + id;

    try {
      let repsonse = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });

      let responseJson = await repsonse.json();

      this.employee = responseJson.data;
      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  findEmployeeById = async (id) => {
    let url = "/company/new-employees/" + id;

    try {
      let repsonse = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });

      let responseJson = await repsonse.json();

      this.employee = responseJson.data;
      return responseJson.data;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  findEmployees = async (term) => {
    let arr = [];

    let url = "/company/new-employees";

    if (term) {
      url += "?search=" + term;
    }

    try {
      let repsonse = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });

      let responseJson = await repsonse.json();

      this.newEmployees = responseJson.data;

      if (responseJson) {
        if (responseJson.data) {
          if (responseJson.data.length > 0) {
            responseJson.data.forEach((profile) => {
              arr.push(new Applicant(profile));
              this.newEmployees = responseJson.data;
            });
          }
        }
      }

      return arr;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  acceptRequest = async (user_id) => {
    let arr = [];
    let url = "/company/pending-employees/" + user_id;
    try {
      let repsonse = await fetch(Config.api + url, {
        method: "PATCH",
        headers: Config.defaultHeaders,
        body: JSON.stringify({
          status: "accepted",
        }),
      });

      let responseJson = await repsonse.json();

      responseJson.data.forEach((friend) => {
        arr.push(new Friend(friend));
      });

      return arr;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  sendRequest = async (user_id) => {
    let url = "/company/new-employees";
    try {
      let repsonse = await fetch(Config.api + url, {
        method: "POST",
        headers: Config.defaultHeaders,
        body: JSON.stringify({
          user_id: user_id,
        }),
      });

      let responseJson = await repsonse.json();

      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  declineRequest = async (user_id) => {
    let arr = [];
    let url = "/company/pending-employees/" + user_id;
    try {
      let repsonse = await fetch(Config.api + url, {
        method: "PATCH",
        headers: Config.defaultHeaders,
        body: JSON.stringify({
          status: "declined",
        }),
      });

      let responseJson = await repsonse.json();

      responseJson.data.forEach((friend) => {
        arr.push(new Friend(friend));
      });

      return arr;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  deleteEmployee = async (id) => {
    let arr = [];
    let url = "/company/employees/" + id;
    try {
      let repsonse = await fetch(Config.api + url, {
        method: "DELETE",
        headers: Config.defaultHeaders,
      });

      let responseJson = await repsonse.json();

      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getEmployeeRequests = async () => {
    this.employeeRequests = [];
    let url = "/company/pending-employees";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();
      let arr = [];

      responseJson.data.forEach((applicant) => {
        arr.push(new Applicant(applicant));
        this.employeeRequests = responseJson.data;
      });

      return arr;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getSharableUrl = async () => {
    this.employeeRequests = [];
    let url = "/company/invites";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  inviteByEmailAddress = async (emails) => {
    let emailArr = [];

    emails.forEach((email) => {
      emailArr.push(email.name);
    });

    let url = "/company/invites";
    try {
      let response = await fetch(Config.api + url, {
        method: "POST",
        headers: Config.defaultHeaders,
        body: JSON.stringify({
          emails: emailArr,
        }),
      });
      let responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
  };

  @action.bound
  getPricing = async () => {
    let url = "/pricing";
    try {
      let response = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await response.json();

      this.four_week_pricing = responseJson.data["4_weeks"];
      this.six_week_pricing = responseJson.data["6_weeks"];

      return responseJson;
    } catch (error) {
      return error;
    }
  };
}

const jobStore = new JobStore();
export default jobStore;
