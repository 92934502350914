import React, { Component } from "react";
import "../../App.scss";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import {
  Header,
  Sidebar,
  ContentContainer,
  JobsRow,
  EmptyState,
  ApplicantPanel,
  Breadcrumbs,
  FilterApplicants,
} from "../../components";

import AuthStore from "../../stores/AuthStore";
import JobStore from "../../stores/JobStore";
import CompanyStore from "../../stores/CompanyStore";

import moment from "moment";
import { isEmpty } from "lodash";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import companyStore from "../../stores/CompanyStore";
import { OnboardingModal } from "../../components/_onboardingModal";

@observer
class Jobs extends Component {
  @observable active_sidebar_option = "Active";
  @observable sidebar_options = ["Active", "Closed", "Pending", "Applicants"];

  @observable active_filter_option = "All";
  @observable filter_options = ["All", "Referred", "General"];

  @observable requests = [];
  @observable requestsReferred = [];
  @observable requestsGeneral = [];
  @observable showModalJobs = false;

  setActiveSideBarOption = (option) => {
    console.log("Option: " + option);
    if (option == "Closed") {
      JobStore.getJobs();
    } else if (option == "Pending") {
      JobStore.getJobs();
    } else if (option == "Applicants") {
      JobStore.getAllApplicants().then((res) => {
        if (res.data) {
          this.requests = res.data;
        } else {
          this.requests = [];
        }
        this.loaded = true;
      });

      JobStore.getAllReferredApplicants().then((res) => {
        if (res.data) {
          this.requestsReferred = res.data;
        } else {
          this.requestsReferred = [];
        }
        this.loaded = true;
      });

      JobStore.getAllGeneralApplicants().then((res) => {
        if (res.data) {
          this.requestsGeneral = res.data;
        } else {
          this.requestsGeneral = [];
        }
        this.loaded = true;
      });
    } else if (option == "Active") {
      JobStore.getJobs();
    }
    AuthStore.setActiveSideBarOption(option);
    this.active_sidebar_option = option;
  };

  setActiveFilterOption = (option) => {
    this.active_filter_option = option;
  };

  componentDidMount() {
    if (this.props.location) {
      if (this.props.location.state) {
        if (this.props.location.state.paymentSuccess) {
          ToastsStore.success("Payment made successfully..");
          window.history.replaceState(null, "");
        } else {
          ToastsStore.error("Payment unsuccessful..");
        }
      }
    }

    JobStore.getJobs().then(() => {
      if (JobStore.jobs.length == 0) {
        this.showModalJobs = true;
      }
    });
  }

  processRequests = (data) => {
    let arr = [];
    data.forEach((item) => {
      let obj = {};
      obj.title = item.name;
      obj.job_id = item.id;
      obj.data = item.applicants ? toJS(item.applicants) : [];
      arr.push(obj);
    });
    return arr;
  };

  formatDuration = (start, end) => {
    let startDate = start.split("-").map((l) => {
      return parseInt(l);
    });
    let endDate = end.split("-").map((l) => {
      return parseInt(l);
    });
    return this.capitalise(moment(endDate).from(moment(startDate), true));
  };

  filterJobs = (jobs) => {
    if (this.active_sidebar_option == "Closed") {
      return jobs.filter((el) => el.active == 0 && el.paid != null);
    } else if (this.active_sidebar_option == "Pending") {
      return jobs.filter((el) => el.active == 0 && el.paid == null);
    } else if (this.active_sidebar_option === "Applicants") {
    } else {
      return jobs.filter((el) => el.active == 1);
    }
  };

  goToProfile = (job_id, user_id) => {
    JobStore.getJob(job_id).then((res) => {
      JobStore.setApplicant(user_id).then((res) => {
        if (!isEmpty(res)) {
          this.props.history.push(
            "/dashboard/jobs/job/" + job_id + "/applicant/" + user_id,
            {
              fromScreen: "jobs",
            }
          );
        }
      });
    });
  };

  capitalise = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  render() {
    let { jobs } = JobStore;

    let { activeCompany } = CompanyStore;

    let breadcrumbs = [
      {
        link: "/dashboard",
        title: "Dashboard",
      },
      {
        link: "/dashboard/jobs",
        title: "Jobs",
      },
    ];

    return (
      <div className="page jobs">
        <Breadcrumbs data={breadcrumbs} />

        <Header
          title="Jobs"
          buttonText="Create a job"
          buttonClick={() => {
            JobStore.resetJob();
            this.props.history.push("/dashboard/job/create");
          }}
        />

        <div className="page-content">
          <Sidebar
            options={this.sidebar_options}
            currentOption={this.active_sidebar_option}
            onPress={this.setActiveSideBarOption.bind(this)}
            buttonClick={() => {
              this.props.history.push("/dashboard/job/create");
            }}
          />

          <ContentContainer>
            {this.active_sidebar_option &&
              this.active_sidebar_option != "Applicants" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option} Jobs</h1>
                  {this.filterJobs(jobs).length > 0 &&
                    this.filterJobs(jobs).map((l, index) => (
                      <JobsRow
                        key={index}
                        company_name={activeCompany.name}
                        job_title={l.name}
                        company_image={activeCompany.logo}
                        duration={l.contract}
                        referral={l.reward ? "£" + l.reward : null}
                        active={true}
                        onClick={() => {
                          JobStore.getJob(l.id).then((res) => {
                            if (res == 200) {
                              this.props.history.push(
                                "/dashboard/jobs/job/" + l.id,
                                {
                                  id: l.id,
                                }
                              );
                            }
                          });
                        }}
                      />
                    ))}

                  {this.showModalJobs ? (
                    <OnboardingModal
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 300,
                      }}
                      visible={this.showModalJobs}
                      image={require("../../assets/images/createJob.png")}
                      title="Create Jobs"
                      subHeading={
                        "Post jobs with referral reward and track applicant source through employee-shared links."
                      }
                      text="Unlock Your Potential Today"
                      onClose={() => (this.showModalJobs = false)}
                      buttonText="Create a job"
                      onPress={() => {
                        this.props.history.push("/dashboard/job/create");
                        this.showModalJobs = false;
                      }}
                    />
                  ) : null}

                  {this.filterJobs(jobs).length < 1 && (
                    <EmptyState
                      image={require("../../assets/images/icons/noData.png")}
                      title="No Jobs Found"
                      onClick={() => {
                        this.handleClick();
                      }}
                    />
                  )}
                </div>
              )}

            {this.active_sidebar_option &&
              this.active_sidebar_option === "Applicants" && (
                <div className="main-content">
                  <h1>{this.active_sidebar_option}</h1>

                  <FilterApplicants
                    options={this.filter_options}
                    currentOption={this.active_filter_option}
                    onPress={this.setActiveFilterOption.bind(this)}
                  />

                  {this.active_filter_option === "All" &&
                    this.requests.length > 0 &&
                    this.requests.map((item, index) => (
                      <div key={item.id}>
                        <h3>
                          {item.name}{" "}
                          <span className="count">
                            ({item.applicants.length})
                          </span>
                        </h3>
                        {item.applicants.map((subItem, subindex) => (
                          <ApplicantPanel
                            key={subItem.id}
                            id={subItem.id}
                            image={subItem.logo}
                            name={subItem.full_name}
                            job_id={item.id}
                            type={
                              subItem.referrals.length > 0
                                ? "Referred"
                                : "General"
                            }
                            onPress={this.goToProfile.bind(this)}
                          />
                        ))}
                      </div>
                    ))}

                  {this.active_filter_option === "Referred" &&
                    this.requestsReferred.length > 0 &&
                    this.requestsReferred.map((item, index) => (
                      <div key={item.id}>
                        <h3>
                          {item.name}{" "}
                          <span className="count">
                            ({item.applicants.length})
                          </span>
                        </h3>
                        {item.applicants.map((subItem, subindex) => (
                          <ApplicantPanel
                            key={subItem.id}
                            id={subItem.id}
                            image={subItem.logo}
                            name={subItem.full_name}
                            job_id={item.id}
                            type="Referred"
                            onPress={this.goToProfile.bind(this)}
                          />
                        ))}
                      </div>
                    ))}

                  {this.active_filter_option === "General" &&
                    this.requestsGeneral.length > 0 &&
                    this.requestsGeneral.map((item, index) => (
                      <div key={item.id}>
                        <h3>
                          {item.name}{" "}
                          <span className="count">
                            ({item.applicants.length})
                          </span>
                        </h3>
                        {item.applicants.map((subItem, subindex) => (
                          <ApplicantPanel
                            key={subItem.id}
                            id={subItem.id}
                            image={subItem.logo}
                            name={subItem.full_name}
                            job_id={item.id}
                            type="General"
                            onPress={this.goToProfile.bind(this)}
                          />
                        ))}
                      </div>
                    ))}

                  {this.active_filter_option === "All" &&
                    this.requests.length < 1 && (
                      <EmptyState
                        image={require("../../assets/images/icons/noData.png")}
                        title="No Applicants Found"
                        onClick={() => {
                          this.handleClick();
                        }}
                      />
                    )}

                  {this.active_filter_option === "Referred" &&
                    this.requestsReferred.length < 1 && (
                      <EmptyState
                        image={require("../../assets/images/icons/noData.png")}
                        title="No Applicants Found"
                        onClick={() => {
                          this.handleClick();
                        }}
                      />
                    )}

                  {this.active_filter_option === "General" &&
                    this.requestsGeneral.length < 1 && (
                      <EmptyState
                        image={require("../../assets/images/icons/noData.png")}
                        title="No Applicants Found"
                        onClick={() => {
                          this.handleClick();
                        }}
                      />
                    )}
                </div>
              )}

            <ToastsContainer
              className="toast"
              position={ToastsContainerPosition.BOTTOM_CENTER}
              store={ToastsStore}
            />
          </ContentContainer>
        </div>
      </div>
    );
  }
}
export default Jobs;
