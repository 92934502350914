import React, { Component } from 'react';
import { Button } from '../components';
export class EmptyState extends Component {

	render() {

		const { title, message, image, button, buttonText, onClick } = this.props

		return (
			<div className="emptyState">
				<img src={image} alt="Logo" />
				<h3>{title}</h3>
				<p>{message}</p>

				{button &&
					<Button
						children={buttonText}
						onClick={onClick}
					/>
				}
			</div>
		)
	}
}

