import React, { Component } from 'react';

export class DataRowInfo extends Component {


    render() {

        const { title, value } = this.props

        return (
            <div className="dataRowInfo">
                <div className="dataRowTitle">
                    <p>{title}</p>
                </div>

                <div className="dataRowValue">
                    <p>{value}</p>
                </div>

            </div>
        );

    }
}
