import React, { Component } from "react";

export class OnboardingFooter extends Component {
  render() {
    return (
      <div className="onboarding-footer">
        <p>© 2023, Pear Up</p>
      </div>
    );
  }
}
