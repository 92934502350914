import React from 'react';
import SearchScreen from '../../assets/images/homepage/mobile/2.png';
import MapScreen from '../../assets/images/homepage/mobile/1.png';
import StartScreen from '../../assets/images/homepage/mobile/4.png';
import SkillsScreen from '../../assets/images/homepage/mobile/3.png';
import MessageScreen from '../../assets/images/homepage/mobile/5.png';
import Swirl from '../../assets/images/homepage/swirl.png';
import { Slide, Fade } from 'react-reveal';


const AboutUs = () => {
    return (
        <div className="aboutUs section" >

            <Fade duration={2500}>
                <div id="aboutUs" className="aboutUsTitleSection">
                    <h2>About Us</h2>
                </div>
                <hr />
            </Fade>
            <div className="container">
                <div className="left">
                    <p><b>Like you, Pear Up Founder Kevin Eves has seen first-hand the challenges of recruitment in everyday industries. As a 16-year-old looking for local work and some pocket money, his job search was based on who he knew in his local area... that and pure luck.</b> </p>
                    <p>And as Co-owner of a family-run recycling business in Ireland, Kevin and his family know their business is only as good as the people they employ. That making one great hire is worth more than five average ones. And that a worker who is happy and productive in their role is the most valuable asset to have.</p>
                </div>
                <div className="right">
                    <p>But finding the right people is tough, and all too often business owners fall into the trap of over-priced advertising or in-house referral schemes that don’t make the cut. </p>
                    <p>Pear Up was born from years of navigating the minefield of hourly recruitment; from the realisation that job searches don’t have to be this difficult. Current digital platforms’ clear preference for professional sectors is frustrating and unfair, so Pear Up’s mission is to give hourly workers and recruiting companies a voice. We’re certain that we can make a difference to their lives.</p>
                    <p>Your job search matters to us, whether you’re a student looking for a casual summer job or a semi-retired farmer seeking a local general labour role. Don’t rely onluck and who you know in your next job search: take your next role into your own hands.</p>
                </div>
            </div>
            <Fade right duration={1000} delay={1000}>
                <img src={Swirl} className="swirl" />
            </Fade>
            <Slide left delay={500} duration={2000}>
                <div className="aboutUsRowCont">
                    <img className="outerLeft" src={SearchScreen} />
                    <img className="innerLeft" src={MapScreen} />
                    <img src={StartScreen} />
                    <img className="innerRight" src={SkillsScreen} />
                    <img className="outerRight" src={MessageScreen} />
                </div>
            </Slide>
        </div>
    );

}

export default AboutUs;