import { observable, action, toJS } from "mobx";

import Config from "../config";

class AuthStore {
  @observable user_type = null;
  @observable unreadNotifications = false;
  @observable unreadMessages = false;
  @observable sidebar_options = ["Active", "Closed", "Pending"];
  @observable active_sidebar_option = "Active";
  @observable errors = {};

  setUserKey = async (key) => {
    return localStorage.setItem("user_key", key).then(() => {
      Config.userKey = key;
    });
  };

  setUserType = async (type) => {
    return localStorage.setItem("user_type", type).then(() => {
      this.user_type = type;
    });
  };

  checkAuth = async () => {
    let user_key = await localStorage.getItem("user_key");
    if (user_key) {
      Config.userKey = user_key;
      return fetch(Config.api + "/auth/check-auth", {
        //return fetch(Config.api + '/auth/check-auth?version_code=' + Config.version + '&app=true', {
        method: "GET",
        headers: Config.defaultHeaders,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          return this.getStatus()
            .then((res) => {
              return responseJson;
            })
            .catch(() => {
              return responseJson;
            });
        });
    }
  };

  @action.bound
  completeOnboarding = () => {
    let url = `/company/onboarding-complete`;

    try {
      const options = {
        method: "POST",
        headers: Config.defaultHeaders,
      };
      let response = fetch(Config.api + url, options);
      let responseJson = response.json();
      if (responseJson.data) {
        return responseJson;
      } else {
        return responseJson.message;
      }
    } catch (error) {
      return error;
    }
  };

  @action
  setActiveSideBarOption(option) {
    this.active_sidebar_option = option;
  }

  @action.bound
  getStatus = async () => {
    let url = "/notifications/status";
    try {
      let repsonse = await fetch(Config.api + url, {
        method: "GET",
        headers: Config.defaultHeaders,
      });
      let responseJson = await repsonse.json();
      if (responseJson.data) {
        this.unreadNotifications = responseJson.data.notifications;
        this.unreadMessages = responseJson.data.messages;
      }
      return 200;
    } catch (error) {
      return error;
    }
  };

  verifyRequest = async (token) => {
    return fetch(Config.api + "/auth/reset-email/resend", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Xpu-Api-Key": Config.apiKey,
        "Xpu-User-Key": token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          this.state = "complete";
          this.errors = responseJson.errors;
          return responseJson;
        } else {
          this.state = "error";
          this.errors = responseJson.errors;
          return responseJson.message;
        }
      });
  };

  logout = async () => {
    return fetch(Config.api + "/auth/logout", {
      method: "DELETE",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.data) {
          localStorage.setItem("user_key", "");
          Config.userKey = "";
          return Promise.resolve(200);
        }
      });
  };

  verifyEmail = async (token) => {
    return fetch(Config.api + "/auth/email/verify", {
      method: "PATCH",
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          this.state = "complete";
          this.errors = responseJson.errors;
          return responseJson;
        } else {
          this.state = "error";
          this.errors = responseJson.errors;
          return responseJson.message;
        }
      });
  };

  resetPassword = async (current_password, password, password_confirmation) => {
    return fetch(Config.api + "/auth/reset-password", {
      method: "PATCH",
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        current_password: current_password,
        password: password,
        password_confirmation: password_confirmation,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          this.state = "complete";
          this.errors = responseJson.errors;
          return responseJson;
        } else {
          this.state = "error";
          this.errors = responseJson.errors;
          return responseJson.message;
        }
      });
  };

  referalDashboardSignup = async (token, email) => {
    return fetch(Config.api + "/refer", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        token: token,
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.data) {
          this.state = "complete";
          return responseJson;
        } else {
          this.errors = responseJson.errors;
          return toJS(this.errors);
        }
      });
  };

  getReferalData = async (token) => {
    return fetch(Config.api + "/refer?token=" + token, {
      method: "GET",
      headers: Config.defaultHeaders,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.data) {
          this.state = "complete";
          return responseJson;
        } else {
          this.errors = responseJson.errors;
          return toJS(this.errors);
        }
      });
  };

  changeEmail = async (email, email_confirmation) => {
    return fetch(Config.api + "/auth/reset-email", {
      method: "POST",
      headers: Config.defaultHeaders,
      body: JSON.stringify({
        email: email,
        email_confirmation: email_confirmation,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          this.state = "complete";
          this.errors = responseJson.errors;
          return responseJson;
        } else {
          this.state = "error";
          this.errors = responseJson.errors;
          return responseJson.message;
        }
      });
  };
}

const authStore = new AuthStore();
export default authStore;
