import React, { Component } from 'react'

export class TableRowList extends Component {

    constructor(props, context) {
        super(props, context)
    }


    render() {
        const { title, value } = this.props

        return (
            <div className="dataRow">
                <div className="dataRowTitle">
                    <p>{title}</p>
                </div>

                <div className="dataRowValue">
                    {
                        value.map((l, index) => (
                            <p style={{ textAlign: 'right' }} key={index}>
                                {l.title && l.employer &&
                                    l.title + ' - ' + l.employer
                                }
                                {l.name &&
                                    l.name
                                }
                            </p>
                        ))
                    }
                </div>
            </div>
        )
    }
}
