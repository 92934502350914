import React, { Component } from 'react';

export class Pill extends Component {

    render() {

        const { skill, selected, onClick } = this.props

        return (
            <button type="button" className={selected === true ? 'pill pill-active' : 'pill'} onClick={() => onClick(skill)}>
                {skill.name}
            </button>
        );

    }
}
