import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, Button, Breadcrumbs, Input } from '../../../components';

import AuthStore from '../../../stores/AuthStore'
import CompanyStore from '../../../stores/CompanyStore'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


@observer
class DeleteAccount extends Component {

    @observable active_sidebar_option = 'Settings'
    @observable sidebar_options = ['Settings']

    @observable password = ''

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }


    confirmAccountDelete = async () => {

        CompanyStore.deleteAccount(this.password).then(res => {
            if (res == 200) {
                this.loading = false
                ToastsStore.success("Account has been deleted")
                setTimeout(
                    function () {
                        this.props.history.push('/login')
                    }
                        .bind(this),
                    2500
                );
            } else {
                ToastsStore.error("Incorrect Credientials")
            }
        })
            .catch(err => {
                this.loading = false
                ToastsStore.error(err)
            })
    }


    render() {

        let { errors } = AuthStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/profile',
                title: 'Profile'
            },
            {
                link: '/dashboard/profile/delete',
                title: 'Delete Account'
            }
        ]
        return (

            <div className="page jobs">
                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Delete Account"
                    buttonText="Back"
                    buttonClass='button button-white'
                    buttonClick={() => {
                        this.props.history.goBack()
                    }}
                />

                <div className="page-content">

                    <ContentContainer>
                        <div className="main-content">
                            <h1>Delete Account</h1>

                            <Input
                                label="Type your password to confirm"
                                name="password"
                                type="password"
                                placeholder="Confirm Your Password"
                                keyvalue={'password'}
                                value={this.password || ''}
                                onChange={text => this.password = text.target.value}
                                errors={errors}
                            />

                            {errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                                <div className="errorsContainer">
                                    {
                                        Object.keys(errors).map((key) => (
                                            errors[key].map((l, index) => (
                                                key == 'email' ? <p className="error" key={index}>{l}</p> : null
                                            ))
                                        ))
                                    }
                                </div>
                            }

                            <Button
                                text="Delete Account"
                                customClass="button"
                                style={{ width: '45%', float: 'right' }}
                                onClick={() => this.confirmAccountDelete()}
                            />

                        </div>

                        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />

                    </ContentContainer>
                </div>

            </div >
        );
    }
}
export default DeleteAccount;
