import React, { Component } from "react";
import "../../App.scss";
import { observer } from "mobx-react";
import { Button } from "../../components";
import { Helmet } from "react-helmet";
import { SkipButton } from "../../components/_skipButton";
import { OnboardingFooter } from "../../components/_onboardingFooter";

@observer
class ScreenThree extends Component {
  render() {
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Onboarding</title>
        </Helmet>

        <div className="main">
          <div className="onboardingNav">
            <div className="backButton">
              <a
                href="/onboarding/screenTwo"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={require("../../assets/images/arrow-left.png")}
                  style={{ marginLeft: 40, marginRight: 10, marginTop: 5 }}
                />
                <span style={{ color: "black" }}>Back</span>
              </a>
            </div>
            <div className="onboardingNavSkip">
              <SkipButton />
            </div>
          </div>

          <div className="onboarding">
            <img src={require("../../assets/images/handshake.png")} />
            <div className="onboarding-container">
              <h1>Get started!</h1>
              <h3>STEP 2</h3>
              <h5>
                Create a job post and set a referral reward of your choice for
                your employees. <br />
                <br /> Once a job is live your employee will received a
                notification with a link to share on their social media.
                <br />
              </h5>
            </div>
            <br />
            <img src={require("../../assets/images/progress3.png")} />
            <blockquote />
            <Button
              customClass="buttonNext"
              text="Next"
              onClick={() => this.props.history.push("/onboarding/screenFour")}
            ></Button>
          </div>
          <img
            src={require("../../assets/images/leaf-bg.svg")}
            className="leaf-bg"
            alt="Pear Up Logo"
          />
        </div>
        <OnboardingFooter />
      </div>
    );
  }
}
export default ScreenThree;
