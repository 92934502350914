import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Button } from '../../components';
import CompanyStore from '../../stores/CompanyStore'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Helmet } from "react-helmet";

@observer
class ForgotPassword extends Component {

    @observable email = ''
    @observable loading = false
    @observable valid = false

    constructor(props) {
        super(props);
        this.updateProperty = this.updateProperty.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    updateProperty(key, value) {
        this.email = value
    }

    handleChange(event) {
        this.updateProperty(event.target.name, event.target.value)
    }

    submitForm = () => {

        CompanyStore.forgotPassword(this.email).then(res => {
            if (res == 200) {
                ToastsStore.success("You will receive an email with instructions on how to reset your password.")
                setTimeout(
                    function () {
                        this.props.history.push('/')
                    }
                        .bind(this),
                    2500
                );
            }
            else {
                this.valid = res
            }
        })
    }

    render() {
        let { errors } = CompanyStore
        return (
            <div className="wrapper wrapper-white">
                <Helmet>
                    <title>Pear Up | Forgot Password</title>
                    <meta name="description" content="Find your next job or employees straight from your phone…" />
                    <meta name="keywords" content="Find your next job or employees straight from your phone" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta property="og:description" content="Find your next job or employees straight from your phone…" />
                    <meta property="og:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
                    <meta name="twitter:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />

                </Helmet>

                <div className="main">
                    <div className="content">
                        <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
                        <div className="login">
                            <a href="/login" className="link register-link">Or <span style={{ color: '#59AE46' }}>Login</span></a>
                            <a href="/">
                                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
                            </a>
                            <div className="form login-form">
                                <h1>Forgot Your Password</h1>
                                <Input
                                    label="Email address"
                                    name="email"
                                    type="email"
                                    placeholder="Enter your Email Address"
                                    value={this.email}
                                    onChange={this.handleChange}
                                />

                                {this.valid &&
                                    <div className="errorsContainer">
                                        <p className="error">{this.valid}</p>
                                    </div>
                                }

                                <Button customClass="button"
                                    text="Reset your password" onClick={this.submitForm}>
                                </Button>
                            </div>
                        </div>
                        <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
                    </div>
                </div>
                <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
            </div>

        );
    }
}
export default ForgotPassword;
