import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import AuthStore from '../../stores/AuthStore'
import { Input, Button } from '../../components';
import { Helmet } from "react-helmet";

@observer
class Referal extends Component {

    @observable email = '';
    @observable token = '';
    @observable referName = '';
    @observable companyName = '';
    @observable complete = false

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    updateProperty(value) {
    }

    handleChange(event) {
    }


    componentDidMount() {

    }

    submitForm = () => {

    }

    render() {

        let { errors } = AuthStore

        return (
            <div className="wrapper wrapper-white">
                <Helmet>
                    <title>Pear Up - Employee Invite</title>
                    <meta name="description" content="Find your next job or employees straight from your phone…" />
                    <meta name="keywords" content="Find your next job or employees straight from your phone" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta property="og:description" content="Find your next job or employees straight from your phone…" />
                    <meta property="og:image" content="../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
                    <meta name="twitter:image" content="../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />

                </Helmet>

                <div className="main">
                    <div className="content">
                        <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
                        <div className="login">
                            <a href="/">
                                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
                            </a>
                            <div className="form login-form">
                                <div>
                                    <h1>You've been invited to join <span style={{ color: '#59AE46' }}>Pear Up</span></h1>
                                    <p>You can download the app and create an account to get started today!</p>

                                    <div className="appStoreIcons">
                                        <div className="apple">
                                            <a href="https://apps.apple.com/gb/app/pear-up/id6450773650" target="_blank">
                                                <img src={require('../../assets/images/store/apple.png')} alt="Pear Up Logo" />
                                            </a>
                                        </div>
                                        <div className="google">
                                            <a href="https://play.google.com/store/apps/details?id=com.pearupjobs" target="_blank">
                                                <img src={require('../../assets/images/store/google.png')} alt="Pear Up Logo" />
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default Referal;
