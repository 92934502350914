import React, { Component } from 'react';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';

export class InputRemovable extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(value) {
        this.props.add(value)
        this.props.delete(value)
    }

    render() {

        const { label, errors, keyvalue, value, name, id } = this.props

        return (
            <div>
                <div className="input-container-removable">
                    <label>{label}</label>
                    <input
                        {...this.props}
                        className="input"
                    />

                    {name === 'new_class' &&
                        <div key={id} className="btn-add btn-circle " onClick={() => this.props.add(value)}>
                            <Add />
                        </div>
                    }
                    {name != 'new_class' &&
                        <div key={id} className="btn-remove btn-circle " onClick={() => this.props.delete(id)}>
                            <Close />
                        </div>
                    }

                </div>

                {
                    errors && Object.keys(errors) && Object.keys(errors).length > 0 &&
                    <div className="errorsContainer">
                        {
                            Object.keys(errors).map((key) => (
                                errors[key].map((l, index) => (
                                    key == keyvalue ? <p className="error" key={index}>{l}</p> : null
                                ))
                            ))
                        }
                    </div>
                }

            </div>
        );
    }
}
