import React, { Component } from 'react';

export class Breadcrumbs extends Component {

    render() {
        const { data } = this.props

        return (

            <div className="breadcrumbs">
                {data.map((l, index) => (
                    <li key={index}>
                        <a href={l.link}>{l.title}</a>
                    </li>
                ))
                }
            </div>

        );

    }
}
