import React, { Component } from 'react'
import moment from "moment";

export class MessageRow extends Component {

    render() {


        const { created, message, newContact, image, name, onClick, seen } = this.props


        return (
            <div className={seen === 0 ? 'message-row message-container-unopened' : ' message-row message-container'} onClick={onClick}>
                <div className="row">
                    <div style={{ width: '25%' }}>

                        {image &&
                            <img src={image} className="companyImage" alt="Company Icon" />
                        }
                        {!image &&
                            <img src={require("../assets/images/profile/noProfileImage.png")} className="companyImage" alt="Company Icon" />
                        }

                    </div>


                    <div style={{ width: '50%' }}>
                        <h3>{name}</h3>
                        <p>{message}</p>
                    </div>

                    {newContact &&
                        <div style={{ width: '25%', display: 'flex' }}>
                            <div className="message-user">
                                <button className="">Message</button>
                            </div>
                        </div>
                    }

                    {!newContact &&
                        <div style={{ width: '25%', display: 'flex' }}>
                            <div className="message-ago">
                                <p>{moment(created).fromNow()}</p>
                            </div>

                            {seen === 1 &&
                                <div className='chev-right'>
                                    <img src={require('../assets/images/message-chevron-right.png')} alt="Open Message Icon" />
                                </div>
                            }
                            {seen === 0 &&
                                <div className="chev-right">
                                    <img src={require('../assets/images/message-chevron-right-green.png')} alt="Open Message Icon" />
                                </div>
                            }
                        </div>
                    }


                </div>
            </div>
        )
    }
}


