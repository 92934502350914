import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import {
  Header,
  ContentContainer,
  Button,
  PaymentMethod,
  ConfirmationModal,
  ButtonFooter,
  DataRow,
  Breadcrumbs,
  PaymentCTA,
} from '../../../components';

import JobStore from '../../../stores/JobStore';
import CompanyStore from '../../../stores/CompanyStore';

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from 'react-toasts';
import Jobs from '../jobs';

@observer
class PaymentMethods extends Component {
  @observable confirmationModal = false;
  @observable activeCardId = null;
  @observable defaultPaymentMethodID = null;

  componentDidMount() {
    JobStore.getCards();

    JobStore.getJob(this.props.match.params.id).then((res) => {
      let job = JobStore.job;
      if (job.active) {
        this.props.history.push(
          '/dashboard/jobs/job/' + this.props.match.params.id
        );
      }
    });
  }

  deleteCard = (id) => {
    JobStore.deleteCard({ payment_method_id: id })
      .then((res) => {
        if (res == 200) {
          JobStore.getCards();
          this.activeCardId = null;
          this.confirmationModal = false;
        }
      })
      .catch((error) => {
        this.activeCardId = null;
      });
  };

  setDefault = (id) => {
    // if (id != JobStore.defaultPaymentMethod) {
    //     JobStore.setDefaultCard(id).then(res => {
    //     })
    // }
  };

  cancelDelete = () => {
    this.confirmationModal = false;
    this.activeCardId = null;
  };

  toBeDeleted = (id) => {
    this.confirmationModal = true;
    this.activeCardId = id;
  };

  closeModal = () => {
    this.showModal = false;
  };

  render() {
    const { paymentMethods, state, job, defaultPaymentMethod, paymentIntent } =
      JobStore;
    const { activeCompany } = CompanyStore;

    let breadcrumbs = [
      {
        link: '/dashboard',
        title: 'Dashboard',
      },
      {
        link: '/dashboard/jobs',
        title: 'Jobs',
      },
      {
        link: this.props.match.url,
        title: 'Payment Methods',
      },
    ];

    return (
      <div className='page jobs'>
        <Breadcrumbs data={breadcrumbs} />

        <Header title='Jobs' />

        <div className='page-content'>
          {activeCompany.id === job.company_id && (
            <ContentContainer>
              <div className='main-content cards-content'>
                <h1>Order Summary</h1>

                {activeCompany.address && activeCompany.address.country && (
                  <DataRow
                    title='Total Cost'
                    value={`${
                      activeCompany.address.country === 'United Kingdom'
                        ? '£'
                        : '€'
                    }${
                      job.duration === '4_weeks'
                        ? JobStore.four_week_pricing
                        : JobStore.six_week_pricing
                    }`}
                  />
                )}

                <h2>Payment Methods</h2>
                <p>Select a payment method to checkout…</p>

                {state == 'pending' && <p>Loading...</p>}

                <div className='cards'>
                  {state == 'complete' &&
                    paymentMethods.length > 0 &&
                    paymentMethods.map((item, key) => (
                      <PaymentMethod
                        key={item.id}
                        brand={item.card.brand}
                        last4={item.card.last4}
                        exp_month={item.card.exp_month}
                        exp_year={item.card.exp_year}
                        card_id={item.id}
                        onDelete={this.toBeDeleted.bind(this)}
                        toBeDeleted={this.activeCardId == item.id}
                        deleteCard={this.deleteCard.bind(this)}
                        onSelect={(card) => {
                          console.log(card.card_id);
                          this.defaultPaymentMethodID = card.card_id;
                        }}
                        selected={item.id == this.defaultPaymentMethodID}
                        cancel={this.cancelDelete.bind(this)}
                      />
                    ))}
                </div>

                {state == 'complete' && paymentMethods.length == 0 && (
                  <p>No payment methods found.</p>
                )}

                <Button
                  text='Add payment Method'
                  customClass='button btn-black'
                  style={{ maxWidth: '400px' }}
                  onClick={() =>
                    this.props.history.push(
                      '/dashboard/job/pay/' +
                        this.props.match.params.id +
                        '/card'
                    )
                  }
                />
                <PaymentCTA
                  props={this.props}
                  defaultPaymentMethodID={this.defaultPaymentMethodID}
                />
              </div>
              <ToastsContainer
                className='toast'
                position={ToastsContainerPosition.BOTTOM_CENTER}
                store={ToastsStore}
              />
            </ContentContainer>
          )}
        </div>

        <ConfirmationModal
          visible={this.confirmationModal}
          title={`Are you sure you wish to delete this Payment Method?`}
          text='This action cannot be undone...'
          declineText='No, cancel'
          onClose={() => {
            this.confirmationModal = false;
          }}
          acceptText='Yes, delete'
          acceptFunc={() => this.deleteCard(this.activeCardId)}
        />
      </div>
    );
  }
}
export default PaymentMethods;
