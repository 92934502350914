import React, { Component } from 'react';
import '../../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ContentContainer } from '../../../components';

import JobStore from '../../../stores/JobStore'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

@observer
class PaymentProcessing extends Component {

    @observable statusOutput = "";


    componentDidMount() {

        if (this.props.location) {
            if (this.props.location.state) {
                if (this.props.location.state.jobID) {
                    let checkStatus = setInterval(() => {
                        JobStore.getStatus(this.props.location.state.jobID)
                            .then((res) => {
                                this.statusOutput = res;
                                if (res === "This post has been paid for.") {
                                    clearInterval(checkStatus);
                                    this.props.history.push(`/dashboard/jobs`, { paymentSuccess: true });
                                }
                            })
                            .catch(err => {
                                console.log("catchhhhh: " + JSON.stringify(err));
                                clearInterval(checkStatus);
                                this.props.history.push("/dashboard/jobs", { paymentSuccess: false });
                                window.history.replaceState(null, '');
                            })
                    }, 3000);
                    window.history.replaceState(null, '')
                } else {
                    console.log("no job ID");
                    this.props.history.push("/dashboard/jobs", { paymentSuccess: false });
                    window.history.replaceState(null, '')
                }
            } else {
                this.props.history.push("/dashboard/jobs", { paymentSuccess: false });
                window.history.replaceState(null, '')
            }
        }
    }

    render() {

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/jobs',
                title: 'Jobs'
            },
            {
                link: this.props.match.url,
                title: 'Payment Methods'
            }
        ]

        return (
            <div className="page jobs">

                <div className="page-content">

                    <div className="main-content">

                        <ContentContainer>

                            <div className="d-flex flex-col f-width">
                                <h2 className="text-center f-width">Payment Processing</h2>
                                <p className="text-center f-width">Please wait while your payment is processed...</p>
                                {this.statusOutput.length > 0 &&
                                    <p className="text-center f-width">Payment Status: <b>{this.statusOutput}</b></p>
                                }
                            </div>

                        </ContentContainer>


                    </div>
                </div>


            </div >

        );
    }
}
export default PaymentProcessing;
