import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, ProfileImage, DataRow, DataRowInfo, ConfirmationModal, Breadcrumbs, TableRowList, WorkHistoryRow } from '../../components';

import AuthStore from '../../stores/AuthStore'
import JobStore from '../../stores/JobStore'

import moment from "moment";


@observer
class NewEmployeeProfile extends Component {

    @observable active_sidebar_option = 'Description'

    @observable sidebar_options = ['Description']

    @observable requests = []

    @observable confirmationModal = false
    @observable status = "decline"
    @observable buttonsDisabled = false

    @observable breadcrumbs = []

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }


    applicantStatus = () => {
        let obj = {
            status: this.status == "decline" ? "declined" : "accepted"
        }

        this.confirmationModal = false

        JobStore.setStatus(JobStore.job.id, JobStore.applicant.id, obj).then(response => {
            if (response === 200) {
                JobStore.getApplicants(JobStore.job.id).then(res => {
                    if (res === 200) {
                        JobStore.setApplicant(JobStore.applicant.id).then(applicant => {
                            if (applicant) {
                                this.buttonsDisabled = true
                                this.props.history.goBack()
                            }
                        })
                    }
                })
            }
        })

    }

    processReferrals = (referrals) => {
        const referrals_length = referrals.length;
        let text = '';

        referrals.map((referee, index) => {
            if (referrals_length === index + 1) {
                text += referee.first_name + ' ' + referee.last_name
            }
            else {
                text += referee.first_name + ' ' + referee.last_name + ', '
            }
        })
        return text
    }

    componentDidMount() {

        if (!JobStore.applicant.id) {
            JobStore.getAllApplicants()
                .then(res => {
                    if (res.data) {
                        JobStore.setApplicant(this.props.location.state.user_id).then(res => {

                        })
                    }
                    else {
                        this.requests = []
                    }
                    this.loaded = true
                })
        }
        else {
            JobStore.getAllApplicants()
                .then(res => {
                    if (res.data) {
                        JobStore.setApplicant(this.props.location.state.user_id).then(res => {

                        })
                    }
                    else {
                        this.requests = []
                    }
                    this.loaded = true
                })
        }

        if (this.props.location.state) {
            if (this.props.location.state.fromScreen) {
                if (this.props.location.state.fromScreen === "jobs") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/jobs',
                            title: 'Jobs'
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id,
                            title: JobStore.job.name
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id + '/applicant/' + JobStore.applicant.id,
                            title: JobStore.applicant.full_name
                        }
                    ]
                } else if (this.props.location.state.fromScreen === "job") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/jobs',
                            title: 'Jobs'
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id,
                            title: JobStore.job.name
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id + '/applicant/' + JobStore.applicant.id,
                            title: JobStore.applicant.full_name
                        }
                    ]
                } else if (this.props.location.state.fromScreen === "employees") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/employees',
                            title: 'Employees'
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.job.id + '/applicant/' + JobStore.applicant.id,
                            title: JobStore.applicant.full_name
                        }
                    ]
                }
            }
        }
    }

    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }


    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    render() {

        let { job, applicant } = JobStore
        return (
            <div className="page jobs">

                <Breadcrumbs
                    data={this.breadcrumbs}
                />

                <Header
                    title="Employees"
                    buttonText="Create a job"
                    backButton={true}
                    onClick={() => {
                        this.props.history.goBack()
                    }}
                    buttonClick={() => {
                        this.props.history.push('/dashboard/job/create')
                    }}
                />
                <div className="page-content">
                    <Sidebar
                        options={this.sidebar_options}
                        currentOption={this.active_sidebar_option}
                        onPress={this.setActiveSideBarOption.bind(this)}
                    />

                    {this.active_sidebar_option && this.active_sidebar_option === 'Description' &&
                        <ContentContainer>

                            <div className="main-content">
                                <div className="applicantProfile">

                                    <ProfileImage
                                        image={applicant.logo}
                                        value={applicant.full_name}
                                        name={applicant.full_name}
                                    />

                                </div>

                                <DataRow
                                    title="Name"
                                    value={applicant.full_name}
                                />

                                {applicant.date_of_birth &&
                                    <DataRow
                                        title="Date of Birth"
                                        value={moment(applicant.date_of_birth).calendar()}
                                    />
                                }

                                <DataRow
                                    title="Address"
                                    value={applicant.address}
                                />


                                <DataRowInfo
                                    title="Description"
                                    value={applicant.description}
                                />


                                {applicant.work_history && applicant.work_history.length > 0 &&
                                    <WorkHistoryRow
                                        title="Work History"
                                        value={applicant.work_history}
                                    />
                                }


                                {applicant.skills && applicant.skills.length > 0 &&
                                    <TableRowList
                                        title="Skills"
                                        value={applicant.skills}
                                    />
                                }

                                {applicant.educations && applicant.educations.length > 0 &&
                                    <TableRowList
                                        title="Education"
                                        value={applicant.educations}
                                    />
                                }

                                {applicant.certificates && applicant.certificates.length > 0 &&
                                    <TableRowList
                                        title="Certificates"
                                        value={applicant.certificates}
                                    />
                                }

                                {applicant.referrals &&
                                    applicant.referrals.length > 0 &&
                                    <DataRowInfo
                                        title="Referred By"
                                        value={this.processReferrals(applicant.referrals)}
                                    />
                                }


                            </div>



                        </ContentContainer>

                    }
                </div>

                <ConfirmationModal
                    visible={this.confirmationModal}
                    title={`Are you sure you wish to ${this.status} ${applicant.full_name} for the job?`}
                    text="The applicant will receive a notification about your decision..."
                    declineText="No, cancel"
                    onClose={() => { this.confirmationModal = false }}
                    acceptText={`Yes, ${this.status}`}
                    acceptFunc={this.applicantStatus.bind(this)}
                />

            </div>
        );
    }
}
export default NewEmployeeProfile;
