import React, { Component, useEffect } from 'react';

import { Input, Button } from '../../components';
import GuestStore from '../../stores/GuestStore'
import { Helmet, Child } from "react-helmet";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import MetaTags from 'react-meta-tags';
import DocumentMeta from 'react-document-meta';


@observer
class VerifySuccess extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    openApp() {
        window.location.href = 'pearup://jobs';
    }

    render() {

        return (
            <div className="wrapper wrapper-white">

                <Helmet>
                    <title>Pear Up | Email Verified</title>
                </Helmet>

                <div className="main">
                    <div className="content">
                        <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
                        <div className="login">
                            <a href="/">
                                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
                            </a>
                            <div className="form login-form">
                                <h1 className="verifyCelebratory">All done!<br /><span className="green">Let's get started!</span></h1>

                                <div className="btnContainer pb">
                                    <Button customClass="button mob-bk-btn"
                                        text="Go back to Pear Up" onClick={this.openApp}>
                                    </Button>

                                    <a class="button desk-bk-btn" href="https://pearup-jobs.com/login">
                                        Go back to Pear Up
                                    </a>

                                </div>

                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.pearupjobs">
                                    <img style={{ width: '48%' }} src={require('../../assets/images/store/GooglePlayBtn.png')} className="h-width fl" alt="Pear Up Logo" />
                                </a>
                                <a target="_blank" href="https://apps.apple.com/gb/app/pear-up-jobs/id1485713551">
                                    <img style={{ width: '48%' }} src={require('../../assets/images/store/AppStoreBtn.png')} className="h-width fr" alt="Pear Up Logo" />
                                </a>
                            </div>
                        </div>
                        <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
                    </div>
                </div>
            </div>
        );

    }
}

export default VerifySuccess;





