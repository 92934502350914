import React, { Component } from 'react';
import { Button } from './';

export class PaymentMethod extends Component {

    render() {

        const { brand, last4, exp_month, exp_year, card_id, onDelete, selected, onSelect } = this.props
        const visa = <img src={require('../assets/cards/visa.png')} className="cardImage" alt="Visa Card Icon" resizeMode='contain' />
        const amex = <img src={require('../assets/cards/amex.png')} className="cardImage" alt="Amex Card Icon" resizeMode='contain' />
        const mastercard = <img src={require('../assets/cards/mastercard.png')} className="cardImage" alt="Mastercard Card Icon" resizeMode='contain' />


        return (
            <div className="cardContainer">
                <div className="cardEdit">
                    {brand == 'Visa' &&
                        visa
                    }
                    {brand == 'American Express' &&
                        amex
                    }
                    {brand == 'MasterCard' &&
                        mastercard
                    }
                    <div className="editButton">
                        <Button
                            text="Delete"
                            customClass="button btn-ghost-red"
                            style={{ width: '100%', height: '33px', paddingTop: '6px' }}
                            onClick={() => onDelete(card_id)}
                        />
                    </div>
                </div>

                <div className="cardDetails">
                    <h3 className="brandText">{brand}</h3>
                    <p className="cardDetail">****{last4}</p>
                    <p className="cardDetail">Exp: {exp_month}/{exp_year}</p>
                </div>

                <div className="cardSelected">
                    <Button
                        text={selected ? 'Selected' : 'Select'}
                        customClass={selected ? 'button btn-selected-card' : 'button btn-select-card'}
                        style={{ width: '100%', height: '33px', paddingTop: '6px' }}
                        onClick={() => onSelect({ card_id: card_id })}
                    />
                </div>
            </div>
        );
    }
}
