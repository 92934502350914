import React, { Component } from 'react';

export class MessageUserButton extends Component {

	render() {

		const { onClick, disabled, style = {}, customClass } = this.props

		return (
			<button className={customClass} style={style} onClick={onClick} disabled={disabled}>
				<img src={require('../assets/images/icons/message-user-icon.svg')} alt="share icon" />
			</button>
		);

	}
}
