import React from 'react'

export class JobsRow extends React.Component {

    render() {

        const { job_title, duration, referral, active, applicatedSelected, referralAwarded, company_image, company_name, onClick, job_id } = this.props

        return (
            <div className="jobContainer" onClick={() => {
                if (job_id) {
                    onClick(job_id)
                }
                else {
                    onClick()
                }
            }}>
                <img className="chev-right" src={require('../assets/images/chevron-right.png')} alt="Open Icon" />
                <div className="row">
                    <div style={{ width: '25%', display: 'flex' }}>

                        {company_image &&
                            <img src={company_image} className="companyImage" alt="Company Icon" />
                        }
                        {!company_image &&
                            <img src={require("../assets/images/profile/noProfileImage.png")} alt="company icon" className="companyImage" />
                        }

                    </div>
                    <div style={{ width: '40%' }}>
                        <div className="row">
                            <div className="company_name">
                                <h3>{company_name}</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="job_title">
                                <p>{job_title}</p>
                            </div>
                        </div>

                    </div>

                    <div style={{ width: '25%', paddingLeft: '30px' }}>
                        {active &&
                            <div className="jobDetails">
                                {duration &&
                                    <div className="greenBlock mt-sm">
                                        <p className="btnText">{duration}</p>
                                    </div>
                                }
                                {referral &&
                                    <div className="blackBlock mt-sm">
                                        <p className="btnText">{referral} referral</p>
                                    </div>
                                }
                            </div>
                        }
                        {active == false &&
                            <div className="jobDetails">
                                {applicatedSelected &&
                                    <div className="greenBlock">
                                        <p className="btnText">Applicant Selected</p>
                                    </div>
                                }
                                {!applicatedSelected &&
                                    <div className="redBlock">
                                        <p className="btnText">No Applicant Selected</p>
                                    </div>
                                }

                                {referralAwarded &&
                                    <div className="blackBlock">
                                        <p className="btnText">{referral} Referral rewarded</p>
                                    </div>
                                }
                                {!referralAwarded &&
                                    <div className="blackBlock">
                                        <p className="btnText">No Referral rewarded</p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div >
        )
    }
}