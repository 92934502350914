import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, ApplicantPanel, DataRow, DataRowInfo, Button, ButtonFooter, ConfirmationModal, EmptyState, Breadcrumbs, ShareJobButton, FilterApplicants } from '../../components';

import AuthStore from '../../stores/AuthStore'
import CompanyStore from '../../stores/CompanyStore'
import JobStore from '../../stores/JobStore'
import moment from "moment";
import { isEmpty } from 'lodash'

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

@observer
class ViewJob extends Component {

    @observable active_sidebar_option = 'Description'

    @observable sidebar_options = ['Description', 'Applicants']

    @observable requests = []

    @observable confirmationModal = false


    @observable active_filter_option = 'All'
    @observable filter_options = ['All', 'Referred', 'General']

    @observable requests = []
    @observable requestsReferred = []
    @observable requestsGeneral = []

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }


    setActiveFilterOption = (option) => {
        console.log({ option });
        this.active_filter_option = option
    }

    componentDidMount() {
        JobStore.getPricing();
        if (this.props.match.params) {
            if (this.props.match.params.id) {
                JobStore.getJob(this.props.match.params.id)
                    .then(() => {
                        JobStore.getApplicants(this.props.match.params.id).then(res => {
                        })
                    })
            }
        } else if (this.props.history.location.state) {
            if (this.props.history.location.state.id) {
                JobStore.getJob(this.props.history.location.state.id)
                    .then(() => {
                        JobStore.getApplicants(this.props.history.location.state.id).then(res => {

                        })
                    })
            }
        }
    }

    closeJob = (job_id) => {
        this.confirmationModal = false
        JobStore.closeJob(job_id).then(res => {
            if (res == 200) {
                JobStore.getJob(job_id).then(response => {
                    if (response === 200) {
                        this.props.history.push('/dashboard/jobs')
                    }
                })
            }
        })
            .catch(err => {
                this.confirmationModal = false
            })
    }

    copyToClipboard = () => {
        var URLInput = document.createElement('input'),
            text = 'https://jobs.pearup-jobs.com/jobs/' + JobStore.job.id;

        document.body.appendChild(URLInput);
        URLInput.value = text;
        URLInput.select();
        document.execCommand('copy');
        document.body.removeChild(URLInput);

        ToastsStore.success('Job link has been copied to your clipboard')

    };

    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }

    goToProfile = (job_id, user_id) => {
        JobStore.setApplicant(user_id).then(res => {
            if (!isEmpty(res)) {
                this.props.history.push('/dashboard/jobs/job/' + job_id + '/applicant/' + user_id, {
                    'fromScreen': 'job'
                }
                )
            }
        })
    }

    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    render() {

        let { job, applicants } = JobStore
        let { activeCompany } = CompanyStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/jobs',
                title: 'Jobs'
            },
            {
                link: '/dashboard/jobs/job/' + job.id,
                title: job.name
            }
        ]
        return (
            <div className="page jobs">

                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Jobs"
                    buttonText="Create a job"
                    backButton={true}
                    onClick={() => {
                        this.props.history.goBack()
                    }}
                    buttonClick={() => {
                        JobStore.resetJob()
                        this.props.history.push('/dashboard/job/create')
                    }}
                />
                <div className="page-content">
                    <Sidebar
                        options={this.sidebar_options}
                        currentOption={this.active_sidebar_option}
                        onPress={this.setActiveSideBarOption.bind(this)}
                    />

                    {this.active_sidebar_option && this.active_sidebar_option === 'Description' &&
                        <ContentContainer>
                            <div className="main-content">

                                <h1>Job Details</h1>

                                <DataRow
                                    title="Job Name"
                                    value={job.name}
                                />

                                <DataRow
                                    title="Job Industries"
                                    value={job.industries.map((l, index) => { return (index == 0 ? '' : ' ') + l.name }).toString()}
                                />

                                <DataRow
                                    title="Contract Type"
                                    value={job.contract}
                                />

                                <DataRow
                                    title="Address"
                                    value={job.address}
                                />

                                {job.reward &&
                                    activeCompany.address &&
                                    activeCompany.address.country &&
                                    <DataRow
                                        title="Referral Reward"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}` + job.reward}
                                    />
                                }


                                {job.rate &&
                                    activeCompany.address &&
                                    activeCompany.address.country &&
                                    <DataRow
                                        title="Rate of Pay"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}` + job.rate}
                                    />
                                }


                                <DataRowInfo
                                    title="Job Description"
                                    value={job.description}
                                />

                                {job.duration &&
                                    <DataRow
                                        title="Job Post Length"
                                        value={job.duration === "4_weeks" ? "4 Weeks" : "6 Weeks"}
                                    />
                                }

                                {activeCompany.address &&
                                    activeCompany.address.country &&
                                    job.duration &&
                                    <DataRow
                                        title="Total Cost"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}${job.duration === "4_weeks" ? JobStore.four_week_pricing : JobStore.six_week_pricing}`}
                                    />
                                }



                                {applicants.length > 0 &&
                                    <h3>Successful Applicants</h3>
                                }
                                {applicants.length > 0 &&
                                    applicants.map((l) => {
                                        if (l.application_status === "accepted") {
                                            return <ApplicantPanel
                                                key={l.id}
                                                id={l.id}
                                                job_id={job.id}
                                                image={l.logo}
                                                name={l.full_name}
                                                onPress={this.goToProfile.bind(this)} />
                                        }

                                    })
                                }

                                {job.id && job.paid && job.active == 1 &&
                                    <ButtonFooter style={{ justifyContent: 'space-evenly' }}>
                                        <ShareJobButton
                                            text="Copy to Clipboard"
                                            customClass="button btn-black-share"
                                            onClick={this.copyToClipboard.bind(this)}
                                        />
                                        <Button
                                            text="Close Job"
                                            style={{ width: '45%', float: 'right' }}
                                            customClass="button btn-red"
                                            onClick={() => { this.confirmationModal = true }}
                                        />
                                    </ButtonFooter>
                                }

                                {job.id && !job.paid &&
                                    <ButtonFooter>
                                        <Button
                                            text="Confirm & checkout"
                                            customClass="button"
                                            style={{ width: '45%', float: 'right' }}
                                            onClick={() =>
                                                this.props.history.push('/dashboard/job/pay/' + JobStore.job.id)
                                            }
                                        />
                                    </ButtonFooter>
                                }

                            </div>

                        </ContentContainer>

                    }

                    {this.active_sidebar_option && this.active_sidebar_option === 'Applicants' &&
                        <ContentContainer>
                            <div className="main-content">
                                <h1>Applicants</h1>



                                <FilterApplicants
                                    options={this.filter_options}
                                    currentOption={this.active_filter_option}
                                    onPress={this.setActiveFilterOption.bind(this)}
                                />

                                {this.active_filter_option === 'All' &&
                                    applicants.length > 0 &&
                                    applicants.map((l) => (
                                        <ApplicantPanel
                                            key={l.id}
                                            id={l.id}
                                            job_id={job.id}
                                            image={l.logo}
                                            name={l.full_name}
                                            type={l.referrals.length > 0 ? 'Referred' : 'General'}
                                            onPress={this.goToProfile.bind(this)} />
                                    ))
                                }
                                {this.active_filter_option === 'Referred' &&
                                    applicants.length > 0 &&
                                    applicants.map((l) => (
                                        l.referrals.length > 0 &&
                                        <ApplicantPanel
                                            key={l.id}
                                            id={l.id}
                                            job_id={job.id}
                                            image={l.logo}
                                            name={l.full_name}
                                            type="Referred"
                                            onPress={this.goToProfile.bind(this)} />
                                    ))
                                }

                                {this.active_filter_option === 'General' &&
                                    applicants.length > 0 &&
                                    applicants.map((l) => (
                                        l.referrals.length === 0 &&
                                        <ApplicantPanel
                                            key={l.id}
                                            id={l.id}
                                            job_id={job.id}
                                            image={l.logo}
                                            name={l.full_name}
                                            type="General"
                                            onPress={this.goToProfile.bind(this)} />
                                    ))
                                }

                                {applicants.length < 1 &&
                                    <EmptyState
                                        image={require('../../assets/images/icons/noData.png')}
                                        title="No Applicants Found"
                                        onClick={() => {
                                            this.handleClick()
                                        }}

                                    />
                                }

                            </div>
                        </ContentContainer>
                    }

                </div>


                <ConfirmationModal
                    visible={this.confirmationModal}
                    title={`Are you sure you wish to close this job?`}
                    text="This action cannot be undone..."
                    declineText="No, cancel"
                    onClose={() => { this.confirmationModal = false }}
                    acceptText="Yes, close"
                    acceptFunc={() => this.closeJob(job.id)}
                />
                <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />

            </div>
        );
    }
}
export default ViewJob;
