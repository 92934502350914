import React from 'react';
import medLogo from '../../assets/images/icons/icon-md.png';
import GooglePlayBtn from '../../assets/images/store/GooglePlayBtn.png';
import AppStoreBtn from '../../assets/images/store/AppStoreBtn.png';
import TwitterIcon from '../../assets/images/icons/twitter-sm.png';
import FacebookIcon from '../../assets/images/icons/facebook-sm.png';

const Footer = () => {
    return (
        <div className="homepage-footer">
            <img src={medLogo} className="footerImg" />
            <ul className="footerLinks">
                <a href="/#WhatIsIt">
                    <li className="link">What is it</li>
                </a>
                <a href="/#iOS">
                    <li className="link">Download</li>
                </a>
                <a href="/#aboutUs">
                    <li className="link">About Us</li>
                </a>
                <a href="/login">
                    <li className="link">Sign In</li>
                </a>
                <a href="mailto:info@pearupjobs.com">
                    <li className="link">Contact Us</li>
                </a>
            </ul>
            <div className="appLinks">
                <a href="https://apps.apple.com/gb/app/pear-up/id6450773650" target="_blank">
                    <img src={AppStoreBtn} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.pearupjobs" target="_blank">
                    <img src={GooglePlayBtn} />
                </a>
            </div>
            <hr />
            <div className="footer-bottom">
                <div className="footer-block">
                    <p>&copy; 2023, Pear Up</p>
                </div>
                <ul className="footer-bottom-icons footer-block">

                </ul>
            </div>
            <div className="footer-terms">
            <a href="/terms-and-conditions">
                    Terms and Conditions
                </a>
                <a href="/privacy">
                    Privacy
                </a>
                <a href="/cookie-policy">
                    Cookie Policy
                </a>
            </div>
        </div>
    );
}

export default Footer;