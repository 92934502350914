import React, { Component, useEffect } from 'react';
import { Fade, Zoom, Slide } from 'react-reveal';

import '../../App.scss';
import Header from '../../components/homepage/Header';
import Footer from '../../components/homepage/Footer';
import Landing from '../../components/homepage/Landing';
import IOS from '../../components/homepage/IOS';
import Android from '../../components/homepage/Android';
import WhatIsIt from '../../components/homepage/WhatIsIt';
import AboutUs from '../../components/homepage/AboutUs';
import GetStarted from '../../components/homepage/GetStarted';
import CookieBox from '../../components/homepage/CookieBox';

import Leaf from '../../assets/images/homepage/Leaf.png';
import Doves from '../../assets/images/homepage/Doves.png';
import Cross from '../../assets/images/homepage/landingCross.png';
import Dots from '../../assets/images/homepage/Dots.png';

import "animate.css/animate.min.css";
import { Helmet } from "react-helmet";


class Privacy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cookieBoxShow: null,
            menuShow: false
        }
    }


    componentDidMount() {
        if (localStorage.getItem('cookies') > 0) {
            this.hideCookieBox();
        } else {
            this.setState({
                cookieBoxShow: true
            });
        }
    }

    hideCookieBox = () => {
        this.setState({
            cookieBoxShow: false
        });
        localStorage.setItem('cookies', 1);
    }


    toggleMenu = () => {
        this.setState({
            menuShow: !this.state.menuShow
        })
    }

    closeMenu = () => {
        this.setState({
            menuShow: false
        })
    }

    render() {

        return (


            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <Helmet>
                    <title>Pear Up | Find your next job or employees straight from your phone</title>
                    <meta name="description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="keywords" content="Recruitment, Recruitment app, Part-time job, Job search, Jobs, Jobs near me, Temporary work, Local jobs, Local work, Construction jobs, Retail jobs, Delivery driver jobs, Hourly work, Jobs Northern Ireland" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Dashboard" />
                    <meta property="og:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta property="og:image" content="../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Dashboard" />
                    <meta name="twitter:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="twitter:image" content="../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <Header show={this.state.menuShow} toggle={this.toggleMenu} close={this.closeMenu} />
                <div className="page-cont legal-docs">
                    <h2>Privacy Policy</h2>

                    <h3>CONSENT TO INSTALLATION OF THE APP</h3>
                    <p>Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data.</p>
                    <p>YES I consent to the installation of the App.</p>
                    <p>NO I do not consent to the installation of the App.</p>

                    <h3>HOW YOU CAN WITHDRAW CONSENT</h3>
                    <p>Once you provide consent by selecting "YES", you may change your mind and withdraw consent at any time by contacting us info@pearup-jobs.com but that will not affect the lawfulness of any processing carried out before you withdraw your consent.</p>

                    <h3>CONSENT TO PROCESSING LOCATION DATA</h3>
                    <p>YES I consent to processing of my Location Data (including details of my current location disclosed by GPS technology so that location-enabled Services are activated to assist with the search of nearby jobs and employees).</p>
                    <p>NO I do not consent to processing of my Location Data and location-enabled Services are disabled in my settings.</p>

                    <h3>Location Data</h3>
                    <p>Pear Up Jobs Limited (we) are committed to protecting your personal data and respecting your privacy.</p>

                    <h3>INTRODUCTION</h3>
                    <p>This policy (together with our end-user licence agreement (EULA) and any additional terms of use incorporated by reference into the EULA, together our Terms) applies to your use of:</p>
                    <li>Pear Up mobile application software (App) hosted on [WEB ADDRESS] (App Site), once you have downloaded or streamed a copy of the App onto your mobile telephone or handheld device (Device).</li>
                    <li>Any of the services accessible through the App (Services) that are available on the App Site or other sites of ours (Services Sites), unless the EULA states that a separate privacy policy applies to a particular Service, in which case that privacy policy only applies. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. This App is not intended for children and we do not knowingly collect data relating to children. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</li>

                    <h3>IMPORTANT INFORMATION AND WHO WE ARE</h3>
                    <p>Pear Up Jobs Limited [is the controller and is responsible for your personal data (collectively referred to as "Company", "we", "us" or "our" in this policy).</p>
                    <p>We have appointed a data protection officer (DPO). If you have any questions about this privacy policy, please contact them using the details set out below.</p>

                    <h3>Contact details</h3>
                    <p>Our full details are:</p>
                    <li>Full name of legal entity: Pear Up Jobs Limited</li>
                    <li>Name or title of DPO: Kevin Eves</li>
                    <li>Email address: info@pearupjobs.com</li>
                    <li>Postal address: Aughnahoo Glebe, Pettigo, Co Donegal, F94X771</li>

                    <p>You have the right to make a complaint at any time to the Office of the Information Commissioner (in the Republic of Ireland); or Information Commissioner's Office (in the UK) as the case may be, or other competent supervisory authority of an EU member state if the App is downloaded outside the Republic of Ireland or the UK].</p>


                    <h3>CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES</h3>
                    <p>We keep our privacy policy under regular review.</p>
                    <p>This version was last updated in 2020. It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next start the App or log onto one of the Services Sites. The new policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the App or the Services.</p>
                    <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during our relationship with you.</p>

                    <h3>THIRD PARTY LINKS</h3>
                    <p>Our Sites may, from time to time, contain links to and from the websites of our partner networks, and affiliates which include recruiters and employers seeking prospective employees. Please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as Contact and Location Data. Please check these policies before you submit any personal data to these websites or use these services.</p>


                    <h3>THE DATA WE COLLECT ABOUT YOU</h3>
                    <li>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
                    <li>Contact Data includes billing address, delivery address, email address and telephone numbers.</li>
                    <li>Financial Data includes bank account and payment card details. Pear Up Jobs Limited does not store your payment information other than the last four digits of your credit card or bank account (as applicable), expiration date and country</li>
                    <li>ransaction Data includes details about payments to and from you and other details of services you have purchased from us.</li>
                    <li>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website. </li>
                    <li>Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
                    <li>Usage Data includes information about how you use our website and services. </li>
                    <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                    <li>Communications you send to us for example when you ask for support, send us questions or comments or report a problem.</li>

                    <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific App feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</p>
                    <p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>


                    <h3>HOW IS YOUR PERSONAL DATA COLLECTED?</h3>
                    <p>We will collect and process the following data about you:</p>
                    <p>Direct interactions. You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>
                    <li>create an account on our app;</li>
                    <li>apply for a job through the app;</li>
                    <li>subscribe to our service or publications; </li>
                    <li>request marketing to be sent to you;</li>
                    <li>enter a competition, promotion or survey; or</li>
                    <li>give us some feedback. </li>

                    <br></br>
                    <li>Information you give us. This is information (including Identity, Contact and Marketing and Communications Data) you consent to giving us about you by filling in forms on the App Site and the Services Sites (together Our Sites), or by corresponding with us (for example, by email or chat). It includes information you provide when you register to use the App Site, download or register an App, subscribe to any of our Services, search for an App or Service, share data via an App's social media functions, apply for a job through the App and when you report a problem with an App, our Services, or any of Our Sites. If you contact us, we will keep a record of that correspondence.</li>
                    <li>Information we collect about you and your device. Each time you visit one of Our Sites or use one of our Apps we will automatically collect personal data including Device, Content and Usage Data. </li>
                    <li>Location Data. We also use GPS technology to determine your current location. Some of our location-enabled Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. </li>

                    <h3>HOW WE USE YOUR PERSONAL DATA</h3>
                    <p>We will only use your personal data when the law allows us to do so. Most commonly we will use your personal data in the following circumstances:</p>
                    <li>Where you have consented before the processing.</li>
                    <li>Where we need to perform a contract we are about to enter or have entered with you.</li>
                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                    <li>Where we need to comply with a legal or regulatory obligation.</li>
                    <p>We will only send you direct marketing communications by email or text if we have your consent. You have the right to withdraw that consent at any time by contacting us.</p>
                    <p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</p>

                    <h3>INTERNATIONAL TRANSFERS</h3>
                    <p>We do not transfer your personal data outside the European Economic Area (EEA).</p>

                    <h3>DATA SECURITY</h3>
                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. </p>
                    <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

                    <h3>DATA RETENTION</h3>
                    <p>By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for tax purposes.</p>

                    <p>In some circumstances you can ask us to delete your data: see Your legal rights below for further information.</p>
                    <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
                    <p>In the event that you do not use the App for a period of 36 months then we will treat the account as expired and your personal data may be deleted.</p>

                    <h3>YOUR LEGAL RIGHTS</h3>
                    <p>Under certain circumstances you have the following rights under data protection laws in relation to your personal data.</p>

                    <p>You have the right to:</p>

                    <li>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                    <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                    <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request. </li>
                    <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                    <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. </li>
                    <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. </li>
                    <li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent. You also have the right to ask us not to continue to process your personal data for marketing purposes.</li>
                    <li>You can exercise any of these rights at any time by contacting us at Pear Up Jobs Limited, Aughnahoo Glebe, Pettigo, Co Donegal.</li>

                    <h3>GLOSSARY</h3>
                    <p>Lawful basis</p>
                    <p>Consent means processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us.</p>
                    <p>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
                    <p>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
                    <p>Comply with a legal obligation means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>



                </div>
                <CookieBox display={this.state.cookieBoxShow} hide={this.hideCookieBox} />
                <Footer />
            </div >
        );

    }
}

const styles = {

}

export default Privacy;





