import React, { Component } from 'react';
import { Button } from '../components';
export class ErrorState extends Component {

    render() {

        const { title, message, subtitle, button, buttonText, onClick } = this.props

        return (
            <div className="errorState">
                <h2>{title}</h2>
                <h3>{subtitle}</h3>
                <p>{message}</p>

                {button &&
                    <Button
                        children={buttonText}
                        onClick={onClick}
                    />
                }
            </div>
        )
    }
}

