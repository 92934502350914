import React, { Component } from 'react';

export class ProceedButton extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(value) {
    }

    render() {

        const { nextButtonText, cancelButtonText } = this.props

        return (
            <div className="proceedForm">
                <button className="button btn-cancel" onClick={this.submitForm}>
                    <span>{cancelButtonText}</span>
                </button>

                <button className="button btn-next btn-green" onClick={this.submitForm}>
                    <span>{nextButtonText}</span>
                </button>

            </div>
        );
    }
}
