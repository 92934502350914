import React, { Component, useEffect } from 'react';
import { Fade, Zoom, Slide } from 'react-reveal';

import '../../App.scss';
import Header from '../../components/homepage/Header';
import Footer from '../../components/homepage/Footer';
import Landing from '../../components/homepage/Landing';
import IOS from '../../components/homepage/IOS';
import Android from '../../components/homepage/Android';
import WhatIsIt from '../../components/homepage/WhatIsIt';
import AboutUs from '../../components/homepage/AboutUs';
import GetStarted from '../../components/homepage/GetStarted';
import CookieBox from '../../components/homepage/CookieBox';

import Leaf from '../../assets/images/homepage/Leaf.png';
import Doves from '../../assets/images/homepage/Doves.png';
import Cross from '../../assets/images/homepage/landingCross.png';
import Dots from '../../assets/images/homepage/Dots.png';

import "animate.css/animate.min.css";
import { Helmet } from "react-helmet";


class Terms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cookieBoxShow: null,
            menuShow: false
        }
    }


    componentDidMount() {
        if (localStorage.getItem('cookies') > 0) {
            this.hideCookieBox();
        } else {
            this.setState({
                cookieBoxShow: true
            });
        }
    }

    hideCookieBox = () => {
        this.setState({
            cookieBoxShow: false
        });
        localStorage.setItem('cookies', 1);
    }

    toggleMenu = () => {
        this.setState({
            menuShow: !this.state.menuShow
        })
    }

    closeMenu = () => {
        this.setState({
            menuShow: false
        })
    }

    render() {

        return (


            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <Helmet>
                    <title>Pear Up | Find your next job or employees straight from your phone</title>
                    <meta name="description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="keywords" content="Recruitment, Recruitment app, Part-time job, Job search, Jobs, Jobs near me, Temporary work, Local jobs, Local work, Construction jobs, Retail jobs, Delivery driver jobs, Hourly work, Jobs Northern Ireland" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Dashboard" />
                    <meta property="og:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta property="og:image" content="../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Dashboard" />
                    <meta name="twitter:description" content="Take your job search into your own hands and secure your next role with Pear Up, the ultimate recruitment platform for hourly workers. Find out more now." />
                    <meta name="twitter:image" content="../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <Header show={this.state.menuShow} toggle={this.toggleMenu} close={this.closeMenu} />
                <div className="legal-docs">
                    <h2>JOBSEEKER MOBILE APP TERMS AND CONDITIONS</h2>
                    <h4>BY USING THE APP YOU ACCEPT THESE TERMS</h4>

                    <h4>BY USING THE APP YOU CONFIRM THAT YOU ACCEPT THESE JOBSEEKER TERMS AND THAT YOU AGREE TO COMPLY WITH THEM.</h4>

                    <h4>IF YOU DO NOT AGREE TO THESE JOBSEEKER TERMS, YOU MUST NOT USE THE APP.</h4>

                    <p>This mobile application and website (App) is made available by Pear Up Jobs Limited, a company registered in Ireland with number 667612 and with registered address Aughnahoo Glebe, Pettigo, Co Donegal (Pear Up, us, we or our).</p>

                    <p>You, the user of the App, confirm your acceptance of these App terms of use (Jobseeker Terms). If you do not agree to these Jobseeker Terms, you must immediately uninstall the App and discontinue its use.  These Jobseeker Terms should be read alongside our privacy policy and end user license agreement.</p>

                    <p>These Jobseeker Terms set out the information applicable to jobseekers (Jobseekers) who wish to use the App and apply to the contractual agreement between us and you on the other hand, who are interested in or use any career service that is offered on the App.</p>

                    <h4>General Terms </h4>
                    <p><b>App and Related Terms</b></p>
                    <p>Depending on the version of the App you have downloaded, these Jobseeker Terms incorporate Apple’s or Google Android’s terms and conditions and privacy policies (Platform Terms). If there is any conflict between these Jobseeker Terms and the Platform Terms then these Jobseeker Terms will prevail.</p>

                    <p><b>Use of the App</b></p>
                    <p>You must be at least 16 years of age and resident in either the Republic of Ireland or the UK to use the App.</p>
                    <p>Pear Up hereby grants you a non-exclusive, non-transferable, revocable licence to use the App for your personal, non-commercial use and only on an Apple or Android device (Device) as permitted by the applicable Platform Terms and in accordance with these Jobseeker Terms (User Licence).  All other rights in the App are reserved by Pear Up.</p>

                    <p>In the event of your breach of these Jobseeker Terms we will be entitled to terminate the User Licence immediately.</p>

                    <p>You acknowledge that your agreement with your mobile network provider (Mobile Provider) will apply to your use of the App. You acknowledge that you may be charged by the Mobile Provider for data services while using certain features of the App or any such third party charges as may arise and you accept responsibility for such charges. If you are not the bill payer for the Device being used to access the App, you will be assumed to have received permission from the bill payer for using the App.</p>

                    <p>You acknowledge that where you use services provided by Apple or Google (or any other third parties) in connection with your use of the App, you will be subject to Apple’s, Google’s (or the applicable third party’s) terms and conditions and privacy policy and you should ensure that you have read such terms.</p>

                    <p>Jobseekers are aware that we do not introduce or supply Jobseekers to Employers (or vice versa). The App acts merely as a venue and this means that we do not:</p>
                    <ol>
                        <li>obtain sufficient information for Employees to select a suitable jobseeker for the position which the Employer seeks to fill;</li>
                        <li>obtain confirmation of the identity of a jobseeker or that they have the experience, training, qualifications or authorization to work in the position to be filled or that they wish to undertake the role to be filled;</li>
                        <li>take any steps to ensure the jobseeker and Employer are each aware of any requirement imposed by law or otherwise which must be satisfied by either of them to permit the jobseeker to fulfil the position to be filled;</li>
                        <li>give any indication to employers whether jobseekers are unsuitable (or suitable) or correctly qualified for any position to be filled in any circumstances;</li>
                        <li>propose jobseekers to employers or provide any information about them.</li>
                        <li>take up any references in relation to a jobseeker; or,</li>
                        <li>make any arrangements for accommodation of jobseekers.</li>
                    </ol>

                    <p>Since we are only a venue and do not propose or introduce jobseekers to Employers or vice versa, it is recommended that, if you are a jobseeker you undertake to ensure your suitability for the role advertised or, if you are an Employer, to ensure a jobseeker's suitability for the role, we shall have no liability under any applicable laws, rules or legislation (Regulations) that seek to ensure that jobseekers are only proposed by employment agencies for roles for which they are properly qualified and that Employers are only offered jobseekers who have the appropriate levels of experience, training, qualifications and authorisation for the position to be filled. </p>

                    <p>The information available on the App is provided for the sole purpose of individuals looking for employment opportunities in a specific geographical location and for employers seeking to recruit staff. You may use and download information from the site for these purposes only and for no other personal or commercial purpose. You may not otherwise copy, display, transmit or distribute any material from the site and if you do or if you perform any other unauthorised processing of information on the site it shall be deemed a material breach of these terms and conditions which shall entitle us to terminate the Services immediately.</p>

                    <p>Further, we reserve the right to suspend provision of the Services to you in circumstances where we reasonably believe that you have performed any unauthorised processing of information. In respect of any personal data accessed or otherwise processed by a Customer as a result of access to the site or the Services, the Customer agrees that it shall be the sole data controller of such personal data for the purposes of the Data Protection Act 2018 and, as such, shall be solely responsible for its processing.</p>
                    <p>All copyright, database rights and other intellectual property rights in the site and the material available on the site belongs to us or its third party suppliers. Use of the site does not give you any proprietary rights in such materials.</p>


                    <h4>Object of the agreement and scope of the services</h4>

                    <p>Without creating an account you may use all browse the app, including jobs that are published on the App, however you will not be able to apply for a job without creating an account.</p>

                    <p>You can create a CV-profile (Profile) within your account, which will consist of various CV related pieces of information as well as attachments provided by you. It will also keep a record of your activity on the App.</p>

                    <p>You can use your Profile to apply to job offers that are published on the App. The respective recruiting company will then have access to your Profile even if it is not generally available and will be able to see the data you provided. We will also use the data you have provided as part of your Profile to provide you with job ad recommendations tailored to your needs based on your Profile data. To provide you with the specific Services under the Profile, we will store the date of your last activity on our  Platforms (Last Active date), which will be  the date you most recently updated your profile or CV or the date you last applied for jobs using the Services.</p>

                    <p>As part of an active and public Profile we may also provide a link to a profile about you on a publicly available business related social media website we have found about you to CV Database search users. Such access to CV Database may also be provided through browser plugins or other software that we or our group companies offer and which will allow our customers to access your CV if they visit certain social media websites.</p>

                    <p>If you choose to create a Profile with us, then we will keep your CV on file until you ask us to delete your account from our Database.</p>


                    <h4>Data Protection</h4>
                    <p>Our use of CVs and other personal information supplied by you is governed by our privacy policy. </p>

                    <h4>Prohibited Uses</h4>
                    <p>You agree not to use the App in any way that:</p>
                    <ol>
                        <li>is unlawful, illegal or unauthorised;</li>
                        <li>is defamatory of any other person;</li>
                        <li>is obscene or offensive;</li>
                        <li>promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                        <li>infringes any copyright, database right or trade mark of any other person;</li>
                        <li>is likely to harass, upset, embarrass, alarm or annoy any other person;</li>
                        <li>is likely to disrupt our service in any way; or</li>
                        <li>advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
                    </ol>

                    <h4>Conduct of Claims</h4>
                    <p>You agree to indemnify Pear Up for any breach by a Jobseeker of these Jobseeker Terms. Pear Up reserves the right to control the defence and settlement of any third party claim for which you indemnify Pear Up under these Jobseeker Terms and you will assist us in exercising such rights.</p>

                    <h4>Pear Up’s Liability</h4>
                    <p>Nothing in these Jobseeker Terms shall exclude or in any way limit Pear Up’s liability for death or personal injury caused by its negligence or for fraud or any other liability to the extent the same may not be excluded or limited as a matter of law.</p>
                    <p>To the fullest extent permitted under applicable law, in no event shall Pear Up be liable to you with respect to use of the App and/or be liable to you for any direct, indirect, special or consequential damages including, without limitation, damages for loss of goodwill, lost profits, or loss, theft or corruption of your information, the inability to use the App, Device failure or malfunction.</p>
                    <p>Pear Up shall not be liable even if it has been advised of the possibility of such damages, including without limitation damages caused by error, omission, interruption, defect, failure of performance, unauthorised use, delay in operation or transmission, line failure, computer virus, worm, Trojan horse or other harm.</p>


                    <h4>Deceased User</h4>
                    <p>In the event of the death of user, a person authorised to act on behalf of the estate, or a verified immediate family member of the deceased can request to have the deceased user’s account removed.  We will require additional documentation for this request in order to avoid unauthorised and false reports. Be assured that the requested information will remain confidential and will be deleted once we have reviewed it.</p>

                    <h4>Changes to Jobseeker Terms</h4>
                    <p>We amend these Jobseeker Terms from time to time. You will be notified upon using the App if there have been changes to the Jobseeker Terms and you will need to accept the new terms in order to use the App.</p>

                    <h4>Severance</h4>
                    <p>If any provision (or part of a provision) of these Jobseeker Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>

                    <h4>Jurisdiction</h4>
                    <p>These Jobseeker Terms shall be governed by Irish Law and the parties submit to the exclusive jurisdiction of the courts of Ireland to resolve any dispute between them arising under or in connection with these Jobseeker Terms.</p>

                    <h4>General</h4>
                    <p>Pear Up provides the App on an ‘as is’ and ‘as available’ basis without any promises or representations, express or implied. In particular, Pear Up does not warrant or make any representation regarding the validity, accuracy, reliability or availability of the App or its content.</p>

                    <h4>Contact Us</h4>
                    <p>If you have any questions regarding our App, you can email us info@pearup-jobs.com</p>





                    <h2>EMPLOYER MOBILE APP TERMS AND CONDITIONS</h2>
                    <h4>BY USING THE APP YOU ACCEPT THESE TERMS</h4>

                    <h4>BY USING THE APP YOU CONFIRM THAT YOU ACCEPT THESE EMPLOYER TERMS AND THAT YOU AGREE TO COMPLY WITH THEM.</h4>

                    <h4>IF YOU DO NOT AGREE TO THESE EMPLOYER TERMS, YOU MUST NOT USE THE APP.</h4>

                    <p>This mobile application and website (App) are made available by Pear Up Jobs Limited, a company registered in Ireland with number 667612 and with registered address Aughnahoo Glebe, Pettigo, Co Donegal (Pear Up, us, we or our).</p>
                    <p>You, the user of the App, confirm your acceptance of these App terms of use (Employer Terms). If you do not agree to these Employer Terms, you must immediately uninstall the App and discontinue its use.  These Employer Terms should be read alongside our privacy policy and end user license agreement.</p>
                    <p>These Employer Terms set out the information applicable to prospective employers or recruiters (Employer) who wish to use the App and apply to the contractual agreement (Agreement) between us and you on the other hand, who are interested in or use any career service that is offered on the App.</p>

                    <h4>Other Terms That May Apply to You</h4>
                    <p>These Employer Terms refers to the following additional terms, which also apply to your use of the App:</p>
                    <p>(a)  Our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using the App, you consent to such processing and you warrant that all data provided by you is accurate.</p>

                    <h4>Amendments to the Employer Terms</h4>
                    <p>We amend these Employer Terms from time to time. Every time you wish to use the App, please check these Employer Terms to ensure you understand the terms that apply at that time. </p>

                    <h4>Definitions</h4>
                    <p>In these Employer Terms the following words shall have the following meanings:</p>
                    <p>Candidate Database means the database of Jobseekers which is accessible to Employer via the App who enter into a Contract;</p>
                    <p>Contract means the contract between us and the Customer for the provision of Services governed by these Employer Terms and the documents referred to in them;</p>
                    <p>Customer means any person, company, organisation or firm which purchases Services from us;</p>
                    <p>Order Confirmation means an email from Us to you accepting your Advert;</p>
                    <p>Services means the publication of your job advertisement on the App by Us from time to time; and</p>
                    <p>Start Date means the date of commencement of the Services.</p>

                    <h4>Methods of contracting</h4>
                    <p>The methods of contracting with us for the supply of Services is via the App. When posting a job advertisement (Advert), all relevant detail must be completed. </p>

                    <h4>Basis of contract</h4>
                    <p>The contract between us and you in respect of the Services (Contract) shall comprise the Advert and these Employer Terms to the exclusion of all other terms and conditions (including any terms or conditions which the Employer purports to apply under any purchase order, confirmation of order or other document). In the event of a conflict in terms between any other document and these Employer Terms, the documents shall rank in the following order of precedence: (i) Advert, (ii) these Employer Terms, and any other document.</p>
                    <p>Please check your Advert carefully before confirming it and posting it to the App. You are responsible for ensuring that your Advert is complete and accurate.</p>
                    <p>If we are unable to supply you with the Services for any reason, we will inform you of this by email and we will not process your order.  The Contract is formed once Stripe payment has been made by you.</p>

                    <h4>Our services</h4>
                    <p>After the Contract is formed we will provide you with the Services from the Start Date.</p>
                    <p>Any descriptions or illustrations on the App are published for the sole purpose of giving an approximate idea of the Services described in them. They will not form part of the Contract or have any contractual force.</p>
                    <p>Subject to our right to amend the specification we will supply the Services to you in accordance with the specification for the Services appearing on the App at the date of your order in all material respects.</p>
                    <p>We reserve the right to amend the specification of the Services if required by any applicable statutory or regulatory requirement or if the amendment will not materially affect the nature or quality of the Services.</p>
                    <p>We warrant to you that the Services will be provided using reasonable care and skill.</p>
                    <p>We will use all reasonable endeavours to meet any performance dates specified in the Order Confirmation, but any such dates are estimates only and failure to perform the Services by such dates will not give you the right to terminate the Contract.</p>
                    <p>Employers need to be aware that we do not introduce or supply jobseekers to Employers (or vice versa). The App acts merely as a venue and this means that we do not:</p>
                    <li>obtain sufficient information for Employers to select a suitable jobseeker for the position which the Employers seeks to fill;</li>
                    <li>obtain confirmation of the identity of a jobseeker or that they have the experience, training, qualifications or authorisation to work in the position to be filled or that they wish to undertake the role to be filled;</li>
                    <li>take any steps to ensure the jobseeker and Employer are each aware of any requirement imposed by law or otherwise which must be satisfied by either of them to permit the jobseeker to fulfil the position to be filled;</li>
                    <li>give any indication to Employers whether jobseekers are unsuitable (or suitable) or correctly qualified for any position to be filled in any circumstances;</li>
                    <li>propose jobseekers to Employers or provide any information about them.</li>
                    <li>take up any references in relation to a jobseeker; or,</li>
                    <li>make any arrangements for accommodation of jobseekers.</li>
                    <p>Since we are only a venue and do not propose or introduce jobseekers to Employers or vice versa, it is recommended that, if you are a jobseeker you undertake to ensure your suitability for the role advertised or, if you are an Employer, to ensure a jobseeker's suitability for the role, we shall have no liability under any applicable laws, rules or legislation (Regulations) that seek to ensure that jobseekers are only proposed by employment agencies for roles for which they are properly qualified and that Employers are only offered jobseekers who have the appropriate levels of experience, training, qualifications and authorisation for the position to be filled.</p>



                    <h4>Your obligations</h4>
                    <p>Your obligations</p>

                    <ol>
                        <li>the Advert is complete and accurate;</li>
                        <li>you co-operate with us in all matters relating to the Services;</li>
                        <li>you provide us with such information and materials we may reasonably require in order to supply the Services, and ensure that such information is complete and accurate in all material respects; and</li>
                        <li>you comply with all applicable laws.</li>
                    </ol>

                    <p>If our ability to perform the Services is prevented or delayed by any failure by you to fulfil any obligation (Your Default):</p>

                    <ol>
                        <li>we will be entitled to suspend performance of the Services until you remedy Your Default, and to rely on Your Default to relieve us from the performance of the Services, in each case to the extent Your Default prevents or delays performance of the Services. In certain circumstances Your Default may entitle us to terminate the contract under the termination provisions of the Employer Terms;</li>
                        <li>we will not be responsible for any costs or losses you sustain or incur arising directly or indirectly from our failure or delay to perform the Services; and</li>
                        <li>it will be your responsibility to reimburse us on written demand for any costs or losses we sustain or incur arising directly or indirectly from Your Default.</li>
                    </ol>


                    <h4>Charges</h4>
                    <p>In consideration of us providing the Services you must pay our charges (Charges) in accordance with this Charges section.</p>
                    <p>The Charges are the prices quoted to you on the App from time to time.</p>
                    <p>Our Charges are exclusive of VAT. Where VAT is payable in respect of some or all of the Services you must pay us such additional amounts, at the applicable rate, at the same time as you pay the Charges.</p>
                    <p>Where a Customer posts an Advert on the App which includes any reference to a brand of a client or a subsidiary of the Customer (which reference may include, without limitation, such client's or subsidiary's logo, http address, email address and telephone number) (Client Branded Advertising), and the posting of such Client Branded Advertising has not been agreed by us, the offending content will be removed.</p>
                    <p>An Advert posted on the App will remain live as agreed at the time you post the Advert. Any extension of this time will be charged to the Customer as a new posting. A single Advert is identified by its unique ID number. If a Customer deletes an Advert and then either reposts it or posts another, this will be classed as two Adverts.</p>


                    <h4>How to Pay and Credit Terms</h4>
                    <p>Payment for the Services is by Stripe and will be taken prior to posting any Advert on the App.</p>
                    <p>Stripe will send you an electronic receipt and invoice after payment  has been made via Stripe. The invoice will be sent to the email address you provide as your accounts payable contact or as otherwise agreed.</p>

                    <h4>Job Advertising</h4>
                    <p>Adverts will go live within 24 hours after payment confirmation on the App and You will be notified by email when your job adverts have gone live on the App.</p>
                    <p>For first time Customers, we need to carry out some security checks before jobs go live. If it is not possible to verify this from the information provided, we will contact Customers to request further information. This may delay the time it takes for jobs to go live.</p>
                    <p>We have rules regarding the content and format of jobs posted on the App. Their purpose is to ensure that users who search the App get results which are presented as clearly and informatively as possible. You agree that We may, at Our discretion and without liability to you, remove from the App any Advert which is posted in breach of these rules:</p>

                    <ol>
                        <li>No duplicating of jobs at the expense of other Employers jobs.</li>
                        <li>No gratuitous use of keywords in job descriptions or job titles. This means deliberately inserting words, strings of words, or repeating job titles, with the intention of influencing position in the results listing, or increasing the number of page views.</li>
                        <li>Adverts placed on the App must be for genuine vacancies only, and not for other products or services, either relating to or unrelated to recruitment including, but not limited to, affiliate schemes, pyramid selling schemes or any other so called 'business opportunity'.</li>
                        <li>Contact telephone numbers, URLs or email links (live or text only) are not permitted in the body copy of the job description page. Email links are permitted from the 'send an email' link and url linking is permitted from the 'apply online' link.</li>
                        <li>Any advertising that uses the name or logo of the company being recruited for is subject to Our rates for such advertising as detailed above.</li>
                        <li>Adverts which appear to discriminate on grounds of sex, race or disability are illegal and may result in proceedings being taken against both the Customer and the Employer. Adverts are accepted by the App on the basis that the Customer confirms that any requirement or qualification which may appear to discriminate illegally is in compliance with any exemption available under the relevant legislation. Notwithstanding this confirmation, if We nonetheless believe that an Advert may be discriminatory We may at Our discretion either amend the Advert or remove it from the App without liability to you to make any refund of amounts paid or due to be paid in respect of the posting or otherwise and will inform you accordingly.</li>
                    </ol>

                    <h4>Responses to Adverts</h4>
                    <p>You agree to deal fairly and professionally with individuals who may respond to an Advert you have posted and not do anything which may bring us or the App into disrepute. You will indemnify us from and against any claim brought by an individual against the App arising from your breach of this obligation or any other term of these Employer Terms.</p>
                    <p>We do not guarantee any response to your advertisement or that responses will be from individuals suitable for the job advertised. It is your responsibility to carry out such checks and procedures as are necessary to ensure that Jobseekers are suitable for the job advertised and have the required qualifications and personal characteristics.</p>
                    <p>As part of our commitment to getting customers the best possible response, we reserve the right to amend some of the job details that you supply in order to optimise your Advert.</p>


                    <h4>Use of Candidate Database</h4>
                    <p>You may use the Candidate Database for the purpose of finding suitable Candidates for specific job vacancies that you wish to fill. You may contact Candidates only for the purpose of filling a specific job vacancy and may not use the information in connection with other activities.</p>
                    <p>You may not:</p>
                    <ol>
                        <li>supply, sell or license material from the Candidate Database, or a copy of it, to any other person, including another member of your group of companies.</li>
                        <li>download, search or view the Candidate Database through any automated (e.g. ‘scraping’) process.</li>
                        <li>contact the individuals on the Candidate Database or make or allow any use of the information about those individuals, other than for the purpose of finding suitable Jobseekers for specific job vacancies. You must, at all times, comply with your obligations under the Privacy Policy.</li>
                        <li>access our Candidate Database from a system that is not fully patched or does not have industry standard AntiVirus software installed that is regularly updated.</li>
                    </ol>


                    <h4>Application Data</h4>
                    <p>When a Jobseeker submits a response to an Advert, details of their application will be loaded to your account. Your account allows you to record notes against the application and track its progress (together with the details of their application, Application Data).</p>
                    <p>You acknowledge that we may delete such Application Data without notice to you in line with our general policies on retention and deletion of data relating to Jobseekers, including where the Jobseeker to whom the Application Data relates decides to delete his profile.</p>
                    <p>As a controller of Application Data, we will comply with our responsibilities under the General Data Protection Regulation (GDPR) in respect of such notes, which may include disclosure of Application Data to the Jobseeker if the Jobseeker submits a request to access his data.</p>
                    <p>For the purposes of the General Data Protection Regulation you are a joint controller of Application Data held on your account. You agree you will be responsible for ensuring that any Application Data which you enter is accurate, deleting Application Data when you no longer require it, and responding in a timely fashion to any request from a Jobseeker seeking to exercise his rights under the General Data Protection Regulation, including requests which we receive and pass to you because we are not able to assess them, for example where a Jobseeker asks for inaccurate data to be corrected.</p>


                    <h4>Dealings with Jobseekers</h4>
                    <p>You agree to deal fairly and professionally with Jobseekers you may contact using information from the Candidate Database and not do anything which may bring us or the App into disrepute. You will indemnify us from and against any claim brought by an individual against us arising from your breach of this obligation or any other of these Employer Terms.</p>

                    <h4>Termination</h4>
                    <p>Without limiting any of Our other rights, we may suspend the performance of the Services, or terminate the Contract with immediate effect by giving written notice to you if:</p>
                    <ol>
                        <li>you commit a material breach of any term of the Contract and (if such a breach is remediable) fail to remedy that breach within 5 days of you being notified in writing to do so;</li>
                        <li>you fail to pay any amount due under the Contract on the due date for payment;</li>
                        <li>you take any step or action in connection with you entering administration, provisional liquidation or any composition or arrangement with your creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of your assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction;</li>
                        <li>you suspend, threaten to suspend, cease or threaten to cease to carry on all or a substantial part of your business; or</li>
                        <li>your financial position deteriorates to such an extent that in Our opinion your capability to adequately fulfil your obligations under the Contract has been placed in jeopardy.</li>
                    </ol>
                    <p>Termination of the Contract will not affect your or Our rights and remedies that have accrued as at termination.</p>
                    <p>Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination will remain in full force and effect.</p>


                    <h4>Assignment</h4>
                    <p>We may assign or subcontract any or all of our rights and obligations under this Agreement to a member of our Group of Companies. If we exercise our right to subcontract any or all of our obligations under this Agreement to a member of our Group, we will immediately resume the performance of such obligations on such company ceasing to exist.</p>

                    <h4>Events Outside Our Control</h4>
                    <p>We will not be liable or responsible for any failure to perform, or delay in performance of, any of Our obligations under the Contract that is caused by any act or event beyond Our reasonable control (an Event Outside Our Control).</p>
                    <p>If an Event Outside Our Control takes place that affects the performance of Our obligations under the Contract:</p>
                    <ol>
                        <li>We will contact you as soon as reasonably possible to notify you; and</li>
                        <li>Our obligations under the Contract will be suspended and the time for performance of Our obligations will be extended for the duration of the Event Outside Our Control. We will arrange a new date for performance of the Services with you after the Event Outside Our Control is over.</li>
                    </ol>

                    <p>You may cancel the Contract affected by an Event Outside Our Control which has continued for more than 30 days. To cancel please contact Us. If you opt to cancel We will refund the price you have paid, less the Charges reasonably and actually incurred by Us in performing the Services up to the date of the occurrence of the Event Outside Our Control.</p>


                    <h4>License to Use Customer’s Name Trademarks and Logos</h4>
                    <p>The Customer grants to us a fully paid-up, worldwide, non-exclusive, royalty-free licence during the term of the Contract to use the Customer's name, trademarks and logos (Customer IP) for the purposes of providing the Services and in our marketing materials (including, but no limited to, websites and brochures) and that we may present examples of the services provided to and the materials published on behalf of Customer.</p>


                    <h4>Limitation of Liability</h4>
                    <p>Nothing in the Contract limits or excludes our liability for:</p>
                    <ol>
                        <li>death or personal injury caused by our negligence, or the negligence of our employees, agents or subcontractors;</li>
                        <li>fraud or fraudulent misrepresentation; or</li>
                        <li>breach of any of the terms implied by s12 of the Sale of Goods Act 1893 and any of the terms implied by the Sale of Goods Act 1893 and the Sale of Goods and Supply of Services Act 1980 that cannot be limited or excluded by law or any other liability which cannot be limited or excluded by applicable law.</li>
                    </ol>

                    <p>We will not be liable to you, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with the Contract for:</p>

                    <ol>
                        <li>loss of profits;</li>
                        <li>loss of sales or business;</li>
                        <li>loss of agreements or contracts;</li>
                        <li>loss of anticipated savings;</li>
                        <li>loss of use or corruption of software, data or information;</li>
                        <li>loss of or damage to goodwill; and</li>
                        <li>any indirect or consequential loss.</li>
                    </ol>

                    <p>Our total liability to you arising under or in connection with the Contract, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, will be limited of the total Charges paid under the Contract.</p>
                    <p>Except as expressly stated in these Employer Terms, we do not give any representations, warranties or undertakings in relation to the Services. Any representation, condition or warranty which might be implied or incorporated into these Terms by statute, including without limitation the terms implied by the Sale of Goods Act 1893 and the Sale of Goods and Supply of Services Act 1980, by common law or otherwise are, to the fullest extent permitted by law, excluded from the Contract.</p>


                    <h4>Jurisdiction</h4>
                    <p>These Employer Terms shall be governed by Irish Law and the parties submit to the exclusive jurisdiction of the courts of Ireland to resolve any dispute between them arising under or in connection with these Employer Terms.</p>

                    <h4>Contact Us</h4>
                    <p>If you have any questions regarding our App, you can email us info@pearup-jobs</p>

                </div>
                <CookieBox display={this.state.cookieBoxShow} hide={this.hideCookieBox} />
                <Footer />
            </div >
        );

    }
}

export default Terms;





