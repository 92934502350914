import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Button } from '../../components';
import CompanyStore from '../../stores/CompanyStore'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Helmet } from "react-helmet";

@observer
class Login extends Component {

  @observable company = {
    email: '',
    password: '',
  }


  @observable password = '';
  @observable loading = false
  @observable valid = false

  constructor(props) {
    super(props);
    this.updateProperty = this.updateProperty.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  updateProperty(key, value) {
    this.company[key] = value
  }

  handleChange(event) {
    this.updateProperty(event.target.name, event.target.value)
  }

  submitForm = () => {
    CompanyStore.login(this.company).then(res => {
      if (res) {
        if (res.data) {
          if (res.data.user_type) {
            if (res.data.user_type == 'company') {
              this.props.history.push('/dashboard');
            } else {
              ToastsStore.error("Please ensure you're logging in as a company.")
            }
          }
        }
        else {
          if (res.message) {
            if (res.message.message === 'Please verify your email before you use Pear Up') {
              this.props.history.push('/login/verify', {
                user_key: res.message.user_key
              })
            }
            else {
              this.valid = res.message
              ToastsStore.error(res.message)
            }
          }
          else {
            this.valid = res
          }
        }
      }

    })
  }

  render() {
    let { errors } = CompanyStore
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Pear Up | Login</title>
          <meta name="description" content="Find your next job or employees straight from your phone…" />
          <meta name="keywords" content="Find your next job or employees straight from your phone" />
          <meta name="author" content="Pear Up" />

          <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta property="og:description" content="Find your next job or employees straight from your phone…" />
          <meta property="og:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
          <meta property="og:url" content="https://pearup-jobs.com/" />

          <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
          <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
          <meta name="twitter:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
          <meta name="twitter:card" content="Summary" />

        </Helmet>

        <div className="main">
          <div className="content">
            <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
            <div className="login">
              <a href="/register" className="link register-link">Or <span style={{ color: '#59AE46' }}>Register</span></a>
              <a href="/">
                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
              </a>
              <div className="form login-form">
                <h1>Sign in to <span className="green">Pear Up</span></h1>

                <Input
                  label="Email address"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  keyvalue={'email'}
                  value={this.company.email}
                  onChange={this.handleChange}
                />

                <Input
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={this.company.password}
                  onChange={this.handleChange}
                  keyvalue={'password'}
                  errors={errors}
                />

                {this.valid &&
                  <div className="errorsContainer">
                    <p className="error">{this.valid}</p>
                  </div>
                }

                <Button customClass="button"
                  text="Login" onClick={this.submitForm}>
                  <span>Complete</span>
                </Button>
              </div>
              <a href="/forgot-password" className="link">Forgot your password?</a>
            </div>
            <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
          </div>
        </div>
        <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
      </div>
    );
  }
}
export default Login;
