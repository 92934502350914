import React, { Component } from 'react';

export class ContentContainer extends Component {

	render() {
		const { children } = this.props
		return (
			<div className="content-container">
				{children}
			</div>
		);
	}
}
