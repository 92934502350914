import React from 'react';
import { Button } from '../_button'

const CookieBox = ({ display, hide }) => {
    if (display) {
        return (
            <div className="cookies">
                <div className="bg-screens">
                    <div className="cookies-left">
                        <p className="fw-600 base-font--regular"><b>Your choice regarding cookies on this site</b></p>
                        <p>We use cookies to optimize site functionality and give you the best possible experience.</p>
                    </div>
                    <div className="cookies-right">
                        <a className="" id="accept-cookies" onClick={() => { hide() }}
                            href="#">Accept</a>
                        <a className=""
                            href="/cookie-policy">View Policy</a>
                    </div>
                </div>
            </div>
        )
    }
    return (null);
}

export default CookieBox;