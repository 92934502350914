import React, { Component } from 'react';
import TwitterIcon from '../../assets/images/icons/twitter-sm-blue.png';
import FacebookIcon from '../../assets/images/icons/facebook-sm-blue.png';
import PearUpIcon from '../../assets/images/icons/pearup-md.png';
import BurgerMenu from '../../assets/images/icons/burger-menu.png';
import GooglePlayBtn from '../../assets/images/store/GooglePlayBtn.png';
import AppStoreBtn from '../../assets/images/store/AppStoreBtn.png';
import CloseButton from '../../assets/images/icons/close.png';

let Header = ({ toggle, show, close }) => {

    return (
        <div className={show ? 'header-homepage show-model' : 'header-homepage'}>
            <div className='headerLeft'>
                <a href="/">
                <img className="headerLeftImg" src={PearUpIcon} />
                </a>
                <div className="burger-cont" onClick={() => toggle()}>
                    <b>MENU</b>
                    <img className="burger-menu" src={show ? CloseButton : BurgerMenu} />
                </div>
            </div>
            <div className="headerMiddle">
                <ul className="headerMiddleUL">
                    <li className="headerMiddleLi"><a onClick={() => close()} href="/#WhatIsIt">What is it</a></li>
                    <li className="headerMiddleLi"><a onClick={() => close()} href="/#iOS">Download</a></li>
                    <li className="headerMiddleLi"><a onClick={() => close()} href="/#aboutUs">About Us</a></li>
                    <li className="headerMiddleLi"><a onClick={() => close()} href="/login">Sign In</a></li>
                    <li className="headerMiddleLi"><a onClick={() => close()} href="mailto:info@pearupjobs.com">Contact Us</a></li>
                </ul>
                <div className="bg-menu-btm">
                    <a className="bgMenuLinks" target="_blank" href="https://apps.apple.com/gb/app/pear-up/id6450773650">
                        <img src={AppStoreBtn} />
                    </a>
                    <a className="bgMenuLinks" target="_blank" href="https://play.google.com/store/apps/details?id=com.pearupjobs">
                        <img src={GooglePlayBtn} />
                    </a>
                </div>
            </div>
            <div className="headerRight">
                <div className="headerRightContainer">
                    {/* <a href="#">
                        <img className="headerRightImg" src={TwitterIcon} />
                    </a>
                    <a href="#">
                        <img className="headerRightImg" src={FacebookIcon} />
                    </a> */}
                </div>
            </div>
        </div>
    );
}


export default Header;
