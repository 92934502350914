import React, { Component } from 'react';
import AppStoreBtn from '../../assets/images/store/AppStoreBtn.png';
import iosExample from '../../assets/images/homepage/iOSExample.png';

const IOS = () => {
    return (
        <div className="section ios">
            <div id="iOS" className="iosLeft">
                <h2>iOS</h2>
                <hr />
                <p>Start your next job search with Pear Up by downloading the app on the App Store</p>
                <a href="https://apps.apple.com/gb/app/pear-up/id6450773650" target="_blank">
                    <img src={AppStoreBtn} />
                </a>
            </div>

            <div className="iosRight">
                <img src={iosExample} />
            </div>
        </div>
    );
}

export default IOS;