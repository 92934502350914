import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Header, ContentContainer, DataRow, DataRowInfo, ButtonFooter, Button, ConfirmationModal, Breadcrumbs } from '../../components';

import AuthStore from '../../stores/AuthStore'
import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'

import moment from "moment";

@observer
class ConfirmJob extends Component {

    @observable active_sidebar_option = 'Active'
    @observable sidebar_options = ['Active', 'Closed', 'Pending', 'Applicants']
    @observable requests = []

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }

    componentDidMount() {

        JobStore.getPricing();
        if (!JobStore.job.id) {
            JobStore.getJob(this.props.match.params.id)
        }
    }


    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }

    filterJobs = (jobs) => {
        if (this.active_sidebar_option == 'Closed') {
            return jobs.filter(el => el.active == 0 && el.paid != null)
        }
        else if (this.active_sidebar_option == 'Pending') {
            return jobs.filter(el => el.active == 0 && el.paid == null)
        }
        else if (this.active_sidebar_option === 'Applicants') {

        }
        else {
            return jobs.filter(el => el.active == 1)
        }
    }

    goToProfile = (job_id, user_id) => {

    }

    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    render() {

        let { job, applicants } = JobStore
        let { activeCompany } = CompanyStore

        let breadcrumbs = [
            {
                link: '/dashboard',
                title: 'Dashboard'
            },
            {
                link: '/dashboard/jobs',
                title: 'Jobs'
            },
            {
                link: '/dashboard/job/create',
                title: 'Create a Job'
            },
            {
                link: this.props.match.url,
                title: 'Confirm Job'
            }
        ]

        return (
            <div className="page jobs">

                <Breadcrumbs
                    data={breadcrumbs}
                />

                <Header
                    title="Jobs"
                    backButton={true}
                    onClick={() => {
                        this.props.history.push('/dashboard/jobs')
                    }}

                />
                <div className="page-content">
                    {activeCompany.id === job.company_id &&
                        <ContentContainer>
                            <div className="main-content">

                                <h1>Job Details</h1>

                                <DataRow
                                    title="Job Name"
                                    value={job.name}
                                />

                                <DataRow
                                    title="Job Industries"
                                    value={job.industries.map((l, index) => { return (index == 0 ? '' : ' ') + l.name }).toString()}
                                />

                                <DataRow
                                    title="Contract Type"
                                    value={job.contract}
                                />

                                <DataRow
                                    title="Address"
                                    value={job.address}
                                />

                                {job.reward &&
                                    activeCompany.address &&
                                    activeCompany.address.country &&
                                    <DataRow
                                        title="Referral Reward"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}` + job.reward}
                                    />
                                }

                                {job.rate &&
                                    activeCompany.address &&
                                    activeCompany.address.country &&
                                    <DataRow
                                        title="Rate of Pay"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}` + job.rate}
                                    />
                                }

                                <DataRowInfo
                                    title="Job Description"
                                    value={job.description}
                                />

                                {job.duration &&
                                    <DataRow
                                        title="Job Post Length"
                                        value={job.duration === "4_weeks" ? "4 Weeks" : "6 Weeks"}
                                    />
                                }


                                {activeCompany.address &&
                                    activeCompany.address.country &&
                                    job.duration &&
                                    <DataRow
                                        title="Total Cost"
                                        value={`${activeCompany.address.country === "United Kingdom" ? '£' : '€'}${job.duration === "4_weeks" ? JobStore.four_week_pricing : JobStore.six_week_pricing}`}
                                    />
                                }

                                <p>{job.duration}</p>

                                {job.id && job.paid && job.active == 1 &&
                                    <ButtonFooter style={{ justifyContent: 'space-evenly' }}>
                                        <Button
                                            text="Close Job"
                                            style={{ width: '45%', float: 'right' }}
                                            customClass="button btn-red"
                                            onClick={() => { this.confirmationModal = true }}
                                        />
                                    </ButtonFooter>
                                }

                                {job.id && !job.paid &&
                                    <ButtonFooter>
                                        <Button
                                            text="Confirm & checkout"
                                            customClass="button"
                                            style={{ width: '45%', float: 'right' }}
                                            onClick={() =>
                                                this.props.history.push('/dashboard/job/pay/' + JobStore.job.id)
                                            }
                                        />
                                    </ButtonFooter>
                                }

                            </div>

                        </ContentContainer>
                    }




                </div>


                <ConfirmationModal
                    visible={this.confirmationModal}
                    title={`Are you sure you wish to close this job?`}
                    text="This action cannot be undone..."
                    declineText="No, cancel"
                    onClose={() => { this.confirmationModal = false }}
                    acceptText="Yes, close"
                    acceptFunc={() => this.closeJob(job.id)}
                />

            </div>
        );
    }
}
export default ConfirmJob;
