import React from 'react'

import { Button } from '.';

export class ApplicantPanel extends React.Component {

    render() {

        const { name, image, onPress, id, job_id, user_id, buttonText, referral, referral_status, type, accept } = this.props

        var title = ""

        if (buttonText) {
            var title = buttonText
        } else {
            var title = 'View Profile'
        }

        return (

            <div className="applicantContainer" >
                <div className="row">
                    <div style={{ width: '25%', display: 'flex' }}>

                        {image &&
                            <img src={image} className="applicantImage" alt="Applicant Icon" />
                        }

                        {!image &&
                            <img src={require("../assets/images/profile/noProfileImage.png")} className="applicantImage" alt="Applicant Placeholder Icon" />
                        }

                    </div>
                    <div style={{ width: '40%' }}>
                        <div className="row">
                            <div className="company_name">
                                <h3>{name}</h3>
                                {type &&
                                    <Button
                                        text={type + ' applicant'}
                                        customClass={'button btn-' + type.toLowerCase() + '-applicant'}
                                        textStyle={{ fontSize: 12 }}
                                        onClick={() => {
                                            if (job_id) {
                                                onPress(job_id, id)
                                            }
                                            else if (user_id) {
                                                onPress(user_id)
                                            }
                                            else {
                                                onPress(id)
                                            }
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '35%' }}>
                        <div className="row">
                            {accept &&
                                <Button
                                    text="Accept"
                                    customClass="button btn-accept"
                                    textStyle={{ fontSize: 12 }}
                                    onClick={() => {
                                        accept()
                                    }}
                                />
                            }

                            {onPress && !referral &&
                                <Button
                                    text={title}
                                    customClass="button btn-referal"
                                    textStyle={{ fontSize: 12 }}
                                    onClick={() => {
                                        if (job_id) {
                                            onPress(job_id, id)
                                        }
                                        else if (user_id) {
                                            onPress(user_id)
                                        }
                                        else {
                                            onPress(id)
                                        }
                                    }}
                                />
                            }
                            {onPress && referral &&
                                <Button
                                    title={buttonText}
                                    style={{ height: 30, paddingHorizontal: 8, backgroundColor: referral_status ? 'black' : 'green', maxWidth: 60 }}
                                    textStyle={{ fontSize: 12 }}
                                    disabled={referral_status}
                                    onClick={() => { onPress(job_id, user_id) }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
