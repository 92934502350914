import React, { Component } from 'react'

import Slide from './slide.js'

export class ImageSlider extends Component {


  render() {
    const { data, onClick, mode, onDelete } = this.props

    return (
      <div style={{ height: 125 }}>
        <div style={{ flex: 1 }}>
          <div style={{ float: 'left', position: 'relative' }}>
            {mode === "edit" &&
              <div onClick={() => onClick('placeholder')}>
                <img
                  src={require('../assets/images/placeholder.png')} alt="Placeholder"
                  style={{ width: 110, height: 125, marginRight: 10 }} />
              </div>
            }
          </div>
          <label id='images'>
            {
              data.map((item, index) => (
                item.id !== 'placeholder' &&
                <div key={item.id ? item.id : index} style={{ float: 'left', position: 'relative' }}>
                  <Slide
                    filepath={item.filepath}
                    onClick={() => onClick(item.id, item.filepath)} />
                  {mode === "edit" &&
                    <div style={{ position: 'absolute', top: -20, right: 5, justifyContent: 'center', alignItems: 'flex-end', marginTop: 12 }} onClick={() => onDelete(item.id, index)}>
                      <img src={require('../assets/images/deleteIcon.png')} alt="Delete Icon"
                        style={{ width: 25, height: 25 }} />
                    </div>
                  }
                </div>

              ))
            }
          </label>
        </div>
      </div >
    )
  }
}

