import React, { Component, useEffect } from 'react';

import { Input, Button } from '../../components';
import GuestStore from '../../stores/GuestStore'
import { Helmet, Child } from "react-helmet";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import MetaTags from 'react-meta-tags';
import DocumentMeta from 'react-document-meta';


@observer
class MobileJob extends Component {

    @observable job = {}

    constructor(props) {
        super(props);

        this.state = {
            menuShow: false
        }

    }

    componentDidMount() {
        let splitUrl = window.location.href.split("/");
        let jobID = splitUrl[splitUrl.length - 1];
        GuestStore.getJob(jobID).then(res => {
            if (res) {
            }
            this.job = res;
        });
    }

    submitForm = () => {
        let link = window.location.href;
        var str = link.split("/");
        window.location.href = 'pearup://d/job/' + str[4];
    }

    render() {

        let { job } = GuestStore;

        return (
            <div className="wrapper wrapper-white">

                <Helmet>
                    <title>Pear Up | Open Job In App</title>
                    <meta name="description" content="Find your next job or employees straight from your phone…" />
                    <meta name="keywords" content="Find your next job or employees straight from your phone" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta property="og:description" content="Find your next job or employees straight from your phone…" />
                    <meta property="og:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
                    <meta name="twitter:image" content="https://pearup-jobs.com/static/media/icon-lg.7d3d959d.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <div className="main">
                    <div className="content">
                        <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
                        <div className="login">
                            <a href="/register" className="link register-link">Or <span style={{ color: '#59AE46' }}>Register</span></a>
                            <a href="/">
                                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
                            </a>
                            <div className="form login-form">
                                <h1>Open Job on <span className="green">Pear Up</span></h1>
                                {job &&
                                    <div>
                                        <p><b>{job.name}</b></p>
                                        <hr className="green-bg" />
                                        <p>{job.description}</p>
                                        {this.job.company &&
                                            <div>
                                                <img src={this.job.company.logo} className="h-width db center-x" alt="Pear Up Logo" />

                                                <p>{this.job.company.name}</p>
                                                <p>{this.job.address.id + " " + this.job.address.street_1 + " " + this.job.address.city}</p>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="btnContainer pb">
                                    <Button customClass="button"
                                        text="Open in app" onClick={this.submitForm}>
                                    </Button>

                                </div>
                                <img src={require('../../assets/images/store/GooglePlayBtn.png')} className="h-width fl" alt="Pear Up Logo" />
                                <img src={require('../../assets/images/store/AppStoreBtn.png')} className=" h-width fr" alt="Pear Up Logo" />

                            </div>
                        </div>
                        <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
                    </div>
                </div>
            </div>
        );

    }
}

export default MobileJob;





