import React, { Component } from "react";
import "../../App.scss";
import { observer } from "mobx-react";
import { Button } from "../../components";
import { Helmet } from "react-helmet";
import { SkipButton } from "../../components/_skipButton";
import { OnboardingFooter } from "../../components/_onboardingFooter";

@observer
class ScreenOne extends Component {
  render() {
    return (
      <div className="wrapper wrapper-white">
        <Helmet>
          <title>Onboarding</title>
        </Helmet>

        <div className="main">
          <div className="onboardingNav">
            <div className="backButton"></div>
            <div className="onboardingNavSkip">
              <SkipButton />
            </div>
          </div>

          <div className="onboarding">
            <img src={require("../../assets/images/rocket.png")} />
            <div className="onboarding-container">
              <h1>Get 10x hiring results!</h1>
              <h3>
                BOOST{" "}
                <p
                  style={{
                    color: "#231F20",
                    fontWeight: 700,
                    display: "inline-block",
                  }}
                >
                  your Hiring results!
                </p>
              </h3>
              <h5>
                Set <b>employee</b> referral rewards and let{" "}
                <p
                  style={{
                    color: "#59AE46",
                    display: "inline-block",
                    marginBottom: -10,
                  }}
                >
                  <b>Pear Up's</b>
                </p>{" "}
                digital tracking system take advantage of your employees local
                network.
              </h5>
            </div>
            <br />
            <img src={require("../../assets/images/progress1.png")} />
            <blockquote />
            <Button
              customClass="buttonNext"
              text="Next"
              onClick={() => this.props.history.push("/onboarding/screenTwo")}
            ></Button>
          </div>
          <img
            src={require("../../assets/images/leaf-bg.svg")}
            className="leaf-bg"
            alt="Pear Up Logo"
          />
        </div>
        <OnboardingFooter />
      </div>
    );
  }
}
export default ScreenOne;
