import React, { Component } from 'react';

export class MoreInfo extends Component {

    render() {

        const { title, value } = this.props

        return (
            <div className="moreInfo">
                <div className="moreInfoTitle">
                    <p>{title}</p>
                </div>
                <div className="moreInfoValue">
                    <p>{value}</p>
                </div>
            </div>
        );

    }
}
