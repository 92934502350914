import React, { Component } from 'react';
import '../../App.scss';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Header, Sidebar, ContentContainer, ProfileImage, DataRow, DataRowInfo, TableRowList, WorkHistoryRow, Button, ButtonFooter, ConfirmationModal, Breadcrumbs, MessageUserButton, EditUserProfile } from '../../components';

import AuthStore from '../../stores/AuthStore'
import JobStore from '../../stores/JobStore'
import CompanyStore from '../../stores/CompanyStore'

import moment from "moment";

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

@observer
class EmployeeProfile extends Component {

    @observable active_sidebar_option = 'Description'

    @observable sidebar_options = ['Description']

    @observable requests = []

    @observable confirmationModal = false
    @observable status = "decline"
    @observable buttonsDisabled = false

    @observable breadcrumbs = []
    @observable friendStatusDropdown = false

    setActiveSideBarOption = (option) => {
        AuthStore.setActiveSideBarOption(option)
        this.active_sidebar_option = option
    }



    componentDidMount() {
        // console.log(this.props.location.state.user_id)

        // JobStore.getEmployeeById(this.props.location.state.user_id).then((res) => {
        //     console.log('JobStore.getEmployeeById')
        //     console.log(res)

        //     console.log('JobStore.employee')
        //     console.log(JobStore.employee)
        // })

        if (this.props.location.state) {
            if (this.props.location.state.fromScreen) {
                if (this.props.location.state.fromScreen === "employees") {
                    this.breadcrumbs = [
                        {
                            link: '/dashboard',
                            title: 'Dashboard'
                        },
                        {
                            link: '/dashboard/employees',
                            title: 'Employees'
                        },
                        {
                            link: '/dashboard/jobs/job/' + JobStore.employee.id + '/applicant/' + JobStore.applicant.id,
                            title: JobStore.employee.full_name
                        }
                    ]
                }
            }
        }
    }

    formatDuration = (start, end) => {
        let startDate = start.split('-').map(l => {
            return parseInt(l)
        })
        let endDate = end.split('-').map(l => {
            return parseInt(l)
        })
        return this.capitalise(moment(endDate).from(moment(startDate), true))
    }


    capitalise = str => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }


    acceptRequest = () => {
        if (this.status === 'accept') {
            JobStore.acceptRequest(this.props.location.state.user_id).then(res => {
                this.props.history.goBack()
            })
        } else {
            JobStore.declineRequest(this.props.location.state.user_id).then(res => {
                this.props.history.goBack()
            })
        }
    }

    removeEmployee = (id) => {
        JobStore.deleteEmployee(id).then(res => {
            console.log(res)
            ToastsStore.success("The employer has been deleted")
            this.props.history.goBack()
        })

        this.friendStatusDropdown = false
    }


    sendRequest = () => {
        JobStore.sendRequest(this.props.location.state.user_id).then(res => {
            if (res.message === 'A connection request already exists') {
                ToastsStore.success("A connection request already sent")
            } else {
                ToastsStore.success("A connection request sent")
            }
        })
    }



    render() {

        let { employee } = JobStore

        let { activeCompany } = CompanyStore

        return (
            <div className="page jobs">

                <Breadcrumbs
                    data={this.breadcrumbs}
                />

                <Header
                    title="Employee Profile"
                    buttonText="Create a job"
                    backButton={true}
                    onClick={() => {
                        this.props.history.goBack()
                    }}
                    buttonClick={() => {
                        this.props.history.push('/dashboard/job/create')
                    }}
                />
                <div className="page-content">
                    <Sidebar
                        options={this.sidebar_options}
                        currentOption={this.active_sidebar_option}
                        onPress={this.setActiveSideBarOption.bind(this)}
                    />

                    {this.active_sidebar_option && this.active_sidebar_option === 'Description' &&
                        <ContentContainer>

                            <div className="main-content">
                                <div className="applicantProfile">
                                    <ProfileImage
                                        image={employee.logo}
                                        value={employee.full_name}
                                    />

                                    {this.props.location.state.removeFriendOption && (this.props.location.state.addFriend != true) &&
                                        <EditUserProfile
                                            onClick={() => {
                                                this.friendStatusDropdown = true
                                            }}
                                        />
                                    }

                                </div>

                                <DataRow
                                    title="Name"
                                    value={employee.full_name}
                                />

                                {employee.date_of_birth &&
                                    <DataRow
                                        title="Date of Birth"
                                        value={moment(employee.date_of_birth).calendar()}
                                    />
                                }

                                <DataRow
                                    title="Address"
                                    value={employee.address}
                                />

                                <DataRowInfo
                                    title="Description"
                                    value={employee.description}
                                />

                                {employee.work_history && employee.work_history.length > 0 &&
                                    <WorkHistoryRow
                                        title="Work History"
                                        value={employee.work_history}
                                    />
                                }


                                {employee.skills && employee.skills.length > 0 &&
                                    <TableRowList
                                        title="Skills"
                                        value={employee.skills}
                                    />
                                }

                                {employee.educations && employee.educations.length > 0 &&
                                    <TableRowList
                                        title="Education"
                                        value={employee.educations}
                                    />
                                }

                                {employee.certificates && employee.certificates.length > 0 &&
                                    <TableRowList
                                        title="Certificates"
                                        value={employee.certificates}
                                    />
                                }

                                {activeCompany && (this.props.location.state.addFriend == false) && this.props.location.state.showFooter != true &&
                                    <MessageUserButton
                                        customClass="button btn-black-message"
                                        onClick={() => {
                                            window.location.href = '/dashboard/messages/' + employee.id + '/' + activeCompany.id
                                        }}
                                    />
                                }


                                {(this.props.location.state.addFriend == true) &&
                                    <ButtonFooter>
                                        <Button
                                            text="Send Connection Request"
                                            customClass="button"
                                            style={{ width: '100%' }}
                                            onClick={this.sendRequest.bind(this)}
                                            disabled={this.buttonsDisabled}
                                        />
                                    </ButtonFooter>
                                }

                                {this.props.location.state.showFooter == true &&
                                    <ButtonFooter>
                                        <MessageUserButton
                                            customClass="button btn-black-message"
                                            onClick={() => {
                                                window.location.href = '/dashboard/messages/' + employee.id + '/' + activeCompany.id
                                            }}
                                        />

                                        <Button
                                            text="Accept"
                                            customClass="button"
                                            style={{ width: '45%', float: 'right' }}
                                            onClick={() => {
                                                this.status = "accept"
                                                this.confirmationModal = true
                                            }}
                                        />
                                        <Button
                                            text="Decline"
                                            customClass="button btn-cancel"
                                            style={{ width: '30%', float: 'right' }}
                                            onClick={() => {
                                                this.status = "decline"
                                                this.confirmationModal = true
                                            }}
                                        />


                                    </ButtonFooter>
                                }

                            </div>


                            <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
                        </ContentContainer>

                    }
                </div>

                <ConfirmationModal
                    visible={this.confirmationModal}
                    title={`Are you sure you wish to ${this.status} ${employee.full_name} as an employee?`}
                    text="The user will receive a notification about your decision..."
                    declineText="No, cancel"
                    onClose={() => { this.confirmationModal = false }}
                    acceptText={`Yes, ${this.status}`}
                    acceptFunc={this.acceptRequest.bind(this)}
                />

                <ConfirmationModal
                    visible={this.friendStatusDropdown}
                    title={`Are you sure you wish to remove ${employee.full_name} as an employee?`}
                    text="The user will receive a notification about your decision..."
                    declineText="No, cancel"
                    onClose={() => { this.friendStatusDropdown = false }}
                    acceptText="Yes, remove"
                    acceptFunc={() => { this.removeEmployee(employee.id) }}
                />

            </div>
        );
    }
}
export default EmployeeProfile;
