import React, { Component } from 'react'

export class ButtonFooter extends Component {

    render() {
        return (
            <div className="footer-btns">
                {this.props.children}
            </div>
        )
    }
}

