import React, { Component } from 'react';
import '../../App.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Input, Button } from '../../components';
import AuthStore from '../../stores/AuthStore'
import { Helmet } from "react-helmet";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

@observer
class VerifyLogin extends Component {

    @observable details = [{
        password: '',
        password_confirmation: ''
    }]
    @observable loading = false
    @observable valid = false


    constructor(props) {
        super(props);
        this.updateProperty = this.updateProperty.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    updateProperty(key, value) {
        this.details[key] = value
    }

    handleChange(event) {
        this.updateProperty(event.target.name, event.target.value)
    }

    getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        return results == null ? null : results[1];
    }

    submitForm = () => {
        this.loading = true;

        AuthStore.verifyRequest(this.props.location.state.user_key).then(res => {
            if (res.data) {

                ToastsStore.success(res.data)
                setTimeout(
                    function () {
                        this.props.history.push('/login')
                    }
                        .bind(this),
                    2500
                );
            }
            else {
                setTimeout(
                    function () {
                        this.loading = false;
                    }
                        .bind(this),
                    2000
                );
                ToastsStore.error('Something went wrong there')
            }
        })
    }

    render() {
        return (
            <div className="wrapper wrapper-white">

                <Helmet>
                    <title>Pear Up | Send Verification</title>
                    <meta name="description" content="Find your next job or employees straight from your phone…" />
                    <meta name="keywords" content="Find your next job or employees straight from your phone" />
                    <meta name="author" content="Pear Up" />

                    <meta property="og:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta property="og:description" content="Find your next job or employees straight from your phone…" />
                    <meta property="og:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta property="og:url" content="https://pearup-jobs.com/" />

                    <meta name="twitter:title" content="Pear Up - Find your next job or employees straight from your phone" />
                    <meta name="twitter:description" content="Find your next job or employees straight from your phone…" />
                    <meta name="twitter:image" content="../../assets/images/icons/icon-lg.png" />
                    <meta name="twitter:card" content="Summary" />
                </Helmet>

                <div className="main">
                    <div className="content">
                        <img src={require('../../assets/images/birds.svg')} className="birds-bg" alt="Pear Up Logo" />
                        <div className="login">
                            <a href="/login" className="link register-link">Or <span style={{ color: '#59AE46' }}>Login</span></a>
                            <a href="/">
                                <img src={require('../../assets/images/logo.svg')} className="logo" alt="Pear Up Logo" />
                            </a>
                            <div className="form login-form">
                                <h1>Send Verification Email</h1>

                                <Button customClass="button"
                                    text="Request Verification Email" disabled={this.loading} onClick={this.submitForm}>
                                </Button>
                            </div>
                        </div>
                        <img src={require('../../assets/images/leaf-bg.svg')} className="leaf-bg" alt="Pear Up Logo" />
                    </div>
                </div>
                <ToastsContainer className="toast" position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
            </div>

        );
    }
}
export default VerifyLogin;
