class Configuration {
  constructor() {
    this.api = process.env.REACT_APP_API_BASE_URL;
    this.stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
    this.apiKey = process.env.REACT_APP_PEAR_UP_API_KEY;
    this.userKey = null;
  } 

  get defaultHeaders() {
    return {
      'Xpu-Api-Key': this.apiKey,
      'Xpu-User-Key': this.userKey,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };
};

let Config = new Configuration();

export default Config;
