import React, { Component } from 'react'

import moment from "moment";

export class WorkHistoryRow extends Component {

    render() {
        const { title, value } = this.props

        return (
            <div className="dataRowInfo work-history">
                <div className="dataRowTitle">
                    <p>{title}</p>
                </div>
                <div className="work-history-cont">
                    {
                        value.map((l, index) => (

                            <div className="work-history-item" key={index}>

                                <p className="title">
                                    {l.title}
                                </p>

                                <div className="work-desc">
                                    <p className="employer-name">
                                        {l.employer}
                                    </p>

                                    {l.present === 0 &&
                                        <p className="work-history-duration">
                                            {moment(l.start_date).format("ll")} - {moment(l.end_date).format("ll")} ({moment(l.start_date).from(moment(l.end_date))})
                                    </p>
                                    }

                                    {l.present === 1 &&
                                        <p className="work-history-duration">
                                            {moment(l.start_date).format("ll")} - present ({moment(l.start_date).from(moment())})
                                    </p>
                                    }

                                    {l.description &&
                                        <p className="work-history-desc">
                                            {l.description}
                                        </p>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}
