import React, { Component } from "react";
import "../../App.scss";
import { observable } from "mobx";
import { observer } from "mobx-react";
import {
  Header,
  Sidebar,
  ContentContainer,
  Button,
  Breadcrumbs,
  DataRow,
  DataRowInfo,
  ImageSlider,
  ProfileImage,
  DataRowVideo,
  ConfirmationModal,
} from "../../components";

import AuthStore from "../../stores/AuthStore";
import JobStore from "../../stores/JobStore";
import CompanyStore from "../../stores/CompanyStore";

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

@observer
class Profile extends Component {
  @observable active_sidebar_option = "My Profile";
  @observable sidebar_options = ["My Profile", "Settings"];
  @observable requests = [];
  @observable deactivateModel = false;

  setActiveSideBarOption = (option) => {
    AuthStore.setActiveSideBarOption(option);
    this.active_sidebar_option = option;
  };

  componentDidMount() {
    CompanyStore.checkAuth();
  }
  deactivateAccount = async () => {
    try {
      CompanyStore.deactivateAccount(this.password)
        .then((res) => {
          if (res == 200) {
            this.loading = false;
            ToastsStore.success("Account has been deactiviated");

            setTimeout(
              function () {
                this.props.history.push("/login");
              }.bind(this),
              2500
            );
          } else {
            ToastsStore.success("omething went wrong...");
          }
        })
        .catch((err) => {
          this.loading = false;
          ToastsStore.success(err);
        });
    } catch (e) {
      this.loading = false;
    }
  };

  capitalise = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  render() {
    let { activeCompany, state } = CompanyStore;

    let breadcrumbs = [
      {
        link: "/dashboard",
        title: "Dashboard",
      },
      {
        link: "/dashboard/profile",
        title: "Profile",
      },
    ];
    return (
      <div className="page jobs">
        <Breadcrumbs data={breadcrumbs} />

        <Header
          title="Profile"
          buttonText="Edit Profile"
          buttonClass="button button-white"
          buttonClick={() => {
            JobStore.resetJob();
            this.props.history.push("/dashboard/profile/edit");
          }}
        />

        <div className="page-content">
          <Sidebar
            options={this.sidebar_options}
            currentOption={this.active_sidebar_option}
            onPress={this.setActiveSideBarOption.bind(this)}
            buttonClick={() => {
              this.props.history.push("/dashboard/job/create");
            }}
          />

          <ContentContainer>
            {this.active_sidebar_option &&
              this.active_sidebar_option == "My Profile" && (
                <div className="main-content">
                  <div className="applicantProfile">
                    <ProfileImage
                      image={activeCompany.logo}
                      value={activeCompany.name}
                      name={activeCompany.name}
                    />
                  </div>
                  <DataRow title="Name" value={activeCompany.name} />

                  <DataRow title="Address" value={activeCompany.address} />

                  <div className="dataRow">
                    <div className="dataRowTitle">
                      <p>Onboarding</p>
                    </div>
                    <div className="onboardingRowValue">
                      <a href="/onboarding/screenOne">
                        <span style={{ color: "green" }}>View Onboarding</span>
                      </a>
                    </div>
                  </div>

                  {activeCompany.about && (
                    <DataRowInfo
                      title="About the company"
                      value={activeCompany.about}
                    />
                  )}

                  {activeCompany.website && (
                    <div className="dataRow">
                      <div className="dataRowTitle">
                        <p>Website</p>
                      </div>
                      <div className="dataRowValue">
                        <a href={activeCompany.website}>
                          <span style={{ color: "green" }}>
                            {activeCompany.website}
                          </span>
                        </a>
                      </div>
                    </div>
                  )}

                  {activeCompany.images && activeCompany.images.length > 0 && (
                    <div className="viewPhotos">
                      <h4>Photos</h4>

                      <ImageSlider
                        data={activeCompany.images}
                        // mode="edit"
                        onClick={() => {
                          console.log("click");
                        }}
                      />
                    </div>
                  )}

                  {activeCompany.videos && activeCompany.videos.length > 0 && (
                    <div className="viewVideos">
                      <h4>Videos</h4>
                      {activeCompany.videos.map((l, index) => (
                        <div key={l.id}>
                          <DataRowVideo
                            title="View Video"
                            value={l.name}
                            link={l.link}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

            {this.active_sidebar_option &&
              this.active_sidebar_option == "Settings" && (
                <div className="main-content">
                  <h1>Settings</h1>

                  <Button
                    text="Change Email"
                    customClass="button menu-item"
                    style={{ width: "100%" }}
                    onClick={() =>
                      this.props.history.push("/dashboard/profile/email")
                    }
                  />

                  <Button
                    text="Change password"
                    customClass="button menu-item"
                    style={{ width: "100%" }}
                    onClick={() =>
                      this.props.history.push("/dashboard/profile/password")
                    }
                  />

                  <Button
                    text="Deactivate account"
                    customClass="button menu-item"
                    style={{ width: "100%" }}
                    onClick={() => (this.deactivateModel = true)}
                  />

                  <Button
                    text="Delete Account"
                    customClass="button menu-item"
                    style={{ width: "100%" }}
                    onClick={() =>
                      this.props.history.push("/dashboard/profile/delete")
                    }
                  />
                </div>
              )}

            <ConfirmationModal
              visible={this.deactivateModel}
              title={`Are you sure you wish to deactivate your account?`}
              text="You can still log back in to reactivate your account"
              declineText="No, cancel"
              onClose={() => {
                this.deactivateModel = false;
              }}
              acceptText="Yes, deactivate"
              acceptFunc={() => this.deactivateAccount()}
            />
            <ToastsContainer
              className="toast"
              position={ToastsContainerPosition.BOTTOM_CENTER}
              store={ToastsStore}
            />
          </ContentContainer>
        </div>
      </div>
    );
  }
}
export default Profile;
